import React from 'react';
import { useHistory } from 'react-router-dom';

// Components...
import Can from '@App/components/Can';
import Box from '@material-ui/core/Box';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import LocalAirportIcon from '@material-ui/icons/LocalAirport';
import Button from '@material-ui/core/Button';
import Container from '@App/components/Container';
import Typography from '@material-ui/core/Typography';
import Translation from '@App/components/Translation';

// Styles...
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  navigation: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: theme.spacing(38),
    height: theme.spacing(6.5),
    margin: theme.spacing(1.7),
    textTransform: 'capitalize',
  },
  transfersButtonText: {
    fontSize: 14,
  },
  icon: {
    marginRight: theme.spacing(1),
    minWidth: theme.spacing(7),
  },
  subIcon: {
    marginRight: theme.spacing(1.8),
  },
}));

const HomeMenu = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = (path) => {
    history.push(path);
  };

  return (
    <Container maxWidth="md" allCentered>
      <Can
        perform="transfers:view"
        yes={() => (
          <Button
            className={classes.navigation}
            onClick={() => handleClick('/transfers')}
            variant="contained"
            color="primary"
          >
            <Box display="flex">
              <DriveEtaIcon />
              <LocalAirportIcon className={classes.subIcon} />
            </Box>
            <Typography
              align="left"
              className={classes.transfersButtonText}
              variant="subtitle1"
            >
              <Translation path="main.exit.explorer.customers" />
            </Typography>
          </Button>
        )}
      />
      <Can
        perform="tours:view"
        yes={() => (
          <Button
            className={classes.navigation}
            onClick={() => handleClick('/tours')}
            variant="contained"
            color="primary"
          >
            <DriveEtaIcon className={classes.icon} />
            <Typography variant="subtitle1">
              <Translation path="main.service.toures" />
            </Typography>
          </Button>
        )}
      />
    </Container>
  );
};

export default HomeMenu;
