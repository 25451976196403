import { combineReducers } from 'redux';

import restaurant from '@App/containers/Restaurants/reducer';
import tourBookings from '@App/containers/Tours/Bookings/reducer';
import user from './userReducer';

const rootReducer = combineReducers({
  restaurant,
  user,
  tourBookings,
});

export default rootReducer;
