import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import App from '@App/components/App';
import Route from '@App/components/Route';
// import Footer from '@App/components/Footer';
import Header from '@App/components/Header';
import Main from '@App/components/Main';
import Login from '@App/containers/Auth/Login';
import RestaurantFooter from '@App/containers/Restaurants/RestaurantFooter';

const AuthLayout: React.FC = () => (
  <App>
    <Header />
    <Main>
      <Switch>
        <Route exact path="/auth/login" component={Login} />
        <Redirect to="/not-found" />
      </Switch>
    </Main>
    <RestaurantFooter />
  </App>
);

export default AuthLayout;
