import classNames from 'classnames';
import React from 'react';

import MuiCard from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: '28px',
    overflow: 'visible',
    minWidth: theme.spacing(23),
  },
  topButton: {
    marginRight: '20px',
  },
}));

const Card: React.FC<any> = ({
  badge,
  topButton,
  className,
  children,
  ...props
}) => {
  const classes = useStyles();
  return (
    <MuiCard
      className={classNames(
        {
          [`${classes.topButton}`]: topButton,
          [`${classes.root}`]: topButton || badge,
        },
        className
      )}
      {...props}
    >
      {badge}
      {topButton}
      {children}
    </MuiCard>
  );
};

export default Card;
