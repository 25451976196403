import Axios from '@App/plugins/axios';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TourBookingDialogUserForm from './Bookings/TourBookingDialogUserForm';
import TourList from './TourList';
import TourListSkeleton from './TourListSkeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacing(5)}px 0`,
  },
}));

const Tour = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t: translate } = useTranslation();

  const [tours, setTours] = useState({ data: [], loading: false, error: null });

  const fetchTours = async () => {
    try {
      setTours({ data: [], loading: true, error: null });
      const resource = await Axios.get('local/tours?status=true');
      setTours({ data: resource.data, loading: false, error: null });
    } catch (error) {
      setTours({ data: [], loading: true, error });
    }
  };

  const handleBackClick = () => {
    history.push('/transport-and-tours');
  };

  const fetchData = async () => {
    await fetchTours();
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  return (
    <Container maxWidth="md">
      <Button
        onClick={handleBackClick}
        startIcon={<NavigateBeforeIcon />}
        variant="contained"
        color="primary"
        size="small"
      >
        {translate('back')}
      </Button>
      <Box className={classes.root}>
        {tours.loading ? <TourListSkeleton /> : <TourList tours={tours.data} />}
      </Box>
      <TourBookingDialogUserForm />
    </Container>
  );
};

export default Tour;
