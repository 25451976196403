import React from 'react';
import Translation from '../Translation';

interface DaysTextProps {
  daysNumbers: string;
}

const DaysText: React.FC<DaysTextProps> = ({ daysNumbers }) => {
  const daysNumbersArray = daysNumbers
    ? daysNumbers.split(',').sort()
    : [];
  const totalDays = daysNumbersArray.length;

  return (
    <>
      {daysNumbersArray.map((dayNumber, index) => (
        <>
          <Translation path={`day.${dayNumber}`} />
          {index < totalDays - 1 && index !== totalDays - 2 && ', '}
          {index === totalDays - 2 && (
            <>
              {' '}
              <Translation path={`and`} />{' '}
            </>
          )}
        </>
      ))}
    </>
  );
};

export default DaysText;
