import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    backgroundColor: '#edf1f5',
    color: '#575757',
  },
}));

interface MainProps {
  className?: string;
}

const Main: React.FC<MainProps> = ({ children }) => {
  const classes = useStyles();
  return <main className={classes.main}>{children}</main>;
};

export default Main;
