import RestaurantBookingDate from '@App/types/RestaurantBookingDate';

export default class RestaurantBookingWrap {
  static wrapBookingDates({ data }: any): any {
    const wrappedData: RestaurantBookingDate[] = [];

    data.Datos.forEach((item: any) => {
      const wrappedItem: RestaurantBookingDate = {
        date: item.FECHA.split(' ')[0],
        maxHostQuota: item.CUPOS_TOTAL,
        bookedHostQuota: item.CUPOS_RESE,
        availableHostQuota: item.CUPOS_DISP,
      };

      wrappedData.push(wrappedItem);
    });

    return wrappedData;
  }
}
