import Card from '@App/components/Card';
import DaysText from '@App/components/DaysText';
import TextClipper from '@App/components/TextClipper/TextClipper';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useTranslationHelper from '@App/hooks/useTranslationHelper';
import TourCardActions from './TourCardActions';
import CurrencyFormat from 'react-currency-format';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  subtitle: {
    display: 'flex',
    color: theme.palette.secondary.main,
  },
  subtitleIcon: {
    fontSize: '1.3rem',
    marginRight: theme.spacing(0.5),
  },
  subtitleText: {
    position: 'relative',
    top: '-3px',
    fontWeight: 'bold',
  },
}));

const TourCard = ({ tour }) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const { getTranslation } = useTranslationHelper();

  return (
    <Card>
      <CardMedia
        className={classes.media}
        image={tour.image || 'assets/images/explorer/explorer.jpg'}
      />
      <CardContent>
        {tour.description && (
          <Box mb={2}>
            <Typography gutterBottom variant="h5" component="h2">
              {getTranslation(tour, 'name')}
            </Typography>
            <TextClipper
              content={getTranslation(tour, 'description')}
              maxLength={180}
              gutterBottom
            />
          </Box>
        )}
        {tour.restrictions && (
          <Box mb={2}>
            <Box className={classes.subtitle}>
              <ErrorIcon className={classes.subtitleIcon} />
              <Typography className={classes.subtitleText} variant="subtitle1">
                {translate('restriction')}:
              </Typography>
            </Box>
            <TextClipper
              content={getTranslation(tour, 'restrictions')}
              maxLength={180}
            />
          </Box>
        )}
        {tour.schedule && (
          <Box mb={2}>
            <Box className={classes.subtitle}>
              <WatchLaterIcon className={classes.subtitleIcon} />
              <Typography className={classes.subtitleText} variant="subtitle1">
                {translate('schedules')}:
              </Typography>
            </Box>
            <Typography variant="body1">
              {translate('apply.for.days')}{' '}
              <DaysText daysNumbers={tour.applyForDays} />{' '}
              {translate('at.schedule')}{' '}
              {moment(tour.schedule, 'hh:mm:ss').format('h:mm A')}{' '}
              {translate('with.a.duration.of')} {tour.durationInMinutes / 60}{' '}
              {translate('hours')}.
            </Typography>
          </Box>
        )}
        {tour.hasPrice && (
          <Box mb={2}>
            <Box className={classes.subtitle}>
              <MonetizationOnIcon className={classes.subtitleIcon} />
              <Typography className={classes.subtitleText} variant="subtitle1">
                {translate('price')}:
              </Typography>
            </Box>
            <Typography variant="body1">
              <CurrencyFormat
                value={tour.price}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />{' '}
              COP
            </Typography>
          </Box>
        )}
        <TourCardActions tour={tour} />
      </CardContent>
    </Card>
  );
};

TourCard.propTypes = {
  tour: PropTypes.object.isRequired,
};

export default TourCard;
