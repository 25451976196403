import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enCommon from '@App/languages/common/en.json';
import esCommon from '@App/languages/common/es.json';
import ptCommon from '@App/languages/common/pt.json';
import frCommon from '@App/languages/common/fr.json';
import htCommon from '@App/languages/common/ht.json';

import enValidation from '@App/languages/validations/en.json';
import esValidation from '@App/languages/validations/es.json';
import ptValidation from '@App/languages/validations/pt.json';
import frValidation from '@App/languages/validations/fr.json';
import htValidation from '@App/languages/validations/ht.json';

// Others...
import languages from '@App/hooks/useLanguages';
import { COOKIE_LANG } from '@App/utils/constants';
import { getCookie } from '@App/utils/cookie';
import { isUndefined } from 'lodash';

const resources = {
  en: {
    common: enCommon,
    validations: enValidation,
  },
  es: {
    common: esCommon,
    validations: esValidation,
  },
  pt: {
    common: ptCommon,
    validations: ptValidation,
  },
  fr: {
    common: frCommon,
    validations: frValidation,
  },
  ht: {
    common: htCommon,
    validations: htValidation,
  },
};

const lang = !isUndefined(getCookie(COOKIE_LANG))
  ? getCookie(COOKIE_LANG)
  : 'es';

const [languageFound] = languages().filter(
  (country) => country.languageIso6391Code === lang
);

i18n.use(initReactI18next).init({
  ns: ['common', 'validations'],
  defaultNS: 'common',
  resources,
  fallbackLng: 'es',
  lng: languageFound.languageIso6391Code,
  keySeparator: false,
});

export default i18n;
