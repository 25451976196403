import React from 'react';
import Grid from '@material-ui/core/Grid';
import TourCardSkeleton from './TourCardSkeleton';

const TourListSkeleton = () => (
  <Grid container spacing={5}>
    {[...Array(4).keys()].map((key) => (
      <Grid key={key} md={6} xs={12} item>
        <TourCardSkeleton />
      </Grid>
    ))}
  </Grid>
);

export default TourListSkeleton;
