import zones from '@App/mocks/zones.json';
import { AxiosResponse } from 'axios';
import axios from '@App/plugins/axios';

export default class HotelService {
  static getAll(): Promise<AxiosResponse> {
    return axios.get('/local/hotels');
  }

  static getZones(): Promise<Object> {
    return new Promise((resolve) => {
      resolve(zones);
    });
  }
}
