import React, { useContext } from 'react';
import className from 'classnames';
import { useHistory } from 'react-router-dom';

// Components...
import Fab from '@material-ui/core/Fab';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import Translation from '@App/components/Translation';

// Styles...
import { makeStyles } from '@material-ui/core/styles';

// Others...
import { AuthContext } from '@App/providers/Authentication/context';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    bottom: `${theme.spacing(2)}px`,
    right: `${theme.spacing(2)}px`,
  },
}));

const ActivityButton = () => {
  const classes = useStyles();
  const history = useHistory();
  const { isAuthenticated } = useContext(AuthContext);

  const handleBookingsClick = () => {
    history.push('/activities/bookings');
  };

  return (
    isAuthenticated && (
      <div className={className(classes.container, 'activity-button')}>
        <Fab
          color="primary"
          variant="extended"
          aria-label="add"
          onClick={handleBookingsClick}
        >
          <EventAvailableIcon />
          <Translation path="reservations.my.reservations" />
        </Fab>
      </div>
    )
  );
};
export default ActivityButton;
