import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Can from '@App/components/Can';
import Container from '@App/components/Container';
import Typography from '@material-ui/core/Typography';
import Translation from '@App/components/Translation';
import FastFoodIcon from '@material-ui/icons/Fastfood';
import LocalAirportIcon from '@material-ui/icons/LocalAirport';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import SportsCricketIcon from '@material-ui/icons/SportsCricket';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import HelpCenterIcon from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  navigation: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: theme.spacing(38),
    height: theme.spacing(6.5),
    margin: theme.spacing(1.7),
    textTransform: 'capitalize',
  },
  icon: {
    marginRight: theme.spacing(1),
    minWidth: theme.spacing(7),
  },
  subIcon: {
    marginRight: theme.spacing(1.8),
  },
}));

const HomeMenu = () => {
  const classes = useStyles();
  const history = useHistory();
  const { i18n } = useTranslation();

  const handleClick = (path) => {
    history.push(path);
  };
  const handleOnlineRegisterClick = () => {
    window.open(
      `${process.env.REACT_APP_REGISTER_URL}?sigPais=CO&idioma=${i18n.language}`
    );
  };

  return (
    <Container maxWidth="md" allCentered>
      <Can
        perform="restaurants:view"
        yes={() => (
          <Button
            className={classes.navigation}
            onClick={() => handleClick('/restaurants/bookings')}
            variant="contained"
            color="primary"
          >
            <FastFoodIcon className={classes.icon} />
            <Typography variant="subtitle1">
              <Translation path="main.restaurant.reservation" />
            </Typography>
          </Button>
        )}
      />
      <Can
        perform={['tours:view', 'transfers:view']}
        yes={() => (
          <Button
            className={classes.navigation}
            onClick={() => handleClick('/transport-and-tours')}
            variant="contained"
            color="primary"
          >
            <Box display="flex">
              <DriveEtaIcon />
              <LocalAirportIcon className={classes.subIcon} />
            </Box>
            <Typography variant="subtitle1">
              <Translation path="main.transport.tours" />
            </Typography>
          </Button>
        )}
      />
      <Can
        perform="activities:view"
        yes={() => (
          <Button
            className={classes.navigation}
            onClick={() => handleClick('/activities/children')}
            variant="contained"
            color="primary"
          >
            <SportsCricketIcon className={classes.icon} />
            <Typography variant="subtitle1">
              <Translation path="entertainment" />
            </Typography>
          </Button>
        )}
      />
      <Button
        className={classes.navigation}
        onClick={handleOnlineRegisterClick}
        variant="contained"
        color="primary"
      >
        <PersonAddIcon className={classes.icon} />
        <Typography variant="subtitle1">
          <Translation path="main.online.register" />
        </Typography>
      </Button>
      <Button
        className={classes.navigation}
        onClick={() => handleClick('/fact-sheets')}
        variant="contained"
        color="primary"
      >
        <HelpCenterIcon className={classes.icon} />
        <Typography variant="subtitle1">
          <Translation path="main.general.information" />
        </Typography>
      </Button>
    </Container>
  );
};

export default HomeMenu;
