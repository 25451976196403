import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

const TourCardSkeleton = () => (
  <Box>
    <Skeleton variant="rect" width="100%" height={118} />
    <Skeleton width="100%" />
    <Skeleton width="100%" />
    <Skeleton width="100%" />
  </Box>
);

export default TourCardSkeleton;
