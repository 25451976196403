import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    '@media (max-width: 981px)': {
      padding: '0 !important',
    },
  },
  fullCalendar: {
    '@media (max-width: 981px)': {
      padding: '0 !important',
    },
    '& .fc-daygrid-day': {
      opacity: 0.5,
      backgroundColor: '#ddd',
    },
    '& .fc-cell-active': {
      opacity: 1,
    },
    '& .fc-cell-active:not(.fc-day-today) > div': {
      backgroundColor: '#fff',
      transition: 'all 0.4s ease',
      cursor: 'pointer',
    },
    '& .fc-cell-active:not(.fc-day-today):hover > div': {
      backgroundColor: '#fffcdb',
    },
  },
  event: {
    padding: theme.spacing(0.5),
    display: 'flex',
    width: '100%',
  },
  eventPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  eventSecondary: {
    backgroundColor: theme.palette.error.main,
  },
  eventBody: {
    width: '100%',
  },
  eventTitle: {
    color: theme.palette.primary.contrastText,
    fontSize: 13,
    marginRight: theme.spacing(1),
  },
  eventTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  eventTime: {
    color: theme.palette.primary.contrastText,
    fontSize: 10,
    marginRight: theme.spacing(1),
  },
  descBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light,
    minHeight: '70px',
  },
  descBlockButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.contrastText,
  },
  descBlockTitle: {
    marginLeft: theme.spacing(-12),
    color: theme.palette.primary.contrastText,
  },
}));

export default useStyles;
