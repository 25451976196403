import React, { useState } from 'react';

import Translation from '@App/components/Translation';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

const useStyles = makeStyles((theme) => ({
  wrap: {
    marginBottom: theme.spacing(5),
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
}));

const RestaurantBookingTabs: React.FC = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Box className={classes.wrap}>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
      >
        <Tab label={<Translation path="reservations.new.reservations" />} />
      </Tabs>
    </Box>
  );
};

export default RestaurantBookingTabs;
