import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Card from '@App/components/Card';
import CardBadge from '@App/components/Card/CardBadge';
import DetailWithIcons from '@App/components/DetailWithIcons/DetailWithIcons';
import Image from '@App/components/Image';
import Translation from '@App/components/Translation';
import Restaurant from '@App/types/Restaurant';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MuiCardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';

import useBookings from './Bookings/useBookings';
import RestaurantTitle from './RestaurantTitle';

const useStyles = makeStyles((theme) => ({
  img: {
    marginBottom: 17,
    maxHeight: 250,
  },
  opacity: {
    opacity: '0.5',
  },
  availableQuoteText: {
    color: 'red',
  },
}));

interface RestaurantCardProps {
  restaurant: Restaurant;
}

const RestaurantCard: React.FC<RestaurantCardProps> = ({ restaurant }) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const { language } = i18n;
  const history = useHistory();

  const {
    booking,
    update: updateBooking,
    updateStep: updateBookingStep,
  } = useBookings();

  const handleUpdateBooking = () => {
    booking.data.additionalData = {
      ...booking.data.additionalData,
      restaurantName: restaurant.name,
      restaurantMenuType: restaurant.menuType,
      turnType: restaurant.turnType,
      hotelName: restaurant.hotelName,
      restaurantAvailableQuote: restaurant.availableQuote,
      restaurantLocation: restaurant.location,
      restaurantConditions: restaurant.conditions,
      restaurantFoodType: restaurant.foodType,
    };

    const data = {
      ...booking.data,
      restaurantId: restaurant.id,
    };

    updateBooking(data);
    updateBookingStep(3);
    history.push('/restaurants/bookings/steps/3');
  };

  const hasQuota = restaurant.availableQuote > 0;
  const hasAvailableHostQuota = booking.data.additionalData.availableHostQuota > 0;

  return (
    <Card
      className={classNames({ [`${classes.opacity}`]: !hasQuota })}
      elevation={2}
      badge={<CardBadge>{restaurant?.foodType?.[`${language}`]}</CardBadge>}
    >
      <MuiCardContent>
        <Box mb={2}>
          <Image
            className={classes.img}
            src={`admin/public/storage/images/${restaurant.hotelId}/restaurants/${restaurant.id}.png`}
            defaultSrc="assets/images/default-restaurant.jpg"
          />
          <RestaurantTitle
            type={
              <>
                <Translation path="restaurant" />
                &nbsp;-&nbsp;
                <Translation path={restaurant.menuType} />
              </>
            }
            title={restaurant.name}
            subtitle={
              !hasQuota ? <Translation path="disabled.occuppancies" /> : ''
            }
          />
        </Box>
        <Divider />
        <Box mt={3} mb={3}>
          <DetailWithIcons
            data={[
              {
                label: <Translation path="date" />,
                icon: 'fas fa-calendar',
                content: restaurant.turnDate,
              },
              {
                label: <Translation path="available.occuppancies" />,
                icon: 'fas fa-users',
                content: hasQuota ? (
                  restaurant.availableQuote
                ) : (
                  <Typography
                    className={classes.availableQuoteText}
                    variant="body1"
                  >
                    {restaurant.availableQuote}
                  </Typography>
                ),
              },
              {
                label: <Translation path="turn.type" />,
                icon: 'fas fa-clock',
                content: restaurant?.turnType?.[`${language}`],
              },
              {
                label: <Translation path="hotel" />,
                icon: 'fas fa-hotel',
                content: restaurant.hotelName,
              },
              {
                label: <Translation path="location" />,
                icon: 'fas fa-map-marker',
                content: restaurant.location,
                optional: true,
              },
            ]}
          />
        </Box>
        <Button
          variant="contained"
          color="secondary"
          disabled={!hasQuota || !hasAvailableHostQuota}
          onClick={handleUpdateBooking}
        >
          <Translation path="step.select.restaurant" />
        </Button>
      </MuiCardContent>
    </Card>
  );
};

export default RestaurantCard;
