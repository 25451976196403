import React, { useEffect, useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';

import RestaurantService from '@App/services/Restaurant';

import RestaurantBookingHeader from './Bookings/RestaurantBookingHeader';
import useBookings from './Bookings/useBookings';
import RestaurantList from './RestaurantList';
import RestaurantListSkeleton from './RestaurantListSkeleton';
import Translation from '@App/components/Translation';
import useAuth from '@App/hooks/useAuth';

const Restaurant: React.FC = () => {
  const [restaurants, setRestaurants] = useState({
    data: [],
    loading: false,
    error: null,
  });
  const { user } = useAuth();
  const { booking } = useBookings();

  const hasAvailableHostQuota = booking.data.additionalData.availableHostQuota > 0;

  const fetchRestaurants = async () => {
    try {
      setRestaurants({ data: [], loading: true, error: null });
      const data = { ...user, ...booking.data };
      const resource = RestaurantService.wrapRestaurants(
        await RestaurantService.find(data)
      );
      setRestaurants({ ...restaurants, data: resource, loading: false });
    } catch (error) {
      setRestaurants({ ...restaurants, loading: false, error });
    }
  };

  const fetchData = () => {
    fetchRestaurants();
  };

  useEffect(fetchData, []);

  return (
    <>
      <RestaurantBookingHeader
        title={<Translation path="list.restaurants.title" />}
        subtitle={<Translation path="list.restaurants.description" />}
      />
      {!hasAvailableHostQuota && (
        <Box mb={4}>
          <Alert severity="error">
            <Translation path="restaurant.booking.quotas.exceeded" />
          </Alert>
        </Box>
      )}
      {restaurants.loading ? (
        <RestaurantListSkeleton />
      ) : (
        <RestaurantList restaurants={restaurants.data} />
      )}
    </>
  );
};

export default Restaurant;
