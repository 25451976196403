import { get } from 'lodash';
import { getI18n, withTranslation } from 'react-i18next';

const bindValues: Function = (
  translation: string,
  valuesToBind: { [key: string]: string } = {},
  t: any
): string => {
  const objectKeys = Object.keys(valuesToBind);

  objectKeys.forEach((key) => {
    const regex = new RegExp(`{${key}}`, 'gi');
    translation = translation.replaceAll(regex, t(valuesToBind[key]));
  });

  return translation;
};

interface TranslateProps {
  t?: any;
  path: string;
  count?: number;
  messages?: any;
  default?: string;
  valuesToBind?: object;
}

export const Translate: React.FC<any> = ({
  t,
  path,
  count,
  messages,
  valuesToBind,
  ...props
}) => {
  let response;

  if (messages) {
    const { language } = getI18n();
    response = messages?.[language];

    if (path) response = get(response, path);
  } else {
    response = t(path, { count });
  }

  response = bindValues(response, valuesToBind, t);

  return response || props.default || '';
};

const Translation = withTranslation()(Translate);

export default Translation;
