import React from 'react';

import Box from '@material-ui/core/Box';
import Date from '@App/components/Date';
import Time from '@App/components/Date/Time';

interface TimeProps {
  date: string;
  time: string;
}

const DateTime: React.FC<TimeProps> = ({ date, time }) => (
  <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
    <Date date={date} />
    <Time time={time} />
  </Box>
);

export default DateTime;
