import React from 'react';
import MuiButton, { ButtonProps } from '@material-ui/core/Button';
import Translation from '@App/components/Translation';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  buttonWrapper: {
    position: 'relative',
  },
  circularProgress: {
    position: 'absolute',
  },
}));

interface ExtendedButtonProps extends ButtonProps {
  path: string;
  loading?: boolean;
}

const Button: React.FC<ExtendedButtonProps> = ({ path, loading, ...props }) => {
  const classes = useStyles();
  return (
    <MuiButton
      {...props}
      disabled={props.disabled || loading}
      className={`${classes.buttonWrapper} ${props.className}`}
    >
      <Translation path={path} />
      {loading && (
        <CircularProgress className={classes.circularProgress} size={24} />
      )}
    </MuiButton>
  );
};

export default Button;
