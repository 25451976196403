import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { TextField, TextFieldProps } from '@material-ui/core';
import { get, set } from 'lodash';
import BaseFormElementProps from '../shared/interfaces/BaseFormElementProps';
import parseValue from '../shared/helpers/parse-value';

const Input: React.FC<BaseFormElementProps & TextFieldProps> = ({
  state,
  path,
  control,
  errors,
  ...props
}) => {
  const [data, setData] = state;
  const [value, setValue] = useState<string>(get(data, path));
  const handleChange = (event) => {
    setValue(event.target.value);

    const val =
      props.parseValue === undefined || props.parseValue === true
        ? parseValue(event.target.value)
        : event.target.value;

    set(data, path, val);
    setData(data);
  };

  useEffect(() => {
    setValue(get(data, path));
  }, [JSON.stringify(data)]);

  return (
    <Controller
      name={path}
      render={({ onChange }) => (
        <TextField
          {...props}
          error={Boolean(get(errors, path))}
          fullWidth={props.fullWidth || true}
          helperText={get(errors, `${path}.message`)}
          margin={props.margin || 'normal'}
          value={value === null ? '' : value}
          variant={props.variant || 'outlined'}
          onChange={(event) => {
            onChange(event);
            handleChange(event);
          }}
        />
      )}
      control={control}
    />
  );
};

export default Input;
