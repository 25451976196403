import classNames from 'classnames';
import React from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0px 0px 17px 0px',
  },
  img: {
    width: '100%',
  },
}));

interface ImageProps {
  src: string;
  alt?: string;
  title?: string;
  maxHeight?: string;
  className?: string;
  defaultSrc?: string;
}

const Image: React.FC<ImageProps> = ({
  src,
  defaultSrc,
  alt,
  title,
  className,
}) => {
  const classes = useStyles();

  return (
    <figure
      className={classNames(
        {
          [`${classes.root}`]: true,
          [`${classes.img}`]: true,
        },
        className
      )}
    >
      <img
        onError={(event: any) => {
          event.target.onerror = null;
          event.target.src = defaultSrc;
        }}
        src={src}
        alt={alt}
        title={title}
      />
    </figure>
  );
};

export default Image;
