import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import Translation from '@App/components/Translation';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontWeight: 'bolder',
    padding: `
      ${theme.spacing(1)}px
      ${theme.spacing(1)}px
      ${theme.spacing(1)}px
      0px
    `,
  },
  table: {
    minWidth: 650,
  },
  checkbox: {
    width: '60px',
  },
}));

const RestaurantBookingAssociatedGuestsTable = ({
  associatedGuests,
  handleGuests,
}: any) => {
  const classes = useStyles();

  const [guests, setGuests] = useState(
    associatedGuests.map((associatedGuest: any) => ({
      ...associatedGuest,
      selected: false,
    })),
  );
  const [state, setState] = useState({ showGuests: true });

  const handleSelectedGuestsChange = (currentGuest: any) => {
    const indexGuest = guests.findIndex(
      (guest: any) => guest.id === currentGuest.id
    );
    const newGuests = Object.assign([...guests], {
      [indexGuest]: {
        ...guests[indexGuest],
        selected: !guests[indexGuest].selected,
      },
    });

    setGuests(newGuests);

    const selectedGuests = newGuests.filter(
      (guest: any) => guest.selected === true
    );

    handleGuests(selectedGuests);
  };

  const validateGuestsHasQuota = () => {
    const hasQuota: Array<boolean> = [];

    guests.forEach((guest: any) => {
      if (guest.bookedRestaurantQuota === guest.restaurantQuota) {
        hasQuota.push(false);
      } else {
        hasQuota.push(true);
      }
    });

    if (hasQuota.indexOf(true) === -1) {
      setState({ ...state, showGuests: false });
    }
  };

  useEffect(() => {
    setGuests(guests);
    validateGuestsHasQuota();
  }, [guests]);

  return isEmpty(guests) || !state.showGuests ? (
    <></>
  ) : (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.titleText} />
            <TableCell className={classes.titleText}>
              <Translation path="room" />
            </TableCell>
            <TableCell className={classes.titleText}>
              <Translation path="holder" />
            </TableCell>
            <TableCell className={classes.titleText}>
              <Translation path="occupancies" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {guests.map(
            (guest: any) =>
            guest.bookedRestaurantQuota !== guest.restaurantQuota && (
                <TableRow key={guest.id} hover>
                  <TableCell className={classes.checkbox} padding="none">
                    <Checkbox
                      checked={guest.selected}
                      onChange={() => handleSelectedGuestsChange(guest)}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell padding="none">{guest.fullName}</TableCell>
                  <TableCell padding="none">{guest.roomCode}</TableCell>
                  <TableCell padding="none">{guest.restaurantQuota}</TableCell>
                </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default RestaurantBookingAssociatedGuestsTable;
