import React from 'react';
import { useHistory } from 'react-router-dom';

import Translation from '@App/components/Translation';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import TodayIcon from '@material-ui/icons/Today';

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    color: theme.palette.success.main,
  },
  title: {
    fontSize: 26,
    marginTop: theme.spacing(-0.8),
    fontWeight: 400,
  },
  titleIcon: {
    marginRight: theme.spacing(1),
    fontSize: 30,
  },
}));

const RestaurantBookingHeaderNew: React.FC<any> = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleNewBookingClick = () => {
    history.push('/restaurants/bookings/steps/1');
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Box className={classes.titleContainer} display="flex">
            <EventAvailableIcon className={classes.titleIcon} />
            <Typography className={classes.title} variant="h6">
              <Translation path="confirmed.reservations" />
            </Typography>
          </Box>
          <Typography variant="subtitle1">
            <Translation path="reservations.list.description" />
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display="flex" justifyContent="center" alignContent="center">
            <Button
              variant="contained"
              color="primary"
              startIcon={<TodayIcon />}
              onClick={handleNewBookingClick}
            >
              <Translation path="reservations.list.new.reservation" />
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RestaurantBookingHeaderNew;
