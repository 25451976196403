import React from 'react';

// Components...
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiBlockUi from 'react-block-ui';

// Styles...
import { makeStyles } from '@material-ui/core/styles';
import 'react-block-ui/style.css';

const useStyles = makeStyles(() => ({
  circularProgress: {
    position: 'fixed',
    top: 'calc(50% - 45px)',
    left: 'calc(50% - 45px)',
  },
}));

const BlockUi: React.FC<any> = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <MuiBlockUi
      tag="div"
      loader={
        <CircularProgress className={classes.circularProgress} {...props} />
      }
      {...props}
    >
      {children}
    </MuiBlockUi>
  );
};

BlockUi.defaultProps = {
  size: 70,
  thickness: 1,
  color: 'primary',
};

export default BlockUi;
