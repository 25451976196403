import * as React from 'react';

interface HiddenProps {
  expectation: any;
  value?: any;
  yes: any;
  no?: any;
}

const Hidden: React.FC<HiddenProps> = ({ expectation, value, yes, no }) => {
  const isEqual = expectation === value;
  return isEqual ? yes() : no();
};

Hidden.defaultProps = {
  no: () => null,
};

export default Hidden;
