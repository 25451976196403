import React from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import RestaurantBookingDate from '@App/types/RestaurantBookingDate';
import RestaurantBookingDateCard from './RestaurantBookingDateCard';

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: 'center',
  },
}));

const RestaurantBookingDateList: React.FC<any> = ({
  bookingDates,
  handleTurnsModal,
}) => {
  const classes = useStyles();

  return (
    <Grid className={classes.container} container spacing={3}>
      {bookingDates.map((bookingDate: RestaurantBookingDate, index: number) => (
        <Grid key={index} item md={4} xs={12}>
          <RestaurantBookingDateCard
            bookingDate={bookingDate}
            handleTurnsModal={handleTurnsModal}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default RestaurantBookingDateList;
