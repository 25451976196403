import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { AuthContext } from '@App/providers/Authentication/context';
import { check } from '@App/utils/auth';
import useAuth from '@App/hooks/useAuth';

const PrivateRoute = ({ component: Component, perform, ...otherProps }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const { user } = useAuth();
  const { redirect, path } = otherProps;
  const isAuthorized = check(user, perform);
  const search = `?redirect=${path.slice(1)}`;

  return (
    <Route
      {...otherProps}
      render={(props) => {
        let component = null;
        if (isAuthorized && isAuthenticated) {
          component = <Component {...props} />;
        } else if (isAuthenticated && !isAuthorized) {
          component = (
            <Redirect
              to={{ pathname: redirect, state: { from: props.location } }}
            />
          );
        } else if (!isAuthenticated) {
          component = (
            <Redirect
              to={{
                pathname: '/auth/login',
                search: search,
                state: { from: props.location },
              }}
            />
          );
        }
        return component;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  redirect: PropTypes.string,
  perform: PropTypes.string.isRequired,
};

PrivateRoute.defaultProps = {
  redirect: '/not-authorized',
};

export default PrivateRoute;
