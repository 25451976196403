import * as types from '@App/actions/types/userTypes';

export const initialState = {
  data: {},
  loading: false,
  error: null,
};

export default function reducer(state = initialState, action: any = {}) {
  const { branch }: any = action;

  switch (action.type) {
    case `${branch}/${types.FIND_WHERE_INIT}`:
      return {
        ...state,
        data: {},
        loading: true,
        error: null,
      };

    case `${branch}/${types.FIND_WHERE_SUCCESS}`:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };

    case `${branch}/${types.FIND_WHERE_ERROR}`:
      return {
        ...state,
        data: {},
        loading: true,
        error: action.payload,
      };
    case `${branch}/${types.UPDATE}`:
      return {
        ...state,
        data: action.payload,
      };
    case `${branch}/${types.CLEAR}`:
      return {
        ...state,
        data: {},
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
