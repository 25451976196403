import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';

// Components...
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Card from '@App/components/Card';
import CardContent from '@material-ui/core/CardContent';
import InnerHtml from '@App/components/InnerHtml';
import ActivityDetailSkeleton from './ActivityDetailSkeleton';

// Styles...
import { makeStyles } from '@material-ui/core/styles';

// Others...
import Axios from '@App/plugins/axios';
import ActivityHeader from './ActivityHeader';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import Typography from '@material-ui/core/Typography';
import Translation from '@App/components/Translation';
import ActivityDetailActions from './ActivityDetailActions';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacing(5)}px 0`,
  },
}));

const ActivityDetail = ({ activity: activityProp }) => {
  const classes = useStyles();
  const history = useHistory();
  const { activityId } = useParams();
  const { i18n } = useTranslation();
  const [activity, setActivity] = useState({
    data: [],
    loading: true,
    error: null,
  });

  const fetchActivity = async () => {
    if (activityProp) {
      setActivity({ data: activityProp, loading: true, error: null });
    } else {
      try {
        setActivity({ data: [], loading: true, error: null });
        const resource = await Axios.get(`local/activities/${activityId}`);
        setActivity({ data: resource.data, loading: false, error: null });
      } catch (error) {
        setActivity({ data: [], loading: true, error });
      }
    }
  };

  const checkActivityId = () => {
    activityId === ':activityId' && history.push('/activities/children');
  };

  const fetchData = async () => {
    await fetchActivity();
  };

  useEffect(() => {
    (async () => {
      checkActivityId();
      await fetchData();
    })();
  }, []);

  const renderContent = () => {
    const hasContent = !isEmpty(activity.data.content?.[i18n.language]);

    return hasContent ? (
      <InnerHtml>{activity.data.content[i18n.language]}</InnerHtml>
    ) : (
      <Typography variant="subtitle1">
        <Translation path="no.content.language.available" />
      </Typography>
    );
  };

  return (
    <>
      <ActivityHeader acitivty={activity.data} />
      <Container maxWidth="md">
        <Box className={classes.root}>
          {activity.loading ? (
            <ActivityDetailSkeleton />
          ) : (
            <Card>
              <CardContent>
                {renderContent()}
                {activity.data.hasTurns && (
                  <ActivityDetailActions activity={activity.data} />
                )}
              </CardContent>
            </Card>
          )}
        </Box>
      </Container>
    </>
  );
};

export default ActivityDetail;
