import React, { useEffect, useState } from 'react';

import ButtonProgress from '@App/components/ButtonProgress';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'none',
  },
  button: {
    borderStyle: 'none !important',
  },
}));

const IncrementerButtons: React.FC<any> = ({
  value,
  loading,
  handleChange,
  disabledDecrement,
  disabledIncrement,
  ...others
}) => {
  const classes = useStyles();
  const [counter, setCounter] = useState(value);

  const handleIncrement = () => {
    const newValue = counter + 1;
    handleChange(newValue);
  };
  const handleDecrement = () => {
    const newValue = counter - 1;
    handleChange(newValue);
  };

  useEffect(() => {
    setCounter(value);
  }, [value]);

  return (
    <Box>
      <ButtonGroup {...others}>
        <Button
          className={classes.button}
          onClick={handleDecrement}
          disabled={disabledDecrement}
        >
          -
        </Button>
        <Typography className={classes.text} variant="subtitle1">
          {counter}
        </Typography>
        <Button
          className={classes.button}
          onClick={handleIncrement}
          disabled={disabledIncrement}
        >
          +
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default IncrementerButtons;
