import React from 'react';
import MuiStepLabel from '@material-ui/core/StepLabel';
import className from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { isUndefined } from 'lodash';

const useColorStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    width: 60,
    height: 60,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  active: {
    boxShadow: `0px 0px 40px -4px ${theme.palette.primary.contrasts}`,
  },
  completed: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
    border: `2px solid ${theme.palette.success.main}`,
  },
}));

const ColorStepIcon = ({ icons, active, completed, icon }) => {
  const classes = useColorStepIconStyles();
  const children = isUndefined(icons[String(icon)])
    ? icon
    : icons[String(icon)];

  return (
    <div
      className={className(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {children}
    </div>
  );
};

const StepLabel = ({ icons = {}, children, ...others }) => (
  <MuiStepLabel
    StepIconComponent={(props) => ColorStepIcon({ icons, ...props })}
    {...others}
  >
    {children}
  </MuiStepLabel>
);

export default StepLabel;
