import React from 'react';

// Components...
import Grid from '@material-ui/core/Grid';
import ActivityCardSkeleton from './ActivityCardSkeleton';

const ActivityListCardSkeleton = () => (
  <Grid container spacing={3}>
    {[...Array(3).keys()].map((key) => (
      <Grid key={key} xs={12} item>
        <ActivityCardSkeleton />
      </Grid>
    ))}
  </Grid>
);

export default ActivityListCardSkeleton;
