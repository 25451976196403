import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Translation from '@App/components/Translation';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import RestaurantDishOrdersAccordion from './RestaurantDishOrdersAccordion';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '17px',
  },
}));

const RestaurantDishOrdersList: React.FC<any> = ({ dishOrders }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | boolean>(false);
  const [hasDishes, setHasDishes] = React.useState<boolean>(true);
  const history = useHistory();

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  const validateHasDishes = () => {
    const hasDishes: Array<boolean> = [];
    dishOrders.forEach((dishOrder: any) => {
      if (!isEmpty(dishOrder.dishes)) {
        hasDishes.push(true);
      } else {
        hasDishes.push(false);
      }
    });

    setHasDishes(hasDishes.indexOf(true) >= 0);
  };

  useEffect(() => {
    validateHasDishes();
  }, [dishOrders]);

  return (
    <Box className={classes.root}>
      {hasDishes ? (
        dishOrders.map(
          (dishOrder: any) => !isEmpty(dishOrder.dishes) && (
              <RestaurantDishOrdersAccordion
                key={dishOrder.id}
                dishOrder={dishOrder}
                panelId={`panel${dishOrder.id}`}
                expanded={expanded}
                handleChange={handleChange}
              />
            ),
        )
      ) : (
        <Alert severity="info">
          <Translation path="menu.available" />
        </Alert>
      )}

      <Box className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push('/restaurants/bookings')}
        >
          <Translation path="finish" />
        </Button>
      </Box>
    </Box>
  );
};

export default RestaurantDishOrdersList;
