import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components...
import ReactCountryFlag from 'react-country-flag';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// Styles...
import { makeStyles } from '@material-ui/core/styles';

// Others...
import useLanguages from '@App/hooks/useLanguages';

const useStyles = makeStyles((theme) => ({
  flag: {
    borderRadius: '30%',
    width: '0.8em',
    height: '0.8em',
  },
  item: {
    width: '0.8em',
    height: '0.8em',
    marginRight: theme.spacing(1),
  },
}));

const LanguageMenu = ({ language, handleLangChange }) => {
  const classes = useStyles();
  const languages = useLanguages();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onLangChange = (lang) => () => {
    handleLangChange(lang);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-label="delete" onClick={handleIconClick}>
        <ReactCountryFlag
          className={classes.flag}
          countryCode={language.flag}
          title={language.flag}
          svg
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        keepMounted
      >
        {languages.map((language) => (
          <MenuItem key={language.languageIso6391Code} onClick={onLangChange(language)}>
            <ReactCountryFlag
              className={classes.item}
              countryCode={language.flag}
              title={language.flag}
              svg
            />
            <Typography variant="body1" component="span">
              {language.text}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

LanguageMenu.propTypes = {
  language: PropTypes.object.isRequired,
  handleLangChange: PropTypes.func.isRequired,
};

LanguageMenu.defaultProps = {};

export default LanguageMenu;
