import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import App from '@App/components/App';
import PrivateRoute from '@App/components/PrivateRoute';
import Footer from '@App/components/Footer';
import Header from '@App/components/Header';
import Main from '@App/components/Main';
import Transfers from '@App/containers/Transfers';

const TransferLayout: React.FC = () => (
  <App>
    <Header />
    <Main>
      <Switch>
        <PrivateRoute
          exact
          path="/transfers"
          component={Transfers}
          perform="transfers:view"
        />
        <Redirect to="/not-found" />
      </Switch>
    </Main>
    <Footer />
  </App>
);

export default TransferLayout;
