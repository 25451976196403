import React, { useState, useCallback } from 'react';
import ConfirmationDialog from '@App/components/ConfirmationDialog';
import { translate } from '@App/components/Translation';
import { MaterialConfirmProvider } from './context';

const MaterialConfirm = ({ t, children, defaultOptions = {} }) => {
  const DEFAULT_OPTIONS = {
    title: translate({ t, path: 'title.deleted' }),
    description: translate({ t, path: 'description.deleted' }),
    confirmationText: translate({ t, path: 'continue' }),
    cancellationText: translate({ t, path: 'cancel' }),
    dialogProps: {},
    confirmationButtonProps: {},
    cancellationButtonProps: {},
  };
  const buildOptions = (defaultValues, options) => {
    const dialogProps = {
      ...(defaultValues.dialogProps || DEFAULT_OPTIONS.dialogProps),
      ...(options.dialogProps || {}),
    };
    const confirmationButtonProps = {
      ...(defaultValues.confirmationButtonProps
        || DEFAULT_OPTIONS.confirmationButtonProps),
      ...(options.confirmationButtonProps || {}),
    };
    const cancellationButtonProps = {
      ...(defaultValues.cancellationButtonProps
        || DEFAULT_OPTIONS.cancellationButtonProps),
      ...(options.cancellationButtonProps || {}),
    };

    return {
      ...DEFAULT_OPTIONS,
      ...defaultValues,
      ...options,
      dialogProps,
      confirmationButtonProps,
      cancellationButtonProps,
    };
  };

  const [options, setOptions] = useState({
    ...DEFAULT_OPTIONS,
    ...defaultOptions,
  });
  const [resolveReject, setResolveReject] = useState([]);
  const [resolve, reject] = resolveReject;

  const confirm = useCallback(
    (options = {}) => new Promise((resolve, reject) => {
        setOptions(buildOptions(defaultOptions, options));
        setResolveReject([resolve, reject]);
      }),
    []
  );

  const handleClose = useCallback(() => {
    setResolveReject([]);
  }, []);

  const handleCancel = useCallback(() => {
    reject();
    handleClose();
  }, [reject, handleClose]);

  const handleConfirm = useCallback(() => {
    resolve();
    handleClose();
  }, [resolve, handleClose]);

  return (
    <>
      <MaterialConfirmProvider value={confirm}>
        {children}
      </MaterialConfirmProvider>
      <ConfirmationDialog
        open={resolveReject.length === 2}
        options={options}
        onClose={handleClose}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default MaterialConfirm;
