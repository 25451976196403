import RestaurantBookingService from '@App/services/RestaurantBooking';
import RestaurantBooking from '@App/types/RestaurantBooking';
import User from '@App/types/User';
import { isUndefined } from 'lodash';

import * as types from './types';

const branch: string = 'restaurants';

export const bookingsFindInitAction = () => ({
  branch,
  type: `${branch}/${types.BOOKINGS_FIND_INIT}`,
});
export const bookingsFindSuccessAction = (payload: RestaurantBooking[]) => ({
  branch,
  type: `${branch}/${types.BOOKINGS_FIND_SUCCESS}`,
  payload,
});
export const bookingsFindErrorAction = (payload: any) => ({
  branch,
  type: `${branch}/${types.BOOKINGS_FIND_ERROR}`,
  payload,
});
export const bookingsUpdateInitAction = () => ({
  branch,
  type: `${branch}/${types.BOOKINGS_UPDATE_INIT}`,
});
export const bookingsUpdateSuccessAction = (payload: RestaurantBooking[]) => ({
  branch,
  type: `${branch}/${types.BOOKINGS_UPDATE_SUCCESS}`,
  payload,
});
export const bookingsUpdateErrorAction = (payload: any) => ({
  branch,
  type: `${branch}/${types.BOOKINGS_UPDATE_ERROR}`,
  payload,
});
export const bookingsDeleteInitAction = () => ({
  branch,
  type: `${branch}/${types.BOOKINGS_DELETE_INIT}`,
});
export const bookingsDeleteSuccessAction = (payload: RestaurantBooking[]) => ({
  branch,
  type: `${branch}/${types.BOOKINGS_DELETE_SUCCESS}`,
  payload,
});
export const bookingsDeleteErrorAction = (payload: any) => ({
  branch,
  type: `${branch}/${types.BOOKINGS_DELETE_ERROR}`,
  payload,
});
export const bookingResetAction = () => ({
  branch,
  type: `${branch}/${types.BOOKING_RESET}`,
});
export const bookingCreateInitAction = () => ({
  branch,
  type: `${branch}/${types.BOOKING_CREATE_INIT}`,
});
export const bookingCreateSuccessAction = (payload: RestaurantBooking) => ({
  branch,
  type: `${branch}/${types.BOOKING_CREATE_SUCCESS}`,
  payload,
});
export const bookingCreateErrorAction = (payload: string) => ({
  branch,
  type: `${branch}/${types.BOOKING_CREATE_ERROR}`,
  payload,
});

export const bookingsFindAction = (user: User) => async (
  dispatch: Function
) => {
  dispatch(bookingsFindInitAction());

  try {
    const foundBookings: RestaurantBooking[] = (
      await RestaurantBookingService.find(user)
    ).data;

    return dispatch(bookingsFindSuccessAction(foundBookings));
  } catch (error) {
    return dispatch(bookingsFindErrorAction(error));
  }
};

export const bookingsUpdateAction = (
  bookings: RestaurantBooking[],
  booking: RestaurantBooking,
  user: User
) => async (dispatch: Function) => {
  dispatch(bookingsUpdateInitAction());
  const data = { ...booking, ...user };
  await RestaurantBookingService.update(data);

  bookings.map((item, index) => {
    if (item.id === booking.id) {
      bookings[index] = booking;
    }
  });

  return dispatch(bookingsUpdateSuccessAction(bookings));
};

export const bookingsDeleteAction = (
  bookings: RestaurantBooking[],
  booking: RestaurantBooking
) => async (dispatch: Function) => {
  dispatch(bookingsDeleteInitAction());

  await RestaurantBookingService.delete(booking.id);
  bookings = bookings.filter((item) => item.id !== booking.id);

  return dispatch(bookingsDeleteSuccessAction(bookings));
};

export const bookingUpdateAction = (booking: any) => async (
  dispatch: Function
) => {
  if (isUndefined(booking.channel)) booking.channel = 'web';

  dispatch({
    branch,
    type: `${branch}/${types.BOOKING_UPDATE}`,
    payload: booking,
  });
};

export const bookingStepUpdateAction = (step: number) => async (
  dispatch: Function
) => {
  dispatch({
    branch,
    type: `${branch}/${types.BOOKING_STEP_UPDATE}`,
    payload: step,
  });
};

export const bookingCreateAction = (booking: any) => async (
  dispatch: Function
) => {
  dispatch(bookingCreateInitAction());

  try {
    const resource = await RestaurantBookingService.store(booking);
    const createdBooking = resource.data;

    booking.id = createdBooking?.id;

    return dispatch(bookingCreateSuccessAction(booking));
  } catch ({ response }) {
    return dispatch(bookingCreateErrorAction(response.data.message));
  }
};
