import React from 'react';

// Components...
import Grid from '@material-ui/core/Grid';
import TransferCardSkeleton from './TransferCardSkeleton';

const TransferListSkeleton = () => (
  <Grid container spacing={5}>
    {[...Array(4).keys()].map((key) => (
      <Grid key={key} md={6} xs={12} item>
        <TransferCardSkeleton />
      </Grid>
    ))}
  </Grid>
);

export default TransferListSkeleton;
