import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { URL_API_HOTEL } from '@App/utils/constants';
import { useTranslation, getI18n } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const HotelCard = ({ hotel }) => {
  const styles = useStyles();
  const { t: translate } = useTranslation();
  const { language } = getI18n();
  const hasFactSheet = () =>
    hotel.translations.hasOwnProperty(language) &&
    hotel.translations[language].fact_sheet_file !== '';

  const getURLFactsheet = () => {
    if (hotel.translations.hasOwnProperty(language)) {
      return hotel.translations[language].fact_sheet_file;
    }
  };

  const getImageHotel = (idHotel) => `assets/images/factsheet/${idHotel}.jpg`;

  const handleViewPDF = () => {
    const url = getURLFactsheet();
    window.open(
      URL_API_HOTEL + url,
      'title',
      'toolbar=yes,location=yes,status=yes'
    );
  };

  return (
    <>
      <Card>
        <CardMedia
          component="img"
          image={getImageHotel(hotel.id)}
          alt={hotel.name}
        />
        <CardContent className={styles.cardContent}>
          <Typography variant="h5" component="div">
            {hotel.name}
          </Typography>
          {hotel.rnt && (
            <Typography variant="body1" component="div">
              RNT {hotel.rnt}{' '}
            </Typography>
          )}
        </CardContent>
        <CardActions>
          {hasFactSheet() ? (
            <Button
              onClick={handleViewPDF}
              variant="contained"
              color="secondary"
              startIcon={<VisibilityIcon />}
              size="small"
            >
              {translate('see.factsheet')}
            </Button>
          ) : (
            ''
          )}
        </CardActions>
      </Card>
    </>
  );
};

HotelCard.propTypes = {
  hotel: PropTypes.any.isRequired,
};
export default HotelCard;
