import React, { useState } from 'react';
import { useParams } from 'react-router';

import ButtonProgress from '@App/components/ButtonProgress';
import IncrementerButtons from '@App/components/IcrementerButtons';
import Translation from '@App/components/Translation';
import RestaurantDishOrdersService from '@App/services/RestaurantDishOrders';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import useTranslationHelper from '@App/hooks/useTranslationHelper';
import useMaterialSnackbar from '@App/hooks/useMaterialSnackbar';

const useStyles = makeStyles((theme: Theme) => ({
  img: {
    width: 90,
    height: 90,
    marginRight: `${theme.spacing(2)}px`,
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const RestaurantDishOrdersAccordionDish: React.FC<any> = ({
  dish: orderedDish,
  bookedHostQuantity,
  maxHostQuota,
  handleBookedHostQuantity,
  isLastDish,
}) => {
  const classes = useStyles();
  const { bookingId }: any = useParams();
  const [quantity, setQuantity] = useState({
    data: orderedDish.bookedQuantity,
    loading: false,
    error: null,
  });
  const [dish, setDish] = useState(orderedDish);
  const [isDishAvailable, setIsDishAvailable] = useState(true);
  const { getTranslation } = useTranslationHelper();

  const handleChange = async (value: any) => {    
    const data: any = {
      turnDistributionId: dish.turnDistributionId,
      dishId: dish.id,
      quantityDishes: value,
      restaurantId: dish.restaurantId,
      hotelId: dish.hotelId,
      status: value > 0 ? 'A' : 'I',
      bookingId,
    };

    setIsDishAvailable(true);
    const operator = value > quantity.data ? 'increment' : 'decrement';
    const hasAvailability = validateAvailability(operator, value);

    if (hasAvailability) {
      try {
        setQuantity({ ...quantity, loading: true, error: null });
        if (dish.dishOrderId) {
          await RestaurantDishOrdersService.update(dish.dishOrderId, data);
        } else {
          const resource: any = await RestaurantDishOrdersService.store(data);
          setDish({ ...dish, dishOrderId: resource.data.id });
        }
        handleBookedHostQuantity(operator, 1);
        setQuantity({ data: value, loading: false, error: null });
      } catch (error) {
        setQuantity({ data: parseInt(value) - 1, loading: false, error });
        //@Fixme
        setIsDishAvailable(false);
      }
    } else {
      setIsDishAvailable(false);
    }
  };

  const validateAvailability = (operator: string, value: number) => {
    const availability =
      operator === 'increment' ? dish.available - 1 : dish.available + 1;

    if (availability >= 0 && availability <= dish.quota) {
      setDish({ ...dish, available: availability });
    }

    return !(availability < 0);
  };

  return (
    <>
      {quantity.loading && <ButtonProgress size={60} />}
      <Box display="flex" mb={2}>
        <Avatar
          className={classes.img}
          alt={getTranslation(dish, 'name')}
          src={`admin/public/storage/images/${dish.hotelId}/dishes/${dish.id}.png`}
          onError={(event: any) => {
            event.target.onerror = null;
            event.target.src = 'assets/images/default-food.png';
          }}
        />
        <Box>
          <Typography variant="subtitle1">
            <b>
              <Translation
                path="name"
                messages={dish.translations}
                default={dish.name}
              />
            </b>
          </Typography>
          <Typography variant="subtitle2">
            <Translation
              path="accompanying"
              messages={dish.translations}
              default={dish.accompanying}
            />
          </Typography>
          <Typography variant="subtitle2">
            <Translation
              path="description"
              messages={dish.translations}
              default={dish.description}
            />
          </Typography>
        </Box>
      </Box>
      <Box className={classes.actions}>
        <IncrementerButtons
          value={quantity.data}
          handleChange={handleChange}
          disabledIncrement={
            bookedHostQuantity >= maxHostQuota || quantity.loading
          }
          disabledDecrement={quantity.data <= 0 || quantity.loading}
          variant="contained"
          size="small"
          color="primary"
          aria-label="contained primary button group"
        />
      </Box>
      {!isDishAvailable && (
        <Box mt={1}>
          <Alert
            severity="error"
            onClose={() => {
              setIsDishAvailable(true);
            }}
          >
            <Translation path="out.of.order.dish" />
          </Alert>
        </Box>
      )}
      {!isLastDish && <Divider className={classes.divider} />}
    </>
  );
};

export default RestaurantDishOrdersAccordionDish;
