import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    right: '-20px',
    top: '-20px',
    padding: '5px',
    width: '35px',
    height: '35px',
  },
}));

const CardTopButton: React.FC = () => {
  const classes = useStyles();
  return (
    <Skeleton className={classes.root} animation="wave" variant="circle" />
  );
};

export default CardTopButton;
