import React from 'react';

// Components...
import Card from '@App/components/Card';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: '28px',
  },
  skeleton: {
    height: theme.spacing(2),
  },
  content: {
    paddingBottom: '16px !important',
  },
}));

const RestaurantBookingDateCardSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Box>
          <Box mb={1}>
            {[...(Array(2) as any).keys()].map((key) => (
              <Skeleton
                className={classes.skeleton}
                key={key}
                width="40%"
                animation="wave"
              >
                <Typography>.</Typography>
              </Skeleton>
            ))}
          </Box>
          <Box mb={1}>
            {[...(Array(5) as any).keys()].map((key) => (
              <Skeleton
                className={classes.skeleton}
                key={key}
                width="100%"
                animation="wave"
              >
                <Typography>.</Typography>
              </Skeleton>
            ))}
          </Box>
          <Skeleton
            className={classes.skeleton}
            height={60}
            width="100%"
            animation="wave"
          >
            <Typography>.</Typography>
          </Skeleton>
          <Box mb={1}>
            {[...(Array(3) as any).keys()].map((key) => (
              <Skeleton
                className={classes.skeleton}
                key={key}
                width="100%"
                animation="wave"
              >
                <Typography>.</Typography>
              </Skeleton>
            ))}
          </Box>
          <Skeleton
            className={classes.skeleton}
            height={80}
            width="100%"
            animation="wave"
          >
            <Typography>.</Typography>
          </Skeleton>
          <Box mb={1}>
            {[...(Array(2) as any).keys()].map((key) => (
              <Skeleton
                className={classes.skeleton}
                key={key}
                width="100%"
                animation="wave"
              >
                <Typography>.</Typography>
              </Skeleton>
            ))}
          </Box>
          <Box mb={1}>
            {[...(Array(1) as any).keys()].map((key) => (
              <Skeleton
                className={classes.skeleton}
                key={key}
                width="100%"
                animation="wave"
              >
                <Typography>.</Typography>
              </Skeleton>
            ))}
          </Box>
          <Box display="flex" justifyContent="center">
            <Skeleton
              className={classes.skeleton}
              height={70}
              width="10%"
              animation="wave"
            >
              <Typography>.</Typography>
            </Skeleton>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default RestaurantBookingDateCardSkeleton;
