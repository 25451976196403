import React, { useState } from 'react';

import Translation from '@App/components/Translation';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import RestaurantDishOrdersAccordionDish from './RestaurantDishOrdersAccordionDish';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const RestaurantDishOrdersAccordion: React.FC<any> = ({
  dishOrder,
  panelId,
  expanded,
  handleChange,
}) => {
  const classes = useStyles();
  const [bookedHostQuantity, setBookedHostQuantity] = useState(
    dishOrder.bookedHostQuantity
  );
  const lastDishOrder = dishOrder.dishes[dishOrder.dishes.length - 1];

  const handleBookedHostQuantity = (operator: string, value: number) => {
    operator === 'increment'
      ? setBookedHostQuantity(bookedHostQuantity + value)
      : setBookedHostQuantity(bookedHostQuantity - value);
  };

  return (
    <Accordion expanded={expanded === panelId} onChange={handleChange(panelId)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2bh-content"
        id="panel2bh-header"
      >
        <Typography className={classes.heading}>
          {dishOrder.name}{' '}
          <b>
            ({bookedHostQuantity} <Translation path="of" />{' '}
            {dishOrder.maxHostQuota})
          </b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box className={classes.container}>
          {dishOrder.dishes.map((dish: any) => (
            dish.menuIsActive && dish.isActive && (
                <RestaurantDishOrdersAccordionDish
                  key={dish.id}
                  dish={dish}
                  isLastDish={dish.id === lastDishOrder.id}
                  bookedHostQuantity={bookedHostQuantity}
                  maxHostQuota={dishOrder.maxHostQuota}
                  handleBookedHostQuantity={handleBookedHostQuantity}
                />
              )
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default RestaurantDishOrdersAccordion;
