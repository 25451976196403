import React from 'react';

// Components...
import Grid from '@material-ui/core/Grid';
import RestaurantDishOrdersAccordionSkeleton from './RestaurantDishOrdersAccordionSkeleton';

const RestaurantDishOrdersListSkeleton: React.FC = () => (
  <Grid container>
    {[...(Array(4) as any).keys()].map((key) => (
      <Grid key={key} xs={12} item>
        <RestaurantDishOrdersAccordionSkeleton />
      </Grid>
    ))}
  </Grid>
);

export default RestaurantDishOrdersListSkeleton;
