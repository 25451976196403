import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Translation from '@App/components/Translation';
import Sidebar from './Sidebar';
import LanguageMenu from './LanguageMenu';
import { makeStyles } from '@material-ui/core/styles';
import useWindowDimensions from '@App/hooks/useWindowDimensions';
import useLanguages from '@App/hooks/useLanguages';
import useAuth from '@App/hooks/useAuth';
import { isUndefined } from 'lodash';
import { getCookie, setCookie } from '@App/utils/cookie';
import { COOKIE_LANG } from '@App/utils/constants';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles((theme) => ({
  body: {
    margin: `${theme.spacing(2)}px  ${theme.spacing(2)}px  ${theme.spacing(
      2
    )}px  ${theme.spacing(3)}px `,
    padding: `${theme.spacing(2)}px 0 0 ${theme.spacing(2)}px`,
    borderLeft: `1px solid ${theme.palette.primary.contrastText}`,
    textAlign: 'right',
    '@media (max-width: 981px)': {
      display: 'none',
    },
  },
  logout: {
    '@media (min-width: 982px)': {
      display: 'none',
    },
  },
  logoXl: {
    maxWidth: '125px',
    cursor: 'pointer',
    marginLeft: 17,
  },
  logoXs: {
    maxWidth: '130px',
    cursor: 'pointer',
    marginLeft: 17,
  },
  logoutLink: {
    color: theme.palette.primary.contrastText,
    '&:hover': {
      color: theme.palette.primary.contrastText,
    },
  },
  space: {
    flexGrow: 1,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
  },
  titleWrap: {
    padding: `${theme.spacing(3)}px 0`,
    borderBottom: `${theme.spacing(0.6)}px solid ${
      theme.palette.primary.contrastText
    }`,
    '@media (max-width: 981px)': {
      display: 'none',
    },
  },
  icon: {
    color: theme.palette.primary.contrastText,
  },
}));

const Header = ({ children, title = null }) => {
  const classes = useStyles();
  const history = useHistory();
  const languages = useLanguages();
  const [anchor, setAnchor] = useState('left');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [language, setLanguage] = useState(languages[0]);
  const { user, isAuthenticated, logout } = useAuth();
  const { width } = useWindowDimensions();
  const { i18n } = useTranslation();

  const handleDrawerOpen = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleLangChange = async (lang) => {
    const [languageFound] = languages.filter(
      (language) => language.languageIso6391Code === lang.languageIso6391Code
    );
    await i18n.changeLanguage(lang.languageIso6391Code);
    setLanguage(languageFound);
    setCookie(COOKIE_LANG, lang.languageIso6391Code);
  };

  const handleLogoClick = () => {
    history.push('/');
  };

  useEffect(() => {
    (async () => {
      const lang = !isUndefined(getCookie(COOKIE_LANG))
        ? getCookie(COOKIE_LANG)
        : 'es';
      const [languageFound] = languages.filter(
        (language) => language.languageIso6391Code === lang
      );
      await i18n.changeLanguage(languageFound.languageIso6391Code);
      setLanguage(languageFound);
      setCookie(COOKIE_LANG, lang);
    })();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    width <= 768 ? setAnchor('bottom') : setAnchor('left');
  }, [width]);

  return (
    <AppBar position="static">
      {!isAuthenticated && (
        <Toolbar className={classes.toolbar}>
          <Box className={classes.space} />
          <CardMedia
            className={classes.logoXl}
            onClick={() => handleLogoClick()}
            component="img"
            alt="logo"
            image="assets/images/logo-dec.png"
          />
          <Box className={classes.space} />
          <LanguageMenu
            language={language}
            handleLangChange={handleLangChange}
          />
        </Toolbar>
      )}
      {isAuthenticated && (
        <Toolbar className={classes.auth}>
          <IconButton
            className={classes.icon}
            aria-label="menu"
            component="span"
            onClick={handleDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Box
            display="flex"
            alignItems="center"
            width={'100%'}
            justifyContent="space-between"
          >
            <CardMedia
              className={classes.logoXs}
              onClick={() => handleLogoClick()}
              component="img"
              alt="logo"
              image="assets/images/logo-dec.png"
            />
            <Box display="flex" alignItems="center">
              <Box className={classes.titleWrap}>
                <Typography
                  className={classes.title}
                  variant="body1"
                  component="div"
                >
                  {title}
                </Typography>
              </Box>
              <Box className={classes.body}>
                <Typography
                  className={classes.title}
                  variant="body1"
                  component="div"
                >
                  <Translation path="room" />
                  {` ${user.roomCode}`}
                </Typography>
                <Typography
                  className={classes.logoutLink}
                  variant="subtitle2"
                  component={Link}
                  to="/"
                  onClick={() => logout()}
                >
                  <Translation path="logout" />
                </Typography>
              </Box>
              <Box>
                <LanguageMenu
                  language={language}
                  handleLangChange={handleLangChange}
                />{' '}
                <IconButton
                  className={`${classes.icon} ${classes.logout}`}
                  onClick={() => logout()}
                >
                  <ExitToAppIcon />
                </IconButton>
              </Box>
            </Box>
            <Sidebar
              open={drawerOpen}
              anchor={anchor}
              onOpen={handleDrawerOpen}
            />
          </Box>
        </Toolbar>
      )}
      {children}
    </AppBar>
  );
};

Header.propTypes = {
  children: PropTypes.node,
};

Header.defaultProps = {};

export default Header;
