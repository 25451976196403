import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import createEncryptor from 'redux-persist-transform-encrypt';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import rootReducers from '@App/reducers';

const encryptor = createEncryptor({
  secretKey: 'my-super-secret-key',
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['login'],
  transforms: [encryptor],
};

const enhancer = applyMiddleware(thunk);

const configureStore = createStore(
  persistReducer(persistConfig, rootReducers),
  enhancer,
);
const persistor = persistStore(configureStore);

export { configureStore, persistor };
