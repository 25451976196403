import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components...
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import HorizontalDatepicker from '@App/components/HorizontalDatepicker';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Translation from '@App/components/Translation';
import Typography from '@material-ui/core/Typography';

// Stiles...
import { makeStyles } from '@material-ui/core/styles';

// Others...
import Axios from '@App/plugins/axios';
import moment from 'moment';
import TransferListSkeleton from './TransferListSkeleton';
import TransferList from './TransferList';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacing(4)}px 0`,
  },
  info: {
    backgroundColor: theme.palette.primary.contrastText,
    marginTop: '16px',
    textAlign: 'center',
    padding: '15px 25px',
    borderRadius: '.25rem',
  },
  datePicker: {
    width: '75%',
  },
}));

const Transfer = () => {
  const classes = useStyles();
  const history = useHistory();

  const [transfers, setTransfers] = useState({
    data: [],
    loading: false,
    error: null,
  });
  const [selectDate, setSelectDate] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(
    moment().format('YYYY-MM-DD').toString()
  );

  const fetchTours = async (newDate) => {
    try {
      setTransfers({ data: [], loading: true, error: null });
      const resource = await Axios.get(
        `local/transfers?status=true&exitDate=${newDate}&_distinct=number,exitDate,pickupTime,pickupPlace,destination`
      );
      setTransfers({ data: resource.data, loading: false, error: null });
    } catch (error) {
      setTransfers({ data: [], loading: true, error });
    }
  };

  const handleDateChangeClick = async (date) => {
    const newDate = moment(date).format('YYYY-MM-DD').toString();
    if (newDate !== currentDate) {
      await fetchTours(newDate);
      setCurrentDate(newDate);
      setSelectDate(date);
    }
  };

  const handleBackClick = () => {
    history.push('/transport-and-tours');
  };

  const fetchData = async () => {
    await fetchTours(currentDate);
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  return (
    <Container maxWidth="md">
      <Button
        onClick={handleBackClick}
        startIcon={<NavigateBeforeIcon />}
        variant="contained"
        color="primary"
        size="small"
      >
        <Translation path="back" />
      </Button>
      <Box mt={2}>
        <Box display="flex" justifyContent="center">
          <Box className={classes.datePicker}>
            <HorizontalDatepicker
              selectDate={selectDate}
              getSelectedDay={handleDateChangeClick}
              endDate={60}
              labelFormat="MMMM"
              color="#702f6d"
            />
          </Box>
        </Box>
        <Box className={classes.info}>
          <Typography variant="body1" gutterBottom>
            <Translation path="more.info.transfers" />
          </Typography>
        </Box>
      </Box>
      <Box className={classes.root}>
        {transfers.loading ? (
          <TransferListSkeleton />
        ) : (
          <TransferList transfers={transfers.data} />
        )}
      </Box>
    </Container>
  );
};

export default Transfer;
