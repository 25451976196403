import Card from '@App/components/Card';
import Container from '@App/components/Container';
import useMaterialConfirm from '@App/hooks/useMaterialConfirm';
import useMaterialSnackbar from '@App/hooks/useMaterialSnackbar';
import useAuth from '@App/hooks/useAuth';
import Axios from '@App/plugins/axios';
import moment from '@App/plugins/moment';
import DayGridPlugin from '@fullcalendar/daygrid';
import InteractionPlugin from '@fullcalendar/interaction';
import ListPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import TimeGridPlugin from '@fullcalendar/timegrid';
import Box from '@material-ui/core/Box';
import MuiCardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import classNames from 'classnames';
import { isEmpty, isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import TourBookingDialogConfirmForm from './TourBookingDialogConfirmForm';
import TourBookingSkeleton from './TourBookingSkeleton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '@media (max-width: 981px)': {
      padding: '0 !important',
    },
  },
  descBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light,
    minHeight: '70px',
  },
  descBlockButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.contrastText,
  },
  descBlockTitle: {
    marginLeft: theme.spacing(-12),
    color: theme.palette.primary.contrastText,
  },
  fullCalendar: {
    '@media (max-width: 981px)': {
      padding: '0 !important',
    },
    '& .fc-daygrid-day': {
      opacity: 0.5,
      backgroundColor: '#ddd',
    },
    '& .fc-cell-active': {
      opacity: 1,
    },
    '& .fc-cell-active:not(.fc-day-today) > div': {
      backgroundColor: '#fff',
      transition: 'all 0.4s ease',
      cursor: 'pointer',
    },
    '& .fc-cell-active:not(.fc-day-today):hover > div': {
      backgroundColor: '#fffcdb',
    },
  },
  event: {
    padding: theme.spacing(0.5),
    display: 'flex',
    width: '100%',
  },
  eventPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  eventSecondary: {
    backgroundColor: theme.palette.error.main,
  },
  eventBody: {
    width: '100%',
  },
  eventTitle: {
    color: theme.palette.primary.contrastText,
    fontSize: 13,
    marginRight: theme.spacing(1),
  },
  eventTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  eventTime: {
    color: theme.palette.primary.contrastText,
    fontSize: 10,
    marginRight: theme.spacing(1),
  },
}));

const TourBooking: React.FC<any> = () => {
  const classes = useStyles();
  const history = useHistory();
  const confirm = useMaterialConfirm();
  const snackbar = useMaterialSnackbar();
  const { t: translate, i18n }: any = useTranslation();
  const { tourId }: any = useParams();
  const { user } = useAuth();

  const [tour, setTour]: any = useState({
    data: null,
    loading: false,
    error: null,
  });
  const [tourBookings, setTourBookings] = useState({
    data: [],
    loading: false,
    error: null,
  });
  const [event, setEvent]: any = useState({
    id: null,
    title: null,
    start: null,
    end: null,
  });
  const [events, setEvents] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const hasTour = () => !isUndefined(tourId);

  const getHeaderToolbar = () => {
    const right = tourId ? 'dayGridMonth,listMonth' : 'listMonth';
    return {
      left: 'prev,next',
      center: 'title',
      right,
    };
  };

  const handleCloseClick = () => {
    setOpenDialog(false);
  };

  const createTourBooking = async (formData: any) => {
    const startDate = moment(`${event.day} ${tour.data.schedule}`).format(
      'YYYY-MM-DD HH:mm'
    );
    const endDate = moment(`${event.day} ${tour.data.schedule}`)
      .add(tour.data.durationInMinutes, 'minutes')
      .format('YYYY-MM-DD HH:mm');
    try {
      const personReferenceId = `${user.identityDocumentCountryId}:${user.identityDocumentType}:${user.identityDocumentCode}`;
      const resource = await Axios.post('/local/tour-bookings?_embed=tour', {
        languageIso6391Code: formData.languageIso6391Code,
        adultsNumber: formData.adultsNumber,
        childrenNumber: formData.childrenNumber,
        bookedDate: event.day,
        tourId: tour.data.id,
        personReferenceId,
        user: user,
      });
      // @ts-ignore
      setEvents([
        ...events,
        {
          ...event,
          ...resource.data,
          start: startDate,
          end: endDate,
        },
      ]);
      // @ts-ignore
      await confirm({
        title: translate('create.reservation.confirmation.title', {
          bookingNumber: resource.data.referenceId,
        }),
        description: translate('create.reservation.confirmation.description'),
        dialogProps: { maxWidth: 'xs' },
        cancellationButtonProps: { style: { display: 'none' } },
      });
      // @ts-ignore
      snackbar({
        message: translate('create.reservation.confirmation.title', {
          bookingNumber: resource.data.referenceId,
        }),
      });
    } catch (error) {
      // @ts-ignore
      snackbar({ variant: 'error', message: error.message });
    }
  };

  const updateTourBooking = async (formData: any) => {
    const indexEvent = events.findIndex((item: any) => item.id === event.id);
    try {
      const resource = await Axios.put(`/local/tour-bookings/${event.id}`, {
        languageIso6391Code: formData.languageIso6391Code,
        adultsNumber: formData.adultsNumber,
        childrenNumber: formData.childrenNumber,
      });
      setEvents(
        Object.assign([...events], {
          [indexEvent]: {
            ...event,
            ...resource.data,
          },
        })
      );
      // @ts-ignore
      snackbar({
        message: translate('update.reservation.confirmation'),
      });
    } catch (error) {
      // @ts-ignore
      snackbar({ variant: 'error', message: error.message });
    }
  };

  const handleSaveClick = async (formData: any) => {
    setLoadingDialog(true);
    if (event.id) {
      await updateTourBooking(formData);
    } else {
      await createTourBooking(formData);
    }
    setLoadingDialog(false);
    setOpenDialog(false);
  };

  const handleRemoveClick = async () => {
    setLoadingDialog(true);
    try {
      // @ts-ignore
      await confirm({ dialogProps: { maxWidth: 'xs' } });

      await Axios.delete(`/local/tour-bookings/${event.id}`);
      setEvents(events.filter((item: any) => item.id !== event.id));
      setOpenDialog(false);
      // @ts-ignore
      snackbar({
        message: translate('delete.reservation.confirmation'),
      });
    } catch (error) {
      // @ts-ignore
      error && snackbar({ variant: 'error', message: error.message });
    }
    setLoadingDialog(false);
  };

  const buildEvents = (tourBookings: any) => {
    const resource = tourBookings.map((tourBooking: any) => {
      const startDate = moment(
        `${tourBooking.bookedDate} ${tourBooking.tour.schedule}`
      ).format('YYYY-MM-DD HH:mm');
      const endDate = moment(
        `${tourBooking.bookedDate} ${tourBooking.tour.schedule}`
      )
        .add(tourBooking.tour.durationInMinutes, 'minutes')
        .format('YYYY-MM-DD HH:mm');
      return {
        title: tourBooking.tour.name,
        start: startDate,
        end: endDate,
        day: tourBooking.bookedDate,
        ...tourBooking,
      };
    });
    return resource;
  };

  const handleEventClick = (info: any) => {
    const date = moment(info.event.extendedProps.day).format('YYYY-MM-DD');
    const currentDate = moment().format('YYYY-MM-DD');
    if (
      parseInt(tourId, 10) === info.event.extendedProps.tour.id &&
      date > currentDate
    ) {
      setEvent({
        id: parseInt(info.event.id, 10),
        title: info.event.title,
        start: moment(info.event.startStr).format('YYYY-MM-DD HH:mm'),
        end: moment(info.event.endStr).format('YYYY-MM-DD HH:mm'),
        ...info.event.extendedProps,
      });
      setOpenDialog(true);
    }
  };

  const handleDayClick = (info: any) => {
    const date = moment(info.dateStr).format('YYYY-MM-DD');
    const currentDate = moment().format('YYYY-MM-DD');
    const dayNumber = moment(info.dateStr).format('d');

    if (
      hasTour() &&
      date > currentDate &&
      tour.data.applyForDays?.includes(dayNumber)
    ) {
      setEvent({
        id: null,
        title: tour.data.name,
        day: date,
        adultsNumber: 1,
        childrenNumber: 0,
      });
      setOpenDialog(true);
    }

    if (
      hasTour() &&
      date === currentDate &&
      tour.data.applyForDays.includes(dayNumber)
    ) {
      // @ts-ignore
      snackbar({
        variant: 'info',
        message: translate('tours.booking.currentDay'),
      });
    }
  };

  const renderDatesSet = (arg: any) => {
    const startDate = moment(arg.start);
    const endDate = moment(arg.end);
    const currentDate = moment();

    const date = startDate;

    while (date < endDate) {
      const dayCell: any = document.querySelector(
        `td[data-date="${date.format('YYYY-MM-DD')}"]`
      );

      if (
        hasTour() &&
        date > currentDate &&
        tour.data.applyForDays?.includes(date.format('d'))
      ) {
        dayCell && dayCell.classList.add('fc-cell-active');
      }

      date.add(1, 'days');
    }
  };

  const renderEventContent = (info: any) => {
    const startDateTime = moment(info.event.startStr).format('H:mm A');
    const endDateTime = moment(info.event.endStr).format('H:mm A');
    return (
      <div
        className={classNames(
          classes.event,
          {
            [`${classes.eventPrimary}`]:
              info.event.extendedProps.tour.id === parseInt(tourId, 10),
          },
          {
            [`${classes.eventSecondary}`]:
              info.event.extendedProps.tour.id !== parseInt(tourId, 10),
          }
        )}
        onClick={() => handleEventClick(info)}
      >
        <div className={classes.eventBody}>
          <div className={classes.eventTitleWrapper}>
            <span className={classes.eventTitle}>{info.event.title}</span>
            <span className={classes.eventTitle}>
              {info.event.extendedProps.bookedQuantity}
            </span>
          </div>
          <span className={classes.eventTime}>
            {startDateTime} {translate('to')} {endDateTime}
          </span>
        </div>
      </div>
    );
  };

  const fetchTour = async () => {
    try {
      setTour({ ...tour, loading: true, error: null });
      const resource = await Axios.get(`local/tours/${tourId}`);
      setTour({ data: resource.data, loading: false, error: null });
    } catch (error) {
      // @ts-ignore
      snackbar({ variant: 'error', message: error.message });
      setTour({ data: null, loading: true, error });
    }
  };

  const fetchTourBookings = async () => {
    try {
      const personReferenceId = `${user.identityDocumentCountryId}:${user.identityDocumentType}:${user.identityDocumentCode}`;
      setTourBookings({ ...tourBookings, loading: true, error: null });
      const resource = await Axios.get(
        `/local/tour-bookings?personReferenceId=${personReferenceId}&_embed=tour`
      );
      setTourBookings({ data: resource.data, loading: false, error: null });
      setEvents(buildEvents(resource.data));
    } catch (error) {
      setTourBookings({ data: [], loading: true, error });
    }
  };

  const checkAddedUser = () => {
    isEmpty(user) && history.push('/tours');
  };

  const fetchData = async () => {
    // eslint-disable-next-line no-unused-expressions
    hasTour() && (await fetchTour());
    await fetchTourBookings();
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await checkAddedUser();
      await fetchData();
    })();
  }, []);

  return (
    <Box className={classes.root}>
      <Box className={classes.descBlock}>
        <IconButton
          className={classes.descBlockButton}
          aria-label="back"
          onClick={() => history.goBack()}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <Typography
          className={classes.descBlockTitle}
          variant="h6"
          gutterBottom
        >
          {tour.data?.name}
        </Typography>
        <Box />
      </Box>
      <Container className={classes.root} maxWidth="md">
        <Card>
          <MuiCardContent className={classes.fullCalendar}>
            {loading || tour.loading ? (
              <TourBookingSkeleton />
            ) : (
              <>
                <FullCalendar
                  plugins={[
                    TimeGridPlugin,
                    DayGridPlugin,
                    ListPlugin,
                    InteractionPlugin,
                  ]}
                  height="auto"
                  headerToolbar={getHeaderToolbar()}
                  titleFormat={{ year: 'numeric', month: 'long' }}
                  buttonText={
                    {
                      today: translate('day'),
                      month: translate('booking'),
                      week: translate('week'),
                      day: translate('day'),
                      list: translate('reservations.my.reservations'),
                    } as any
                  }
                  initialView="dayGridMonth"
                  events={events}
                  locale={i18n.language}
                  dateClick={handleDayClick}
                  eventContent={renderEventContent}
                  datesSet={renderDatesSet}
                  dayMaxEventRows={2}
                  showNonCurrentDates={false}
                />
                <TourBookingDialogConfirmForm
                  tour={tour.data}
                  event={event}
                  open={openDialog}
                  onClose={handleCloseClick}
                  onSave={handleSaveClick}
                  onRemove={handleRemoveClick}
                  loading={loadingDialog}
                />
              </>
            )}
          </MuiCardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default TourBooking;
