import '@App/assets/css';
import 'react-add-to-calendar/dist/react-add-to-calendar.css';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ActivityLayout from '@App/layouts/ActivityLayout';
import AuthLayout from '@App/layouts/AuthLayout';
import MenuLayout from '@App/layouts/MenuLayout';
import RestaurantLayout from '@App/layouts/RestaurantLayout';
import TourLayout from '@App/layouts/TourLayout';
import TransferLayout from '@App/layouts/TransferLayout';
import AuthWrapper from '@App/providers/Authentication';
import UnauthorizedLayout from '@App/layouts/UnauthorizedLayout';
import NotFoundLayout from '@App/layouts/NotFoundLayout';
import { configureStore } from '@App/store/configureStore';

const App = () => (
  <div>
    <Provider store={configureStore}>
      <Router basename={process.env.PUBLIC_URL}>
        <AuthWrapper>
          <Switch>
            <Route exact path="/" component={MenuLayout} />
            <Route exact path="/transport-and-tours" component={MenuLayout} />
            <Route path="/fact-sheets" component={MenuLayout} />
            <Route path="/transfers" component={TransferLayout} />
            <Route path="/tours" component={TourLayout} />
            <Route path="/activities" component={ActivityLayout} />
            <Route path="/restaurants" component={RestaurantLayout} />
            <Route path="/auth" component={AuthLayout} />
            <Route path="/404" component={NotFoundLayout} />
            <Route path="/not-authorized" component={UnauthorizedLayout} />
            <Route component={NotFoundLayout} />
          </Switch>
        </AuthWrapper>
      </Router>
    </Provider>
  </div>
);

export default App;
