import BlockUi from '@App/components/BlockUi';
import useLanguages from '@App/hooks/useLanguages';
import yup from '@App/plugins/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { isEmpty } from 'lodash';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  dialogActions: {
    justifyContent: 'space-between',
  },
  dialogActionsRemove: {
    color: theme.palette.error.main,
  },
}));

const TourBookingDialogConfirmForm: React.FC<any> = ({
  tour,
  open,
  event,
  onClose,
  onSave,
  onRemove,
  loading,
}) => {
  const classes = useStyles();
  const languages = useLanguages();
  const { t: translate }: any = useTranslation();

  const schema = yup.object().shape({
    languageIso6391Code: yup.string().required(),
    adultsNumber: yup.number().required().integer().min(1).max(500),
    childrenNumber: yup.number().required().integer().min(0).max(500),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const {
    control,
    handleSubmit: handleSubmitHook,
    errors,
    formState,
  } = methods;

  const getAllowedLanguages = () => {
    const allowedLanguages: any[] = [];
    const languageIsoPrefixes: any = tour?.serviceLanguages
      ? tour.serviceLanguages.split(',')
      : languages.map((language: any) => language.languageIso6391Code);

    languageIsoPrefixes.forEach((prefix: string) => {
      const filteredLanguages = languages.filter(
        (language: any) => language.languageIso6391Code === prefix
      );
      if (!isEmpty(filteredLanguages)) {
        allowedLanguages.push(filteredLanguages[0]);
      }
    });
    return allowedLanguages;
  };

  return (
    <MuiDialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
    >
      <BlockUi blocking={loading}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmitHook(onSave)}>
            <DialogTitle id="max-width-dialog-title">
              {translate('turn.booking.confirm.title')}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl variant="outlined">
                    <InputLabel id="identity-document-type">
                      {translate('turn.booking.language')}
                    </InputLabel>
                    <Controller
                      name="languageIso6391Code"
                      error={Boolean(errors.languageIso6391Code)}
                      control={control}
                      labelId="booked-quote-select"
                      as={
                        <Select>
                          {getAllowedLanguages().map(
                            (language: any, index: number) => (
                              <MenuItem
                                key={index}
                                value={language.languageIso6391Code}
                              >
                                <em>{language.text}</em>
                              </MenuItem>
                            ),
                          )}
                        </Select>
                      }
                      defaultValue={event.languageIso6391Code}
                      label={translate('turn.booking.language')}
                      disabled={event.id}
                      fullWidth
                    />
                    <FormHelperText>
                      {errors.languageIso6391Code?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    variant="outlined"
                    name="adultsNumber"
                    control={control}
                    as={TextField}
                    label={translate('turn.booking.adultsNumber')}
                    error={Boolean(errors.adultsNumber)}
                    helperText={errors.adultsNumber?.message}
                    defaultValue={event.adultsNumber}
                    disabled={event.id}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    variant="outlined"
                    name="childrenNumber"
                    control={control}
                    as={TextField}
                    label={translate('turn.booking.childrenNumber')}
                    error={Boolean(errors.childrenNumber)}
                    helperText={errors.childrenNumber?.message}
                    defaultValue={event.childrenNumber}
                    disabled={event.id}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Box>
                {/* event.id && (
                  <Button
                    className={classes.dialogActionsRemove}
                    onClick={onRemove}
                  >
                    {translate('delete')}
                  </Button>
                ) */}
                <Button onClick={onClose} color="primary">
                  {translate('close')}
                </Button>
              </Box>
              <Box>
                {!event.id && (
                  <Button
                    type="submit"
                    color="primary"
                    disabled={!formState.isValid}
                  >
                    {translate('save')}
                  </Button>
                )}
              </Box>
            </DialogActions>
          </form>
        </FormProvider>
      </BlockUi>
    </MuiDialog>
  );
};

export default TourBookingDialogConfirmForm;
