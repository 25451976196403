import classNames from 'classnames';
import React from 'react';

import Card from '@App/components/Card';
import CardBadgeCircle from '@App/components/Card/CardBadgeCircle';
import Date from '@App/components/Date';
import Translation from '@App/components/Translation';
import RestaurantBookingDate from '@App/types/RestaurantBookingDate';
import { makeStyles } from '@material-ui/core';
import MuiCardContent from '@material-ui/core/CardContent';

interface RestaurantBookingDateCardProps {
  bookingDate: RestaurantBookingDate;
  handleTurnsModal: Function;
}

const useStyles = makeStyles(() => ({
  card: {
    cursor: 'pointer',
  },
}));

const RestaurantBookingDateCard: React.FC<
  RestaurantBookingDateCardProps & React.HTMLAttributes<HTMLElement>
> = ({ bookingDate, handleTurnsModal, ...props }) => {
  const classes = useStyles();

  const onClickCard = () => {
    handleTurnsModal(bookingDate, true);
  };

  return (
    <Card
      onClick={onClickCard}
      className={classNames(classes.card)}
      elevation={2}
      badge={
        <CardBadgeCircle
          slug={bookingDate.bookedHostQuota}
          text={
            bookingDate.bookedHostQuota > 0 && (
              <Translation path="list.dates.my.done.reservations" />
            )
          }
        />
      }
      {...props}
    >
      <MuiCardContent>
        <Date date={bookingDate.date} />
      </MuiCardContent>
    </Card>
  );
};

export default RestaurantBookingDateCard;
