const applicationTheme = () => ({
  palette: {
    primary: {
      light: '#6BB6D7',
      main: '#469dc3',
      dark: '#2886AF',
      contrasts: 'rgba(70, 157, 195, 0.62)',
      contrastText: '#FFF',
    },
    secondary: {
      light: '#874C85',
      main: '#702f6d',
      dark: '#5B1B59',
      contrasts: 'rgba(112, 47, 109, 0.62)',
      contrastText: '#fff',
    },
    error: {
      light: '#FFA8A8',
      main: '#F47C7C',
      dark: '#CE4C4C',
      contrasts: 'rgba(244, 124, 124, 0.62)',
      contrastText: '#fff',
    },
    warning: {
      light: '#FFC16A',
      main: '#ffb347',
      dark: '#F0981A',
      contrasts: 'rgba(255, 179, 71, 0.62)',
      contrastText: '#fff',
    },
    info: {
      light: '#80D6FA',
      main: '#57C7F8',
      dark: '#30B8F3',
      contrasts: 'rgba(87, 199, 248, 0.62)',
      contrastText: '#fff',
    },
    success: {
      light: '#28A586',
      main: '#059F78',
      dark: '#007C5D',
      contrasts: 'rgba(5, 159, 120, 0.62)',
      contrastText: '#fff',
    },
    dark: {
      light: '#878787',
      main: '#575757',
      dark: '#424242',
      contrasts: 'rgba(87, 87, 87, 0.62)',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: ['akrobat-regular', 'sans-serif'].join(','),
  },
  overrides: {
    MuiTypography: {
      root: {
        // color: '#575757',
      },
    },
    MuiDialogTitle: {
      root: {
        position: 'relative',
        marginBottom: 12,
        '&:after': {
          content: '""',
          position: 'absolute',
          width: 60,
          height: 4,
          background: '#469ec3',
          bottom: 0,
          left: 24,
        },
      },
    },
    MuiPaper: {
      root: {
        color: '#575757',
      },
    },
    MuiFormHelperText: {
      root: {
        marginLeft: '0 !important',
        marginRight: '0 !important',
      },
    },
    MuiContainer: {
      root: {
        padding: '32px 0',
      },
    },
    MuiCard: {
      root: {
        boxShadow: '2px -2px 15px 0px rgba(173, 173, 173, 0.24)',
      },
    },
    MuiTab: {
      root: {
        backgroundColor: 'white',
        boxShadow: '3px -2px 6px rgba(64,64,64,.07)',
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
        margin: 0,
      },
    },
  },
});

export default applicationTheme;
