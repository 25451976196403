// Bookings
export const BOOKINGS_FIND_INIT = 'BOOKINGS_FIND_INIT';
export const BOOKINGS_FIND_SUCCESS = 'BOOKINGS_FIND_SUCCESS';
export const BOOKINGS_FIND_ERROR = 'BOOKINGS_FIND_ERROR';
export const BOOKINGS_UPDATE_INIT = 'BOOKINGS_UPDATE_INIT';
export const BOOKINGS_UPDATE_SUCCESS = 'BOOKINGS_UPDATE_SUCCESS';
export const BOOKINGS_UPDATE_ERROR = 'BOOKINGS_UPDATE_ERROR';
export const BOOKINGS_DELETE_INIT = 'BOOKINGS_DELETE_INIT';
export const BOOKINGS_DELETE_SUCCESS = 'BOOKINGS_DELETE_SUCCESS';
export const BOOKINGS_DELETE_ERROR = 'BOOKINGS_DELETE_ERROR';
export const BOOKING_UPDATE = 'BOOKING_UPDATE';
export const BOOKING_STEP_UPDATE = 'BOOKING_STEP_UPDATE';
export const BOOKING_RESET = 'BOOKING_RESET';
export const BOOKING_CREATE_INIT = 'BOOKING_CREATE_INIT';
export const BOOKING_CREATE_SUCCESS = 'BOOKING_CREATE_SUCCESS';
export const BOOKING_CREATE_ERROR = 'BOOKING_CREATE_ERROR';
