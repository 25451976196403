import { createContext } from 'react';

const context = createContext({
  isAuthenticated: false,
  user: {},
  refresh: () => {},
});

export const AuthContext = context;
export const AuthProvider = context.Provider;
export const AuthConsumer = context.Consumer;
