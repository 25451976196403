import { useDispatch, useSelector } from 'react-redux';

import RestaurantBooking from '@App/types/RestaurantBooking';
import User from '@App/types/User';

import {
  bookingCreateAction,
  bookingsDeleteAction,
  bookingsFindAction,
  bookingStepUpdateAction,
  bookingsUpdateAction,
  bookingUpdateAction,
  bookingResetAction,
} from '../actions';

const useBookings = () => {
  const dispatch = useDispatch();

  return {
    bookings: useSelector((state: any) => state.restaurant.bookings),
    booking: useSelector((state: any) => state.restaurant.booking),
    step: useSelector((state: any) => state.restaurant.step),
    find: (user: User) => dispatch(bookingsFindAction(user)),
    update: (booking: RestaurantBooking) =>
      dispatch(bookingUpdateAction(booking)),
    updateAll: (
      bookings: RestaurantBooking[],
      booking: RestaurantBooking,
      user: User
    ) => dispatch(bookingsUpdateAction(bookings, booking, user)),
    delete: (bookings: RestaurantBooking[], booking: RestaurantBooking) =>
      dispatch(bookingsDeleteAction(bookings, booking)),
    updateStep: (step: number) => dispatch(bookingStepUpdateAction(step)),
    create: (booking: any) => dispatch(bookingCreateAction(booking)),
    reset: () => dispatch(bookingResetAction()),
  };
};

export default useBookings;
