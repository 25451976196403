import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import MatDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import GroupIcon from '@material-ui/icons/Group';
import { makeStyles } from '@material-ui/core/styles';
import yup from '@App/plugins/yup';
import moment from 'moment';
import Translation from '@App/components/Translation';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isUndefined } from 'lodash';
import ButtonProgress from '@App/components/ButtonProgress';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  avatar: {
    margin: theme.spacing(1),
    color: theme.palette.dark.light,
  },
  avatarText: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
  dialogActionsRemove: {
    color: theme.palette.error.main,
  },
  quantity: {
    position: 'relative',
    top: '-12px',
  },
}));

const schema = yup.object().shape({
  activityTurnDistributionId: yup.number().required(),
  bookedQuantity: yup.number().required().min(1),
});

const ActivityBookingDialog = ({
  open,
  activity,
  event,
  onClose,
  onSave,
  onRemove,
  loading,
}) => {
  const classes = useStyles();
  const [dayDistributions, setDayDistributions] = useState([]);
  const { i18n } = useTranslation();

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const { control, handleSubmit, errors } = methods;

  const formatDateDistribution = (distribution) => {
    const startDate = `${distribution.date} ${distribution.activityTurn.timeStart}`;
    const endDate = `${distribution.date} ${distribution.activityTurn.timeEnd}`;
    return `${moment(startDate).format('HH:mm')} a ${moment(endDate).format(
      'HH:mm'
    )}`;
  };

  useEffect(() => {
    const distributions = activity.data?.distributions.filter(
      (distribution) => distribution.date === event.day
    );
    if (!isUndefined(distributions)) {
      setDayDistributions(distributions);
    }
  }, [event]);

  return (
    <MatDialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="xs"
      fullWidth
    >
      {loading && <ButtonProgress />}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSave)}>
          <DialogTitle id="simple-dialog-title">
            <Box component="span" display="flex" justifyContent="space-between">
              <Translation path="booking" />
              <Box component="span">
                <GroupIcon className={classes.avatar} />
                <Box component="span" className={classes.quantity}>
                  {event.bookedQuantity || 0} | {event.totalHosts}
                </Box>
              </Box>
            </Box>
          </DialogTitle>
          <List>
            <ListItem key={event.id}>
              <Grid container spacing={2}>
                <Grid container>
                  <Avatar className={classes.avatar}>
                    <i className={event.activity.icon} />
                  </Avatar>
                  <Box className={classes.avatarText}>
                    {event.activity?.translations?.[i18n.language]?.name ||
                      event.title}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    error={Boolean(errors.activityTurnDistributionId)}
                    className={classes.formControl}
                    aria-describedby="name-error-text"
                  >
                    <InputLabel htmlFor="name-error">
                      <Translation path="schedules" />
                    </InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value=" ">
                            <Translation path="select" />{' '}
                          </MenuItem>
                          {dayDistributions.map((distribution) => (
                            <MenuItem
                              key={distribution.id}
                              value={distribution.id}
                            >
                              {formatDateDistribution(distribution)}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      name="activityTurnDistributionId"
                      control={control}
                      defaultValue={event.activityTurnDistributionId}
                    />
                    <FormHelperText id="name-error-text">
                      {errors.activityTurnDistributionId &&
                        errors.activityTurnDistributionId.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    error={Boolean(errors.bookedQuantity)}
                    className={classes.formControl}
                    aria-describedby="name-error-text"
                  >
                    <InputLabel htmlFor="name-error">
                      <Translation path="quotas.to.be.reserved" />
                    </InputLabel>
                    <Controller
                      name="bookedQuantity"
                      as={<Input />}
                      control={control}
                      defaultValue={event.bookedQuantity}
                    />
                    <FormHelperText>
                      {errors.bookedQuantity && errors.bookedQuantity.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </ListItem>
          </List>

          <DialogActions className={classes.dialogActions}>
            <Box>
              {event.id && (
                <Button
                  className={classes.dialogActionsRemove}
                  onClick={onRemove}
                >
                  <Translation path="delete" />
                </Button>
              )}
            </Box>
            <Box>
              <Button onClick={onClose} color="primary">
                <Translation path="cancel" />
              </Button>
              <Button type="submit" color="primary">
                <Translation path="save" />
              </Button>
            </Box>
          </DialogActions>
        </form>
      </FormProvider>
    </MatDialog>
  );
};

ActivityBookingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  activity: PropTypes.object,
  event: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    day: PropTypes.string,
    bookedQuantity: PropTypes.number,
    totalHosts: PropTypes.number,
    activityTurnDistributionId: PropTypes.number,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default ActivityBookingDialog;
