import CryptoJS from 'crypto-js';
import { ENCRYPTION_KEY } from '@App/utils/constants';

const key = CryptoJS.enc.Base64.parse(ENCRYPTION_KEY.substr(7));

export const PARAM_STRING = 'string';
export const PARAM_JSON = 'json';

export const encrypt = (data) => {
  let iv = CryptoJS.lib.WordArray.random(16);
  const encrypted = CryptoJS.AES.encrypt(data, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();

  iv = CryptoJS.enc.Base64.stringify(iv);

  let result = {
    iv,
    value: encrypted,
    mac: CryptoJS.HmacSHA256(iv + encrypted, key).toString(),
  };

  result = CryptoJS.enc.Utf8.parse(JSON.stringify(result));
  return CryptoJS.enc.Base64.stringify(result);
};

export const decrypt = (encryptStr) => {
  const str = JSON.parse(atob(encryptStr));
  const iv = CryptoJS.enc.Base64.parse(str.iv);

  return CryptoJS.AES.decrypt(str.value, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString(CryptoJS.enc.Utf8);
};

export const decryptToLaravel = (encryptStr, paramType = PARAM_STRING) => {
  const regex = /(\{.*\})/g;
  let response = null;
  switch (paramType) {
    case PARAM_STRING:
      throw new Error('type of decryption disabled');
    case PARAM_JSON:
      // eslint-disable-next-line prefer-destructuring
      response = decrypt(encryptStr).match(regex)[0];
      break;
    default:
      throw new Error('type of decryption not available');
  }
  return response;
};
