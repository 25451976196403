import React from 'react';
import PropTypes from 'prop-types';

// Components...
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Translation from '@App/components/Translation';
import TransferCard from './TransferCard';

const TransferList = ({ transfers }) => {
  const hasTransfers = () => transfers.length > 0;

  return hasTransfers() ? (
    <Grid container spacing={5}>
      {transfers.map((transfer) => (
        <Grid key={transfer.id} md={6} xs={12} item>
          <TransferCard transfer={transfer} />
        </Grid>
      ))}
    </Grid>
  ) : (
    <Alert severity="info">
      <Translation path="tours.available" />
    </Alert>
  );
};

TransferList.propTypes = {
  transfers: PropTypes.array.isRequired,
};

export default TransferList;
