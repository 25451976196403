import React from 'react';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { HAS_RECAPTCHA_GOOGLE, RECAPTCHA_GOOGLE_ID } from '@App/utils/env';

const GoogleReCaptcha = ({ children }) => {
  if (HAS_RECAPTCHA_GOOGLE) {
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={RECAPTCHA_GOOGLE_ID}
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: 'head', // optional, default to "head", can be "head" or "body",
          nonce: undefined, // optional, default undefined
        }}
      >
        {children}
      </GoogleReCaptchaProvider>
    );
  }
  return children;
};

export default GoogleReCaptcha;
