import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Components...
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextClipper from '@App/components/TextClipper/TextClipper';
import Translation, { translate } from '@App/components/Translation';
import useTranslationHelper from '@App/hooks/useTranslationHelper';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import Divider from '@material-ui/core/Divider';
import AddToCalendar from 'react-add-to-calendar';

// Stiles...
import { makeStyles } from '@material-ui/core/styles';

// Others...
import moment from 'moment';
import days from '@App/refactor/common/consts/days';
import months from '@App/refactor/common/consts/months';
import { diffSeconds } from '@App/refactor/common/utils';

const useStyles = makeStyles((theme) => ({
  dateDayNumber: {
    color: theme.palette.success.main,
  },
  dateDayLetter: {
    fontWeight: 'bold',
  },
  dateMonthLetter: {
    position: 'relative',
    top: theme.spacing(-0.5),
  },
  airlineName: {
    marginRight: theme.spacing(0.5),
  },
  airlineNumber: {
    color: theme.palette.success.main,
  },
  arrowIco: {
    fontSize: '3.5rem',
    color: theme.palette.secondary.main,
  },
  clockIco: {
    fontSize: '1.1rem',
    color: theme.palette.secondary.main,
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    '&hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  hour: {
    position: 'relative',
    top: theme.spacing(-1.5),
  },
  subtitle: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
}));

const TransferCard = ({ t, transfer }) => {
  const classes = useStyles();
  const { getTranslation } = useTranslationHelper();

  const event = () => {
    const exitDate = `${transfer.exitDate} ${transfer.exitTime}`;
    const differenceSeconds = diffSeconds(
      moment.utc(transfer.pickupTime, 'HH:mm:ss A'),
      moment.utc(transfer.exitTime, 'HH:mm:ss A')
    );
    const datePickupTime = moment
      .utc(exitDate)
      .subtract(differenceSeconds, 's')
      .format();
    return {
      title: `${transfer.description} ${transfer.code}${transfer.number}`,
      description: translate({ t, path: 'transfer.description' }).replace(
        '{date}',
        moment.utc(exitDate).format('YYYY-MM-DD h:mm:ss A')
      ),
      location: `${transfer.destination}`,
      startTime: datePickupTime,
      endTime: moment.utc(datePickupTime).add(1, 'h').format(),
    };
  };

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Typography className={classes.dateDayNumber} variant="h3">
              {new Date(transfer.exitDate).getUTCDate()}
            </Typography>
            <Box ml={1} display="flex" flexDirection="column">
              <Typography
                className={classes.dateDayLetter}
                variant="body1"
                display="block"
              >
                <Translation
                  path={days[new Date(transfer.exitDate).getUTCDay()]}
                />
              </Typography>
              <Typography
                className={classes.dateMonthLetter}
                variant="body2"
                display="block"
              >
                <Translation
                  path={months[new Date(transfer.exitDate).getUTCMonth()]}
                />
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              className={classes.airlineName}
              variant="h5"
              component="span"
            >
              {transfer.description}
            </Typography>
            <Typography
              className={classes.airlineNumber}
              variant="h5"
              component="span"
            >
              {transfer.number}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            flexGrow={1}
            ml={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography variant="subtitle1">{transfer.pickupPlace}</Typography>
            <Typography variant="subtitle2">
              <Translation path="pickup.place" />
            </Typography>
          </Box>
          <Box
            flexGrow={1}
            ml={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <TrendingFlatIcon className={classes.arrowIco} />
            <Box className={classes.hour} display="flex" alignItems="center">
              <WatchLaterIcon className={classes.clockIco} />
              <Typography variant="h4">
                {moment.utc(transfer.pickupTime, 'hh:mm').format('h:mm')}
              </Typography>
              <Typography variant="body2">
                {moment.utc(transfer.pickupTime, 'hh:mm').format('A')}
              </Typography>
            </Box>
          </Box>
          <Box
            flexGrow={1}
            ml={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography variant="body1">{transfer.destination}</Typography>
            <Typography variant="body2">
              <Translation path="destination" />
            </Typography>
          </Box>
        </Box>
        <Box mb={2}>
          <Typography className={classes.subtitle} variant="subtitle1">
            <Translation path="observations" />:
          </Typography>
          <TextClipper
            content={getTranslation(transfer, 'observations')}
            maxLength={180}
          />
        </Box>
        <Box mb={2} display="flex" justifyContent="center">
          <AddToCalendar
            event={event()}
            listItems={[
              { outlook: 'Outlook' },
              { apple: 'Apple' },
              { google: 'Google' },
            ]}
            buttonLabel={translate({ t, path: 'add.to.calendar' })}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

TransferCard.propTypes = {
  transfer: PropTypes.object.isRequired,
};

export default withTranslation()(TransferCard);
