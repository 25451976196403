import React from 'react';

// Components...
import Card from '@App/components/Card';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: '28px',
  },
  rect: {
    marginRight: theme.spacing(1),
  },
  list: {
    width: '100%',
    marginTop: theme.spacing(-0.3),
  },
  skeleton: {
    height: theme.spacing(2),
  },
  content: {
    paddingBottom: '16px !important',
  },
}));

const RestaurantBookingDateCardSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Box display="flex">
          <Skeleton
            className={classes.rect}
            variant="rect"
            width="40%"
            height={60}
            animation="wave"
          />
          <Box className={classes.list}>
            {[...(Array(4) as any).keys()].map((key) => (
              <Skeleton
                className={classes.skeleton}
                key={key}
                width="100%"
                animation="wave"
              >
                <Typography>.</Typography>
              </Skeleton>
            ))}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default RestaurantBookingDateCardSkeleton;
