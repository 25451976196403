import { AxiosResponse } from 'axios';
import { isEmpty, isUndefined } from 'lodash';

import axios from '@App/plugins/axios';
import RestaurantBookingService from '@App/services/RestaurantBooking';
import RestaurantBooking from '@App/types/RestaurantBooking';
import User from '@App/types/User';

export default class RestaurantDishOrdersService {
  static find(booking: RestaurantBooking): Promise<AxiosResponse> {
    return axios.get(
      `local/restaurant-dish-orders?restaurantBookingId=${booking.id}&restaurantId=${booking.restaurantId}&restaurantTurnDistributionId=${booking.turnDistributionId}`
    );
  }

  static update(id: number, data: any): Promise<AxiosResponse> {
    return axios.put(`local/restaurant-dish-orders/${id}`, data);
  }

  static store(data: any): Promise<AxiosResponse> {
    return axios.post('local/restaurant-dish-orders', data);
  }

  static async findOrdersByDishType(
    user: User,
    bookingId: number
  ): Promise<any> {
    let resource: any = await RestaurantBookingService.find(user);
    const bookings = resource.data;

    const booking = bookings.filter(
      (booking: any) => booking.id == bookingId
    )[0];

    if (!isUndefined(booking)) {
      resource = await axios.get('local/restaurant-dish-types');
      const dishTypes = resource.data;

      resource = await RestaurantDishOrdersService.find(booking);
      const dishOrders = resource.data;

      resource = await axios.get(
        `local/restaurant-dish-distributions?_embed=restaurantMenu&restaurantTurnDistributionId=${booking.turnDistributionId}`
      );
      const dishDistributions = resource.data;

      resource = dishTypes.map((dishType: any) => {
        const dishes: Array<any> = [];
        let totalBookedQuantity = 0;

        dishDistributions.forEach((dishDistribution: any) => {
          if (dishDistribution.dishTypeId === dishType.id) {
            const filteredDishOrder: any = dishOrders.filter(
              (dishOrder: any) =>
                dishOrder.dishId ===
                  parseInt(dishDistribution.dish.id as any) &&
                dishOrder.turnDistributionId ===
                  parseInt(booking.turnDistributionId as any)
            )[0];

            const bookedQuantity = !isUndefined(
              filteredDishOrder?.quantityDishes
            )
              ? filteredDishOrder.quantityDishes
              : 0;

            totalBookedQuantity += bookedQuantity;
            dishes.push({
              ...dishDistribution.dish,
              available: dishDistribution.quantity - dishDistribution.ordered,
              menuIsActive: dishDistribution.menuIsActive,
              quota: dishDistribution.quantity,
              bookedQuantity,
              quantityDishes: !isUndefined(filteredDishOrder?.quantityDishes)
                ? filteredDishOrder.quantityDishes
                : 0,
              restaurantId: booking.restaurantId,
              hotelId: booking.additionalData.hotelId,
              turnDistributionId: booking.turnDistributionId,
              dishOrderId: !isUndefined(filteredDishOrder?.id)
                ? filteredDishOrder.id
                : null,
            });
          }
        });

        return {
          ...dishType,
          bookedHostQuantity: totalBookedQuantity,
          maxHostQuota: booking.bookedQuota,
          dishes,
        };
      });
    } else {
      throw 'booking.not.found';
    }

    return resource;
  }
}
