import React, { useEffect, useState } from 'react';

import RestaurantBookingService from '@App/services/RestaurantBooking';
import RestaurantBookingDateTypes from '@App/types/RestaurantBookingDate';

import RestaurantBookingHeader from './RestaurantBookingHeader';
import RestaurantBookingTurnModal from './RestaurantBookingTurnModal';
import RestaurantBookingDateList from './RestaurantBookingDateList';
import RestaurantBookingDateListSkeleton from './RestaurantBookingDateListSkeleton';
import Translation from '@App/components/Translation';
import useAuth from '@App/hooks/useAuth';

const RestaurantBookingDate: React.FC = () => {
  const { user } = useAuth();
  const [bookingDates, setBookingDates] = useState({
    data: [],
    loading: true,
    error: null,
  });

  const [turnsModal, setTurnsModal] = useState({
    bookingDate: {} as any,
    show: false,
  });

  const handleTurnsModal = async (
    bookingDate: RestaurantBookingDateTypes,
    show: boolean
  ) => {
    setTurnsModal({ bookingDate, show });
  };

  const fetchBookingDates = async () => {
    try {
      setBookingDates({ data: [], loading: true, error: null });
      const resource = RestaurantBookingService.wrapBookingDates(
        await RestaurantBookingService.findBookingDates(user)
      );
      setBookingDates({ ...bookingDates, data: resource, loading: false });
    } catch (error) {
      setBookingDates({ ...bookingDates, loading: false, error });
    }
  };

  const fetchData = async () => {
    await fetchBookingDates();
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  return (
    <>
      <RestaurantBookingHeader
        title={<Translation path="list.dates.select.date" />}
        subtitle={<Translation path="list.dates.description" />}
      />
      {bookingDates.loading ? (
        <RestaurantBookingDateListSkeleton />
      ) : (
        <RestaurantBookingDateList
          bookingDates={bookingDates.data}
          handleTurnsModal={handleTurnsModal}
        />
      )}
      <RestaurantBookingTurnModal
        bookingDate={turnsModal.bookingDate}
        show={turnsModal.show}
        handleTurnsModal={handleTurnsModal}
      />
    </>
  );
};

export default RestaurantBookingDate;
