import { useDispatch, useSelector } from 'react-redux';

import {
  showUserFormAction,
  updateSelectedTourAction
} from './actions';

const useTourBookings = () => {
  const dispatch = useDispatch();

  return {
    tourBookings: useSelector((state: any) => state.tourBookings),
    showUserForm: (state: boolean) => dispatch(showUserFormAction(state)),
    updateSelectedTour: (state: boolean) => dispatch(updateSelectedTourAction(state)),
  };
};

export default useTourBookings;
