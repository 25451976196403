import { useState } from 'react';

const useActivityBooking = () => {
  const [loading, setLoading] = useState(true);
  return {
    loading,
    setLoading,
  };
};
export default useActivityBooking;
