import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import App from '@App/components/App';
import Route from '@App/components/Route';
import Header from '@App/components/Header';
import Main from '@App/components/Main';
import HomeMenu, { TransfersAndToursMenu, factSheetMenu } from '@App/containers/Menus';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FactSheet from '@App/containers/FactSheet';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: '1 1 auto',
    background: `
      linear-gradient(0deg, rgba(23, 23, 23, 0.44),
      rgba(49, 49, 49, 0.69)),
      url('assets/images/main-screen-back.jpg')
    `,
    backgroundSize: 'cover',
    backgroundPosition: 'fixed',
  },
}));

const MenuLayout: React.FC = () => {
  const classes = useStyles();
  return (
    <App>
      <Header />
      <Main>
        <Box className={classes.container}>
          <Switch>
            <Route exact path="/" component={HomeMenu} />
            <Route
              exact
              path="/transport-and-tours"
              component={TransfersAndToursMenu}
              perform={['tours:view', 'transfers:view']}
            />
            <Route exact path="/fact-sheets" component={factSheetMenu} />
            <Route exact path="/fact-sheets/:country" component={FactSheet} />
            <Redirect to="/not-found" />
          </Switch>
        </Box>
      </Main>
    </App>
  );
};

export default MenuLayout;
