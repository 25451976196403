import { AxiosResponse } from 'axios';

import axios from '@App/plugins/axios';

import RestaurantWrap from './wraps/RestaurantWrap';

export default class RestaurantService extends RestaurantWrap {
  static find(data: any): Promise<AxiosResponse> {
    return axios.post('/restaurantes?idioma=ES', {
      fecha: data.additionalData.bookingDate,
      id_tipser: data.additionalData.turnTypeId,
      nroregistro: data.registryNumber,
    });
  }
}
