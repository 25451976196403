import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import Translation from '@App/components/Translation';
import yup from '@App/plugins/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import RestaurantBooking from '@App/types/RestaurantBooking';

const schema = yup.object().shape({
  bookedQuota: yup.string().required().label('occupancies'),
});

const useStyles = makeStyles((theme: Theme & any) => ({
  form: {
    marginTop: theme.spacing(2),
  },
  cancelButton: {
    backgroundColor: theme.palette.dark.main,
    '&:hover': {
      backgroundColor: theme.palette.dark.dark,
    },
  },
}));

interface RestaurantBookingCardFormProps {
  booking: RestaurantBooking;
  handleSubmit: Function;
  handleCancel: Function;
}

const RestaurantBookingCardForm: React.FC<RestaurantBookingCardFormProps> = ({
  booking,
  handleSubmit,
  handleCancel,
}) => {
  const classes = useStyles();
  const defaultValues = {
    bookedQuota: booking.bookedQuota,
  };

  const methods = useForm<any>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });
  const {
    control,
    handleSubmit: handleSubmitHook,
    errors,
    formState,
  } = methods;
  const onSubmit = (data: RestaurantBooking) => handleSubmit(data);

  return (
    <Box>
      <Typography variant="h6">
        <Translation path="modify.reservation" />
      </Typography>
      <Typography variant="subtitle1" component="span">
        <b>
          <Translation path="modify.res.p1" />
          &nbsp;
        </b>
      </Typography>
      <Typography variant="subtitle1" component="span">
        <Translation path="modify.res.p2" />
      </Typography>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmitHook(onSubmit)} className={classes.form}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl variant="outlined">
                <InputLabel id="booked-quote-select">
                  <Translation path="occuppancies.person" />
                </InputLabel>
                <Controller
                  name="bookedQuota"
                  error={Boolean(errors.bookedQuota)}
                  control={control}
                  labelId="booked-quote-select"
                  as={
                    <Select>
                      {[
                        ...Array(
                          parseInt(booking.additionalData.maxHostQuota as any)
                        ),
                      ].map((item, index) => (
                        <MenuItem key={index} value={index + 1}>
                          <em>{index + 1}</em>
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  label={<Translation path="occuppancies.person" />}
                  fullWidth
                />
                <FormHelperText>{errors.bookedQuota?.message}</FormHelperText>
              </FormControl>
              <Typography variant="caption">
                <Translation path="occuppancies.foot.note" />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleCancel();
                  }}
                  className={classes.cancelButton}
                >
                  <Translation path="cancel" />
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={!formState.isValid}
                >
                  <Translation path="update" />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
};
export default RestaurantBookingCardForm;
