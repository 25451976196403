import React from 'react';

// Components...
import Box from '@material-ui/core/Box';

// Styles...
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `0 ${theme.spacing(0.5)}px`,
    position: 'absolute',
    top: '-28px',
  },
  title: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
    margin: `0 ${theme.spacing(0.5)}px`,
    padding: `0 ${theme.spacing(1)}px`,
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    textAlign: 'center',
    minWidth: theme.spacing(12),
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const CardBadge = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography className={classes.title} variant="subtitle1">
        {children}
      </Typography>
    </Box>
  );
};

export default CardBadge;
