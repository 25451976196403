import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

const useTranslationHelper: Function = () => {
  const { i18n } = useTranslation();

  const getTranslation = (data: any, path: string) => {
    const translation = data?.translations?.[i18n.language]?.[path];

    if (!isEmpty(translation)) {
      return translation;
    } else {
      return data?.[path];
    }
  };

  return {
    getTranslation,
  };
};

export default useTranslationHelper;
