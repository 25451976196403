import React from 'react';
import PropTypes from 'prop-types';

// Styles...
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  application: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
});

const App = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.application}>{children}</div>;
};

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
