import Axios from 'axios';

// Utils
import { getCookieToJson, getCookie } from '@App/utils/cookie';
import { COOKIE_AUTH, COOKIE_LANG } from '@App/utils/constants';
import { removeCookie } from '@App/utils/cookie';
import { isUndefined } from 'lodash';

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axios.interceptors.request.use((config) => {
  const auth = getCookieToJson(COOKIE_AUTH);
  const token = `Bearer ${auth?.accessToken}`;
  const lang = !isUndefined(getCookie(COOKIE_LANG))
    ? getCookie(COOKIE_LANG)
    : 'es';
  config.headers.Authorization = token;
  config.headers['Hotel-Id'] = auth?.hotelId || null;
  config.baseURL = `${process.env.REACT_APP_API_BASE_URL}/${lang}/api`;
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 403) {
      if (error.response?.data?.message?.match(/hotel id/i)) {
        removeCookie(COOKIE_AUTH);
        // eslint-disable-next-line no-restricted-globals
        location.replace('/auth/login?redirect=restaurants/bookings');
      }
    }
    if (error.response.status === 401) {
      removeCookie(COOKIE_AUTH);
      // eslint-disable-next-line no-restricted-globals
      location.replace('/auth/login?redirect=restaurants/bookings');
    }
    return Promise.reject(error);
  }
);

export default axios;
