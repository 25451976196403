import React from 'react';

// Components...
import Box from '@material-ui/core/Box';

// Styles...
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `0 ${theme.spacing(0.5)}px`,
    position: 'absolute',
    top: '-28px',
  },
  title: {
    margin: `0 ${theme.spacing(0.5)}px`,
    padding: `0 ${theme.spacing(1)}px`,
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    textAlign: 'center',
    minWidth: theme.spacing(12),
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const CardBadge = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Skeleton className={classes.title} animation="wave" variant="rect">
        <Typography variant="subtitle1">.</Typography>
      </Skeleton>
    </Box>
  );
};

export default CardBadge;
