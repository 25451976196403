import React, { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import RestaurantBookingCard from './RestaurantBookingCard';
import useBookings from './useBookings';
import { isEmpty } from 'lodash';
import Alert from '@material-ui/lab/Alert';
import Translation from '@App/components/Translation';
import RestaurantBooking from '@App/types/RestaurantBooking';

const useStyles = makeStyles((theme) => ({
  bookingWrap: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
  },
  alert: {
    width: '100%',
  },
}));

const RestaurantBookingList: React.FC = () => {
  const classes = useStyles();
  const { bookings, updateStep } = useBookings();
  useEffect(() => {
    updateStep(1);
  }, []);

  return (
    <Grid className={classes.bookingWrap} container spacing={3}>
      {isEmpty(bookings.data) ? (
        <Alert className={classes.alert} severity="info">
          <Translation path="no.bookings.done" />
        </Alert>
      ) : (
        bookings.data.map((booking: RestaurantBooking) => (
          <Grid key={booking.id} item md={6} xs={12}>
            <RestaurantBookingCard booking={booking} />
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default RestaurantBookingList;
