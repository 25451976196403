import RestaurantTurn from '@App/types/RestaurantTurn';
import moment from 'moment';

export default class RestaurantTurnWrap {
  static wrapTurns({ data }: any): any {
    const wrappedData: RestaurantTurn[] = [];

    data.Datos.forEach((item: any) => {
      let isAllowedTime;
      const openingTime = moment(item.HORA_APERTURA_TIPSER, 'HH:mm:ss');
      const closeTime = moment(item.HORA_CIERRE_TIPSER, 'HH:mm:ss');
      const hotelTime = moment(item.HORA_HOTEL, 'HH:mm:ss');

      if (openingTime.isAfter(closeTime)) {
        isAllowedTime = hotelTime.isSameOrAfter(openingTime) || hotelTime.isSameOrBefore(closeTime);
      } else {
        isAllowedTime = hotelTime.isSameOrAfter(openingTime) && hotelTime.isSameOrBefore(closeTime);
      }

      const wrappedItem: RestaurantTurn = {
        typeId: item.ID_TIPSER,
        availableQuote: item.DISPONIBLES,
        maxHostQuota: item.CUPOSTOTAL,
        bookedHostQuota: item.CUPOS_RESE,
        availableHostQuota: item.CUPOS_DISP,
        turnType: {
          es: item?.TRADUCCION_TIPSER?.ES?.name,
          en: item?.TRADUCCION_TIPSER?.EN?.name,
          pt: item?.TRADUCCION_TIPSER?.PT?.name,
          fr: item?.TRADUCCION_TIPSER?.FR?.name,
          ht: item?.TRADUCCION_TIPSER?.HT?.name,
        },
        status: item.ESTADO === 'ACTIVE',
        hotelTime: item.HORA_HOTEL,
        openingTime: item.HORA_APERTURA_TIPSER,
        closeTime: item.HORA_CIERRE_TIPSER,
        isAllowedTime,
      };

      wrappedData.push(wrappedItem);
    });

    return wrappedData;
  }
}
