import React from 'react';

// Icons...
import HomeIcon from '@material-ui/icons/Home';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import LocalAirportIcon from '@material-ui/icons/LocalAirport';
import SportsCricketIcon from '@material-ui/icons/SportsCricket';

// Others..
import Translation from '@App/components/Translation';

const isExplorer = process.env.REACT_APP_EXPLORER || 'true';
const isRestaurants = process.env.REACT_APP_RESTAURANTS || 'true';
const isActivities = process.env.REACT_APP_ACTIVITIES || 'true';

export default () => {
  const collection = [
    {
      key: 'home',
      name: <Translation path="main.home" />,
      icon: <HomeIcon />,
      link: '/',
      target: '_self',
      child: [],
    },
  ];
  isRestaurants.toLowerCase() === 'true'
    && collection.push({
      key: 'restaurant-reservation',
      name: <Translation path="main.restaurant.reservation" />,
      icon: <RestaurantIcon />,
      link: '/restaurants/bookings',
      target: '_self',
      child: [],
    });

  isExplorer.toLowerCase() === 'true'
    && collection.push({
      key: 'transport-and-tours',
      name: <Translation path="main.transport.tours" />,
      icon: <LocalAirportIcon />,
      link: '/transport-and-tours',
      target: '_self',
      child: [],
    });

  isActivities.toLowerCase() === 'true'
    && collection.push({
      key: 'entertainment',
      name: <Translation path="entertainment" />,
      icon: <SportsCricketIcon />,
      link: '/activities/children',
      target: '_self',
      child: [],
    });
  return collection;
};
