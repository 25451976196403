import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  type: {
    fontWeight: 700,
    color: theme.palette.secondary.main,
  },
  title: {
    fontWeight: 400,
  },
  subtitle: {
    fontWeight: 300,
    textTransform: 'uppercase',
    color: 'red',
  },
}));

interface RestaurantTitleProps {
  type?: any;
  icon?: string;
  title: any;
  subtitle?: any;
}

const RestaurantTitle: React.FC<RestaurantTitleProps> = ({
  icon,
  type,
  title,
  subtitle
}) => {
  const classes = useStyles();

  return (
    <Box mb={2}>
      <Typography className={classes.type} variant="body1">
        <i className={icon || 'fas fa-utensils'} /> &nbsp;
        <b>{type}</b>
      </Typography>
      <Typography className={classes.title} variant="h5">
        {title}
      </Typography>
      <Typography className={classes.subtitle} variant="h6">
        {subtitle}
      </Typography>
    </Box>
  );
};

export default RestaurantTitle;
