import React from 'react';

import { makeStyles } from '@material-ui/core';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
  root: {
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 3,
  },
  circularProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ButtonProgress: React.FC<CircularProgressProps & any> = ({
  size,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <CircularProgress
        size={size || 25}
        className={classes.circularProgress}
        {...props}
      />
    </Box>
  );
};

export default ButtonProgress;
