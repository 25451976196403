import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components...
import Button from '@material-ui/core/Button';
import Container from '@App/components/Container';
import Typography from '@material-ui/core/Typography';
import CountryServices from '@App/services/Countries';

// Styles...
import { makeStyles } from '@material-ui/core';
import ReactCountryFlag from 'react-country-flag';

const useStyles = makeStyles((theme) => ({
  navigation: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: theme.spacing(38),
    height: theme.spacing(6.5),
    margin: theme.spacing(1.7),
    textTransform: 'capitalize',
  },
  transfersButtonText: {
    fontSize: 14,
  },
  icon: {
    marginRight: theme.spacing(1),
    minWidth: theme.spacing(7),
  },
  subIcon: {
    marginRight: theme.spacing(1.8),
  },
  flag: {
    borderRadius: '30%',
    width: '2em',
    height: '2em',
  },
}));

const FactSheetMenu = () => {
  const classes = useStyles();
  const history = useHistory();
  const [listCountries, setCountries] = useState([]);

  const handleClick = (path) => {
    history.push(path);
  };

  useEffect(() => {
    CountryServices.getAll().then((countries) => {
      const mapCountries = countries.map((country) => {
        if (country.countryCode === "ES") {
          country.countryCode = "SV";
        }
        return country;
      });
      setCountries(mapCountries);
    });
  });

  return (
    <Container maxWidth="md" allCentered>
      {listCountries.map((country) => (
        <Button
          className={classes.navigation}
          onClick={() => handleClick(`/fact-sheets/${country.id}`)}
          variant="contained"
          color="primary"
        >
          <ReactCountryFlag
            style={{ width: '3em', height: '3em', marginRight: '10px' }}
            countryCode={country.countryCode}
            title={country.name}
            svg
          />
          <Typography variant="subtitle1">{country.name}</Typography>
        </Button>
      ))}
    </Container>
  );
};

export default FactSheetMenu;
