import moment from 'moment';
import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  time: {
    fontWeight: 500,
    fontSize: '2.5rem',
  },
  meridian: {
    fontWeight: 500,
    fontSize: '.875rem',
  },
}));

interface TimeProps {
  time: string;
}

const Time: React.FC<TimeProps> = ({ time }) => {
  const classes = useStyles();
  const formattedTime = {
    time: moment.utc(time, 'hh:mm:ss').format('h:mm'),
    meridian: moment.utc(time, 'hh:mm:ss').format('A'),
  };

  return (
    <Box display="flex" alignItems="flex-end">
      <Typography className={classes.time} variant="h4">
        {formattedTime.time}
      </Typography>
      <Typography className={classes.meridian} variant="caption">
        {formattedTime.meridian}
      </Typography>
    </Box>
  );
};

export default Time;
