import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  skeleton: {
    height: theme.spacing(11),
    borderRadius: '.25rem',
  },
}));

const RestaurantBookingTurnModalSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      {[...(Array(3) as any).keys()].map((key) => (
        <Grid item key={key} xs={12}>
          <Skeleton
            className={classes.skeleton}
            key={key}
            width="100%"
            variant="rect"
            animation="wave"
          >
            <Button />
          </Skeleton>
        </Grid>
      ))}
    </Grid>
  );
};

export default RestaurantBookingTurnModalSkeleton;
