import React from 'react';

// Others..
import Translation from '@App/components/Translation';

export default () => [
  {
    flag: 'ES',
    languageIso6391Code: 'es',
    text: <Translation path="language.spanish" />,
  },
  {
    flag: 'US',
    languageIso6391Code: 'en',
    text: <Translation path="language.english" />,
  },
  {
    flag: 'PT',
    languageIso6391Code: 'pt',
    text: <Translation path="language.portuguese" />,
  },
  {
    flag: 'FR',
    languageIso6391Code: 'fr',
    text: <Translation path="language.french" />,
  },
  {
    flag: 'HT',
    languageIso6391Code: 'ht',
    text: <Translation path="language.haitianCreole" />,
  },
];
