import React from 'react';

// Components...
import Card from '@App/components/Card';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';

const ActivityDetailSkeleton = () => (
  <Card>
    <CardContent>
      <Grid container spacing={3}>
        <Grid xs={12} md={4} item>
          <Skeleton variant="rect" width="100%" height={200} animation="wave" />
        </Grid>
        <Grid xs={12} md={8} item>
          {[...Array(8).keys()].map((key) => (
            <Skeleton key={key} width="100%" animation="wave">
              <Typography>.</Typography>
            </Skeleton>
          ))}
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default ActivityDetailSkeleton;
