import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AuthProvider } from './context';
import useAuth from '@App/hooks/useAuth';

const Authentication = ({ children }) => {
  const [change, setChange] = useState(false);
  const { isAuthenticated, user } = useAuth();

  const refresh = () => {
    setChange(!change);
  };

  const authProviderValue = {
    isAuthenticated,
    user,
    refresh,
  };

  useEffect(() => {}, [change]);

  return <AuthProvider value={authProviderValue}>{children}</AuthProvider>;
};

Authentication.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Authentication;
