import React from 'react';
import PropTypes from 'prop-types';
import HTMLReactParser from 'html-react-parser';

const InnerHtml = ({ children }) => HTMLReactParser(children);

InnerHtml.propTypes = {
  children: PropTypes.string,
};

export default InnerHtml;
