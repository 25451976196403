import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrap: {
    marginBottom: theme.spacing(5),
  },
}));

const RestaurantBookingHeader: React.FC<any> = ({title, subtitle}) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrap}>
      <Typography variant="h4">
        {title}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default RestaurantBookingHeader;
