import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import HotelCard from './HotelCard';

const HotelList = ({ hotels, classes }) => (
  <Grid
    container
    spacing={3}
    direction="row"
    justify="flex-start"
    alignItems="flex-start"
    className={classes}
  >
    {hotels.map((hotel) => (
      <Grid item xs={12} sm={6} md={6} key={hotels.indexOf(hotel)}>
        <HotelCard hotel={hotel} />
      </Grid>
    ))}
  </Grid>
);

HotelList.propTypes = {
  hotels: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default HotelList;
