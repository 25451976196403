import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import App from '@App/components/App';

import Header from '@App/components/Header';
import Main from '@App/components/Main';
import PrivateRoute from '@App/components/PrivateRoute';
import Translation from '@App/components/Translation';
import RestaurantBooking from '@App/containers/Restaurants/Bookings';
import RestaurantBookingSteps from '@App/containers/Restaurants/Bookings/RestaurantBookingSteps';
import RestaurantBookingSuccess from '@App/containers/Restaurants/Bookings/RestaurantBookingSuccess';
import RestaurantDishOrders from '@App/containers/Restaurants/DishOrders/RestaurantDishOrders';
import { makeStyles } from '@material-ui/core';
import RestaurantFooter from '@App/containers/Restaurants/RestaurantFooter';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacing(2)}px 0`,
  },
}));

const RestaurantLayout: React.FC = () => {
  const classes = useStyles();

  return (
    <App>
      <Header title={<Translation path="reservations.title" />} />
      <Main className={classes.root}>
        <Switch>
          <PrivateRoute
            exact
            path="/restaurants/bookings"
            component={RestaurantBooking}
            perform="restaurants:view"
          />
          <PrivateRoute
            exact
            path="/restaurants/bookings/success"
            component={RestaurantBookingSuccess}
            perform="restaurants:view"
          />
          <PrivateRoute
            exact
            path="/restaurants/bookings/:bookingId/dish-orders"
            component={RestaurantDishOrders}
            perform="restaurants:view"
          />
          <PrivateRoute
            path="/restaurants/bookings/steps/:stepId([1-3])"
            component={RestaurantBookingSteps}
            perform="restaurants:view"
          />
          <Redirect to="/not-found" />
        </Switch>
      </Main>
      <RestaurantFooter />
    </App>
  );
};

export default RestaurantLayout;
