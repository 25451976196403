import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    background: 'inherit',
  },

  buttonWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    zIndex: '2',
    background: 'inherit',
  },

  button: {
    position: 'relative',
    top: '-22px',
    border: 'none',
    textDecoration: 'none',
    cursor: 'pointer',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    color: 'white',
    fontSize: '20px',
    fontWeight: 'bold',
    flexShrink: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    margin: '-7px',
  },

  dateListScrollable: {
    display: 'flex',
    overflowX: 'scroll',
    scrollbarWidth: 'none',
    margin: '0',
    WebkitOverflowScrolling: 'touch',
    '&::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      display: 'none',
    },
  },

  monthContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  monthYearLabel: {
    alignSelf: 'flex-start',
    zIndex: '3',
    fontSize: '15px',
    fontWeight: 'bold',
    position: 'sticky',
    top: '0',
    left: '0',
    width: 'max-content',
    margin: '0 14px 10px 0',
  },

  dateDayItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '0 0 0 5px',
    width: '45px',
    height: '49px',
    flexShrink: '0',
  },

  daysContainer: {
    display: 'flex',
    zIndex: '1',
    backgroundColor: '#fff',
    margin: '0 !important',
  },

  dayLabel: {
    fontSize: '12px',
    margin: '4px 0 0 0',
  },

  dateLabel: {
    fontSize: '18px',
  },
}));

export default useStyles;
