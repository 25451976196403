import React from 'react';

import Grid from '@material-ui/core/Grid';
import RestaurantCard from './RestaurantCard';
import useAuth from '@App/hooks/useAuth';

interface RestaurantListProps {
  restaurants: any;
}

const RestaurantList: React.FC<RestaurantListProps> = ({ restaurants }) => {
  const { user } = useAuth();

  const canDisplayRestaurant = (restaurant) => {
    return !restaurant.isPlusService || (user.isPlusService === restaurant.isPlusService);
  };

  return (
    <Grid container spacing={3}>
      {restaurants.map(
        (restaurant: any, index: number) =>
          canDisplayRestaurant(restaurant)
          && (
            <Grid key={index} item xs={12} md={6}>
              <RestaurantCard restaurant={restaurant} />
            </Grid>
          )
      )}
    </Grid>
  );
};

export default RestaurantList;
