import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import HotelCard from '@App/containers/FactSheet/HotelCard';

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const FactSheetFilter = ({ handleChange, zones }) => {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="demo-simple-select-outlined-label">Seleccione un departamento</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        onChange={({ target }) => handleChange(target.value)}
        label="Seleccione un departamento"
      >
        <MenuItem value="">
          <em>Todos</em>
        </MenuItem>
        {zones.map((zone, index) => (
          <MenuItem key={index} value={zone.zoneName}>
            {zone.zoneName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

FactSheetFilter.propTypes = {
  handleChange: PropTypes.func.isRequired,
  zones: PropTypes.array.isRequired,
};
export default FactSheetFilter;
