import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Translation from '@App/components/Translation';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

interface RestaurantBookingTurnModalItemProps {
  turn: any;
  onClick: Function;
}

const useStyles = makeStyles(() => ({
  button: {
    width: '100%',
    '& .MuiButton-label': {
      display: 'block',
    },
    textTransform: 'initial',
  },
}));

const RestaurantBookingTurnModalItem: React.FC<RestaurantBookingTurnModalItemProps> = ({
  turn,
  onClick,
}: any) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const { language } = i18n;
  return (
    <Button
      className={classes.button}
      variant="contained"
      color="secondary"
      disabled={!turn.isAllowedTime}
      onClick={() => onClick(turn)}
    >
      <Typography component="span" variant="subtitle1">
        {turn.turnType?.[`${language}`]}{' '}
        {!turn.status && (
          <span>
            <Translation path="closed" />
          </span>
        )}
      </Typography>
      <br />
      <Typography component="span" variant="subtitle2">
        <Translation path="available.occuppancies" />
        &nbsp;
        {turn.availableHostQuota}
        &nbsp;
        <Translation path="of" />
        &nbsp;
        {turn.maxHostQuota}
      </Typography>
      <br />
      <Typography component="span" variant="subtitle2">
        <Translation path="max.res.time" />
        &nbsp;
        {moment.utc(turn.closeTime, 'hh:mm').format('h:mm A')}
      </Typography>
    </Button>
  );
};

export default RestaurantBookingTurnModalItem;
