import React from 'react';

import DetailWithIcons from '@App/components/DetailWithIcons/DetailWithIcons';
import Translation from '@App/components/Translation';
import RestaurantBooking from '@App/types/RestaurantBooking';

interface RestaurantBookingCardDetailProps {
  booking: RestaurantBooking;
}

const RestaurantBookingCardDetail: React.FC<RestaurantBookingCardDetailProps> = ({
  booking,
}) => (
  <DetailWithIcons
    data={[
      {
        label: <Translation path="occuppancy.persons" />,
        icon: 'fas fa-users',
        content: booking.bookedQuota,
      },
      {
        label: <Translation path="hotel" />,
        icon: 'fas fa-hotel',
        content: booking.additionalData.hotelName,
      },
      {
        label: <Translation path="reservation.number" />,
        icon: 'fas fa-ticket-alt',
        content: booking.id,
      },
      {
        label: <Translation path="notes" />,
        icon: 'fas fa-star',
        content: booking.notes,
      },
      {
        label: <Translation path="conditions" />,
        icon: 'fas fa-exclamation-triangle',
        content: booking.additionalData.conditions,
      },
    ]}
  />
);
export default RestaurantBookingCardDetail;
