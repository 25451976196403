import React from 'react';
import MatSkeleton from '@material-ui/lab/Skeleton';

const TourBookingSkeleton = () => (
  <>
    <MatSkeleton variant="text" />
    <MatSkeleton variant="rect" height={300} />
  </>
);
export default TourBookingSkeleton;
