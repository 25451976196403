import { AuthContext } from '@App/providers/Authentication/context';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import className from 'classnames';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    bottom: `${theme.spacing(2)}px`,
    right: `${theme.spacing(2)}px`,
  },
}));

const TourBookingButton = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t: translate } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);

  const handleBookingsClick = () => {
    history.push('/tours/bookings');
  };

  return (
    isAuthenticated && (
      <div className={className(classes.container, 'tour-button')}>
        <Fab
          color="primary"
          variant="extended"
          aria-label="add"
          onClick={handleBookingsClick}
        >
          <EventAvailableIcon />
          {translate('reservations.my.reservations')}
        </Fab>
      </div>
    )
  );
};
export default TourBookingButton;
