import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FullCalendar from '@fullcalendar/react';
import InteractionPlugin from '@fullcalendar/interaction';
import TimeGridPlugin from '@fullcalendar/timegrid';
import DayGridPlugin from '@fullcalendar/daygrid';
import ListPlugin from '@fullcalendar/list';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@App/components/Container';
import Card from '@App/components/Card';
import MuiCardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ActivityBookingDialog from './ActivityBookingDialog';
import ActivityBookingSkeleton from './ActivityBookingSkeleton';
import classNames from 'classnames';
import Axios from '@App/plugins/axios';
import moment from '@App/plugins/moment';
import { translate } from '@App/components/Translation';
import { isUndefined, isEmpty } from 'lodash';
import useMaterialConfirm from '@App/hooks/useMaterialConfirm';
import useMaterialSnackbar from '@App/hooks/useMaterialSnackbar';
import useActivityBooking from './useActivityBooking';
import useStyles from './style';
import useAuth from '@App/hooks/useAuth';

const ActivityBooking = ({ t }) => {
  const classes = useStyles();
  const history = useHistory();
  const confirm = useMaterialConfirm();
  const snackbar = useMaterialSnackbar();
  const { i18n } = useTranslation();
  const { activityId } = useParams();

  const { user } = useAuth();
  const { loading, setLoading } = useActivityBooking();
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [events, setEvents] = useState([]);
  const [activity, setActivity] = useState({
    data: null,
    loading: false,
    error: null,
  });
  const [activityBooking, setActivityBooking] = useState({
    data: null,
    loading: false,
    error: null,
  });
  const [event, setEvent] = useState({
    id: null,
    title: null,
    date: null,
    start: null,
    end: null,
    day: null,
    bookedQuantity: null,
    totalHosts: null,
    activity: {},
    activityTurnDistributionId: null,
  });

  const hasActivity = () => !isUndefined(activityId);

  const checkBookingAllowed = async (formData) => {
    const { data: distribution } = await Axios.get(
      `local/activity-turn-distributions/${formData.activityTurnDistributionId}?_embed=activityTurn`
    );
    const timeStart = moment(
      `${distribution.date} ${distribution.activityTurn.timeStart}`
    ).format('YYYY-MM-DD HH:mm');
    const timeEnd = moment(
      `${distribution.date} ${distribution.activityTurn.timeEnd}`
    ).format('YYYY-MM-DD HH:mm');

    const eventList = events.filter((evt) =>
      moment
        .range(evt.start, evt.end)
        .overlaps(moment.range(timeStart, timeEnd))
    );
    const availableQuotas =
      distribution.quota - distribution.used - distribution.bloqued;

    const hasEvent =
      eventList.length > 0 &&
      formData.activityTurnDistributionId !== event.activityTurnDistributionId;

    if (hasEvent) {
      throw translate({ t, path: 'activity.booking.booking.this.date' });
    } else if (formData.bookedQuantity > user.totalHosts) {
      throw translate({ t, path: 'activity.booking.participants.not.allowed' });
    } else if (formData.bookedQuantity > availableQuotas) {
      throw translate({ t, path: 'activity.booking.quotas.exceeded' });
    }

    return true;
  };

  const buildEvents = (bookings) => {
    return bookings.map((booking) => {
      const { activityTurn } = booking.activityTurnDistribution;
      const { activityTurnDistribution } = booking;
      const startDate = moment(
        `${activityTurnDistribution.date} ${activityTurn.timeStart}`
      ).format('YYYY-MM-DD HH:mm');
      const endDate = moment(
        `${activityTurnDistribution.date} ${activityTurn.timeEnd}`
      ).format('YYYY-MM-DD HH:mm');
      return {
        id: booking.id,
        title: activityTurn.activity.name,
        start: startDate,
        end: endDate,
        day: activityTurnDistribution.date,
        bookedQuantity: booking.bookedQuantity,
        totalHosts: user.totalHosts,
        activity: activityTurn.activity,
        activityTurnDistributionId: booking.activityTurnDistributionId,
      };
    });
  };

  const fetchActivityWithDistributions = async () => {
    try {
      setActivity({ ...activity, loading: true, error: null });
      const resource = await Axios.get(
        `local/activities/${activityId}?_embed=activityTurnDistributions,activityTurn`
      );
      if (!resource.data.hasTurns) {
        throw new Error('No shifts available for the activity');
      }
      setActivity({ data: resource.data, loading: false, error: null });
    } catch (error) {
      snackbar({ variant: 'error', message: error.message });
      setActivity({ data: null, loading: true, error });
    }
  };

  const fetchActivityBooking = async () => {
    try {
      setActivityBooking({ ...activityBooking, loading: true, error: null });
      const resource = await Axios.get(
        `local/activity-bookings?registryNumber=${user.registryNumber}&_embed=activityTurnDistribution,activityTurn,activity`
      );
      setActivityBooking({ data: resource.data, loading: false, error: null });
      setEvents(buildEvents(resource.data));
    } catch (error) {
      setActivityBooking({ data: null, loading: true, error });
    }
  };

  const fetchData = async () => {
    hasActivity() && (await fetchActivityWithDistributions());
    await fetchActivityBooking();
    setLoading(false);
  };

  const handleCloseClick = () => {
    setOpenDialog(false);
  };

  const updateActivityBooking = async (formData) => {
    const indexEvent = events.findIndex((item) => item.id === event.id);
    const [distribution] = activity.data.distributions.filter(
      (distribution) => distribution.id === formData.activityTurnDistributionId
    );
    const startDate = moment(
      `${distribution.date} ${distribution.activityTurn.timeStart}`
    ).format('YYYY-MM-DD HH:mm');
    const endDate = moment(
      `${distribution.date} ${distribution.activityTurn.timeEnd}`
    ).format('YYYY-MM-DD HH:mm');

    try {
      await checkBookingAllowed(formData);
      await Axios.put(`local/activity-bookings/${event.id}`, {
        holder: user.fullName,
        roomCode: user.roomCode,
        registryNumber: user.registryNumber,
        bookedQuantity: formData.bookedQuantity,
        activityTurnDistributionId: formData.activityTurnDistributionId,
      });
      setEvents(
        Object.assign([...events], {
          [indexEvent]: {
            ...event,
            date: distribution.date,
            start: startDate,
            end: endDate,
            bookedQuantity: formData.bookedQuantity,
            activityTurnDistributionId: formData.activityTurnDistributionId,
          },
        })
      );
      snackbar({
        message: translate({ t, path: 'update.reservation.confirmation' }),
      });
    } catch (error) {
      snackbar({ variant: 'error', message: error });
    }
  };

  const createActivityBooking = async (formData) => {
    const [distribution] = activity.data.distributions.filter(
      (distribution) => distribution.id === formData.activityTurnDistributionId
    );
    const startDate = `${distribution.date} ${distribution.activityTurn.timeStart}`;
    const endDate = `${distribution.date} ${distribution.activityTurn.timeEnd}`;
    try {
      await checkBookingAllowed(formData);
      const resource = await Axios.post('local/activity-bookings', {
        holder: user.fullName,
        roomCode: user.roomCode,
        registryNumber: user.registryNumber,
        bookedQuantity: formData.bookedQuantity,
        activityTurnDistributionId: formData.activityTurnDistributionId,
      });
      setEvents([
        ...events,
        {
          ...event,
          id: resource.data.id,
          start: startDate,
          end: endDate,
          bookedQuantity: formData.bookedQuantity,
          activityTurnDistributionId: formData.activityTurnDistributionId,
        },
      ]);
      snackbar({
        message: translate({
          t,
          path: 'create.reservation.confirmation.title',
          valuesToBind: { bookingNumber: resource.data.id },
        }),
      });
    } catch (error) {
      snackbar({ variant: 'error', message: error });
    }
  };

  const handleSaveClick = async (formData) => {
    setLoadingDialog(true);
    if (event.id) {
      await updateActivityBooking(formData);
    } else {
      await createActivityBooking(formData);
    }
    setLoadingDialog(false);
    setOpenDialog(false);
  };

  const handleRemoveClick = async () => {
    setLoadingDialog(true);

    try {
      await confirm({ dialogProps: { maxWidth: 'xs' } });
      await Axios.delete(`local/activity-bookings/${event.id}`);
      setEvents(events.filter((i) => i.id !== event.id));
      setOpenDialog(false);
      snackbar({
        message: translate({ t, path: 'delete.reservation.confirmation' }),
      });
    } catch (error) {
      error && snackbar({ variant: 'error', message: error });
    }

    setLoadingDialog(false);
  };

  const handleEventClick = (info) => {
    const date = moment(info.event.extendedProps.day).format('YYYY-MM-DD');
    const currentDate = moment().format('YYYY-MM-DD');
    if (
      parseInt(activityId, 10) === info.event.extendedProps.activity.id &&
      date >= currentDate
    ) {
      setEvent({
        id: parseInt(info.event.id, 10),
        title: info.event.title,
        start: moment(info.event.startStr).format('YYYY-MM-DD HH:mm'),
        end: moment(info.event.endStr).format('YYYY-MM-DD HH:mm'),
        day: info.event.extendedProps.day,
        bookedQuantity: info.event.extendedProps.bookedQuantity,
        totalHosts: user.totalHosts,
        activity: info.event.extendedProps.activity,
        activityTurnDistributionId:
          info.event.extendedProps.activityTurnDistributionId,
      });
      setOpenDialog(true);
    }
  };

  const handleDayClick = (info) => {
    const date = moment(info.dateStr).format('YYYY-MM-DD');
    const currentDate = moment().format('YYYY-MM-DD');

    if (hasActivity() && date >= currentDate) {
      const distribution = activity.data.distributions.find(
        (distribution) => distribution.date === info.dateStr
      );
      const reservedActivities = events.filter(
        (evt) => evt.day === date && activity.data.id === evt.activity.id
      );
      const hasBookingLimit =
        (activity.data.hasBookingLimit &&
          reservedActivities.length < activity.data.bookingLimitQuantity) ||
        !activity.data.hasBookingLimit;

      if (distribution && hasBookingLimit) {
        setEvent({
          id: null,
          title: activity.data.name,
          start: null,
          end: null,
          day: distribution.date,
          bookedQuantity: '',
          totalHosts: user.totalHosts,
          activity: activity.data,
          activityTurnDistributionId: '',
        });
        setOpenDialog(true);
      }

      if (!hasBookingLimit) {
        snackbar({
          variant: 'info',
          message: translate({
            t,
            path: 'activity.booking.limit.of.booking',
            valuesToBind: {
              quantity: activity.data.bookingLimitQuantity,
            },
          }),
        });
      }
    }
  };

  const renderEventContent = (info) => {
    const startDateTime = moment(info.event.startStr).format('H:mm A');
    const endDateTime = moment(info.event.endStr).format('H:mm A');
    const activityTranslations =
      info.event.extendedProps.activity?.translations;

    return (
      <div
        className={classNames(
          classes.event,
          {
            [`${classes.eventPrimary}`]:
              info.event.extendedProps.activity.id === parseInt(activityId, 10),
          },
          {
            [`${classes.eventSecondary}`]:
              info.event.extendedProps.activity.id !== parseInt(activityId, 10),
          }
        )}
        onClick={() => handleEventClick(info)}
      >
        <div className={classes.eventBody}>
          <div className={classes.eventTitleWrapper}>
            <span className={classes.eventTitle}>
              {activityTranslations?.[`${i18n.language}`]?.name ||
                info.event.title}
            </span>
            <span className={classes.eventTitle}>
              {info.event.extendedProps.bookedQuantity}
            </span>
          </div>
          <span className={classes.eventTime}>
            {startDateTime} {translate({ t, path: 'to' })} {endDateTime}
          </span>
        </div>
      </div>
    );
  };

  const renderCellDidMount = (arg) => {
    const date = moment(arg.date).format('YYYY-MM-DD');
    const currentDate = moment().format('YYYY-MM-DD');

    if (hasActivity() && date >= currentDate) {
      const dayFound = activity.data.distributions.filter(
        (distribution) => distribution.date === date
      );
      !isEmpty(dayFound) && arg.el.classList.add('fc-cell-active');
    }
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  const getHeaderToolbar = () => {
    const right = activityId ? 'dayGridMonth,listMonth' : 'listMonth';
    return {
      left: 'prev,next',
      center: 'title',
      right,
    };
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.descBlock}>
        <IconButton
          className={classes.descBlockButton}
          aria-label="back"
          onClick={() => history.goBack()}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <Typography
          className={classes.descBlockTitle}
          variant="h6"
          gutterBottom
        >
          {activity?.data?.translations?.[i18n.language]?.name ||
            activity.data?.name}
        </Typography>
        <Box />
      </Box>
      <Container className={classes.container} maxWidth="md">
        <Card>
          <MuiCardContent className={classes.fullCalendar}>
            {loading || activity.loading ? (
              <ActivityBookingSkeleton />
            ) : (
              <>
                <FullCalendar
                  plugins={[
                    TimeGridPlugin,
                    DayGridPlugin,
                    ListPlugin,
                    InteractionPlugin,
                  ]}
                  height="auto"
                  headerToolbar={getHeaderToolbar()}
                  titleFormat={{ year: 'numeric', month: 'long' }}
                  buttonText={{
                    today: translate({ t, path: 'day' }),
                    month: translate({ t, path: 'booking' }),
                    week: translate({ t, path: 'week' }),
                    day: translate({ t, path: 'day' }),
                    list: translate({
                      t,
                      path: 'reservations.my.reservations',
                    }),
                  }}
                  validRange={{
                    start: user.bookingArrivalDate,
                    end: moment
                      .utc(user.bookingDepartureDate)
                      .add(1, 'day')
                      .format('YYYY-MM-DD HH:mm:ss'),
                  }}
                  initialView="listMonth"
                  events={events}
                  locale={i18n.language}
                  dateClick={handleDayClick}
                  eventContent={renderEventContent}
                  dayCellDidMount={renderCellDidMount}
                  dayMaxEventRows={2}
                />
                <ActivityBookingDialog
                  activity={activity}
                  event={event}
                  open={openDialog}
                  onClose={handleCloseClick}
                  onSave={handleSaveClick}
                  onRemove={handleRemoveClick}
                  loading={loadingDialog}
                />
              </>
            )}
          </MuiCardContent>
        </Card>
      </Container>
    </Box>
  );
};

ActivityBookingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default ActivityBooking;
