import React from 'react';
import PropTypes from 'prop-types';

// Components...
import Can from '@App/components/Can';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Translation from '@App/components/Translation';
import ActivityCard from './ActivityCard';
import ActivityButton from './ActivityButton';

const ActivityListCard = ({ activities }) => {
  const hasActivities = () => activities.length > 0;

  return hasActivities() ? (
    <>
      <Grid container spacing={3}>
        {activities.map((activity) => (
          <Grid key={activity.id} xs={12} item>
            <ActivityCard activity={activity} />
          </Grid>
        ))}
      </Grid>
      <Can perform="activity-bookings:view" yes={() => <ActivityButton />} />
    </>
  ) : (
    <Alert severity="info">
      <Translation path="activities.available" />
    </Alert>
  );
};

ActivityListCard.propTypes = {
  activities: PropTypes.array.isRequired,
};

export default ActivityListCard;
