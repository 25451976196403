import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import App from '@App/components/App';
import Header from '@App/components/Header';
import Main from '@App/components/Main';
import Route from '@App/components/Route';
import PrivateRoute from '@App/components/PrivateRoute';
import Translation from '@App/components/Translation';
import Activities from '@App/containers/Activities';
import ActivityDetail from '@App/containers/Activities/ActivityDetail';
import ActivityBookings from '@App/containers/Activities/Bookings';

const ActivityLayout = () => (
  <App>
    <Header title={<Translation path="activities" />} />
    <Main>
      <Switch>
        <PrivateRoute
          exact
          path="/activities/:activityId?/children"
          component={Activities}
          perform="activities:view"
        />
        <PrivateRoute
          exact
          path="/activities/bookings"
          component={ActivityBookings}
          perform="activity-bookings:view"
        />
        <PrivateRoute
          exact
          path="/activities/:activityId"
          component={ActivityDetail}
          perform="activities:view"
        />
        <PrivateRoute
          exact
          path="/activities/:activityId/bookings"
          component={ActivityBookings}
          perform="activity-bookings:view"
        />
        <Redirect to="/not-found" />
      </Switch>
    </Main>
  </App>
);
export default ActivityLayout;
