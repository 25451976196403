import React, { useContext, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@App/components/Container';
import Divider from '@material-ui/core/Divider';
import LoginForm from './LoginForm';
import Translation from '@App/components/Translation';
import Typography from '@material-ui/core/Typography';
import useQueryParams from '@App/hooks/useQueryParams';
import { AuthContext } from '@App/providers/Authentication/context';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  card: {
    maxWidth: 480,
    width: '100%',
  },
  divider: {
    marginTop: '10px',
    marginBottom: '30px',
  },
}));

const Login: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const query = useQueryParams();
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    if (isAuthenticated) {
      query.get('redirect')
        ? history.push(`/${query.get('redirect')}`)
        : history.push('/');
    }
  }, [isAuthenticated]);

  return (
    <Container maxWidth="md" allCentered>
      <Box mb={2}>
        <Typography variant="h3">
          <Translation path="your.virtual.hotel" />
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          <Translation path="login.welcome" />
        </Typography>
      </Box>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6">
            <Translation path="login.title" />
          </Typography>
          <Divider className={classes.divider} />
          <LoginForm />
          <Divider className={classes.divider} />
          <Typography
            align="center"
            variant="caption"
            component="div"
            gutterBottom
          >
            <Translation path="login.18year" />
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            <Translation path="login.problems" />
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            <Translation path="login.problems.p1" />{' '}
            <Translation path="login.problems.p2" />
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Login;
