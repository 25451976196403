import React from 'react';

// Components...
import Card from '@App/components/Card';
import CardTopButtonSkeleton from '@App/components/Card/CardTopButtonSkeleton';
import CardBadgeSkeleton from '@App/components/Card/CardBadgeSkeleton';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: '28px',
  },
  list: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  text: {
    height: theme.spacing(3),
  },
  button: {
    height: theme.spacing(4),
    borderRadius: '.25rem',
  },
  content: {
    paddingBottom: '16px !important',
  },
}));

const RestaurantBookingDatesSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <Card
      className={classes.card}
      badge={<CardBadgeSkeleton />}
      topButton={<CardTopButtonSkeleton />}
    >
      <CardContent className={classes.content}>
        <Box
          className={classes.header}
          display="flex"
          justifyContent="space-between"
        >
          {[...(Array(2) as any).keys()].map((key) => (
            <Skeleton
              className={classes.button}
              key={key}
              width="20%"
              animation="wave"
              variant="rect"
            />
          ))}
        </Box>
        <Box className={classes.list}>
          {[...(Array(6) as any).keys()].map((key) => (
            <Skeleton
              className={classes.text}
              key={key}
              width="100%"
              animation="wave"
            >
              <Typography>.</Typography>
            </Skeleton>
          ))}
        </Box>
        <Box display="flex" justifyContent="flex-start">
          {[...(Array(1) as any).keys()].map((key) => (
            <Skeleton
              className={classes.button}
              key={key}
              width="20%"
              animation="wave"
              variant="rect"
            />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default RestaurantBookingDatesSkeleton;
