import { AxiosResponse } from 'axios';

import axios from '@App/plugins/axios';

export const wrapUser = (data: any) => ({
  fullName: data.NOMBRE
    ? data.NOMBRE.toLowerCase().replace(/\b[a-z]/g, (c: string) =>
        c.toUpperCase()
      )
    : null,
  identityDocumentCountryId: 'CO',
  identityDocumentType: data.TIPO_ID_HW || null,
  identityDocumentCode: data.IDENTIFICACION || null,
  email: data.EMAIL || null,
  roomCode: data.NUMERO_HABI || data.roomCode || null,
  braceletCode: data.BRAZALETE || data.braceletNumber || null,
  serviceTypeId: data.TIPO_PLUS || null,
  registryNumber: data.NUMERO_REGI || data.registryNumber || null,
  adultsNumber: data.NUMERO_ADU_REGI || null,
  childrenNumber: data.NUMERO_NIN_REGI || null,
  braceletNumber: data.BRAZALETE || null,
  bookingArrivalDate: data.FECHA_LLEGA_REGI || null,
  bookingDepartureDate: data.FECHA_SALE_REGI || null,
  quantityBookedRooms: data.TOTAL_HAB || null,
  hotelId: data.hotelId || null,
  totalGuests:
    data.NUMERO_HABI || data.NUMERO_NIN_REGI
      ? parseInt(data.NUMERO_ADU_REGI, 10) + parseInt(data.NUMERO_NIN_REGI, 10)
      : null,
});

export const unWrapUser = (data: any) => ({
  NOMBRE: data.fullName,
  NUMERO_HABI: data.roomCode,
  TIPO_PLUS: data.serviceTypeId,
  NUMERO_REGI: data.registryNumber,
  NUMERO_ADU_REGI: data.adultsNumber,
  NUMERO_NIN_REGI: data.childrenNumber,
  BRAZALETE: data.braceletNumber,
  FECHA_LLEGA_REGI: data.bookingArrivalDate,
  FECHA_SALE_REGI: data.bookingDepartureDate,
  TOTAL_HAB: data.quantityBookedRooms,
});

export const wrapAssociatedUsers = ({ data }: any): any => {
  const wrappedData: any = [];

  data.Datos.forEach((item: any, index: number) => {
    const wrappedItem: any = {
      id: index,
      fullName: item.TITULAR,
      roomCode: item.NUMERO_HABI,
      registryNumber: item.NUMERO_REGI,
      restaurantQuota: item.CUPOS,
      bookedRestaurantQuota: item.CUPOSRESERVADOS,
      maxRestaurantQuota: item.MAX_CUPOS,
    };

    wrappedData.push(wrappedItem);
  });

  return wrappedData;
};

export default class UserService {
  static findAssociatedUsers(data: any): Promise<AxiosResponse> {
    return axios.post('/searchHabitacion', {
      fecha: data.bookingDate,
      id_tipser: data.turnTypeId,
      nroregistro: data.registryNumber,
    });
  }
}
