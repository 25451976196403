import React from 'react';
import PropTypes from 'prop-types';

const HotelPdf = ({ url, preview = false }) => null;

HotelPdf.propTypes = {
  url: PropTypes.string.isRequired,
  preview: PropTypes.bool.isRequired,
};

export default HotelPdf;
