import React from 'react';

import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    right: '-20px',
    top: '-20px',
    padding: '5px',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

interface CardTopButtonProps extends React.HTMLAttributes<HTMLDivElement> {}

const CardTopButton: React.FC<CardTopButtonProps & IconButtonProps> = ({
  children,
  ...props
}) => {
  const classes = useStyles();
  return (
    <IconButton
      className={classes.root}
      color="primary"
      aria-label="add to shopping cart"
      {...props}
    >
      {children}
    </IconButton>
  );
};

export default CardTopButton;
