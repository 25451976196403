import { isEmpty } from 'lodash';
import React from 'react';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";

type DetailWithIcon = {
  label: any;
  icon: string;
  content: any;
  optional?: boolean;
};

interface DetailWithIconsProps {
  data: DetailWithIcon[];
  labelMinWidth?: string | number;
  labelMinWidthResponsive?: string | number;
}

const DetailWithIcons: React.FC<DetailWithIconsProps> = ({
  data,
  labelMinWidth,
  labelMinWidthResponsive,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'grid',
      gridTemplateColumns: labelMinWidth
        ? `${labelMinWidth} 27px auto`
        : `120px 27px auto`,
      ['@media (max-width:480px)']: {
        gridTemplateColumns: labelMinWidth
          ? `${labelMinWidthResponsive} 23px auto`
          : `85px 23px auto`,
      },
    },
    label: {
      textAlign: 'right',
      fontWeight: 700,
      color: theme.palette.secondary.main,
      ['@media (max-width:480px)']: {
        fontSize: 12,
      },
    },
    icon: {
      fontWeight: 700,
      marginTop: 5,
      textAlign: 'right',
      color: theme.palette.secondary.main,
      ['@media (max-width:480px)']: {
        fontSize: 12,
      },
    },
    content: {
      marginLeft: 10,
      ['@media (max-width:480px)']: {
        fontSize: 12,
      },
    },
  }));

  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      {data.map((item, index) =>
        item.optional && isEmpty(item.content) ? (
          <></>
        ) : (
          <Grid key={index} item xs={12}>
            <Box mr={1} className={classes.root}>
              <Typography className={classes.label} component="span">
                {item.label}
              </Typography>
              <i className={`${item.icon} ${classes.icon}`} />
              <Typography className={classes.content} component="span">
                {item.content}
              </Typography>
            </Box>
          </Grid>
        )
      )}
    </Grid>
  );
};

export default DetailWithIcons;
