import React from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import Translation from '@App/components/Translation';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  descBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light,
    minHeight: '70px',
  },
  descBlockButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.contrastText,
  },
  descBlockTitle: {
    marginLeft: theme.spacing(-12),
    color: theme.palette.primary.contrastText,
  },
}));

const ActivityHeader = ({ activity }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box>
      <Box className={classes.descBlock}>
        <IconButton
          className={classes.descBlockButton}
          aria-label="back"
          onClick={() => history.goBack()}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <Typography
          className={classes.descBlockTitle}
          variant="h6"
          gutterBottom
        >
          {activity?.name ? (
            <Translation
              path="name"
              messages={activity?.translations}
              default={activity.name}
            />
          ) : (
            <Translation path="activities" />
          )}
        </Typography>
        <Box />
      </Box>
    </Box>
  );
};
export default ActivityHeader;
