function groupObjectKey(arr = [], key) {
  const memory = {
    temp: [],
    result: [],
    default: [],
  };

  arr.map((o) => {
    if (!memory.temp.includes(o[key])) {
      memory.temp.push(o[key]);
      const item = arr.filter((t) => t[key] === o[key]);
      memory.result.push({ [key]: o[key], item });
    }
  });
  return memory.result.sort((x, y) => {
    if (x[key] == undefined || x[key] > y[key]) {
      return 1;
    }
    if (y[key] == undefined || x[key] < y[key]) {
      return -1;
    }

    return 0;
  });
}

// eslint-disable-next-line import/prefer-default-export
export { groupObjectKey };
