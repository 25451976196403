import Can from '@App/components/Can';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TourBookingButton from './Bookings/TourBookingButton';
import TourCard from './TourCard';

const TourList = ({ tours }) => {
  const { t: translate } = useTranslation();
  const hasTours = () => tours.length > 0;

  return hasTours() ? (
    <>
      <Grid container spacing={5}>
        {tours.map((tour) => (
          <Grid key={tour.id} md={6} xs={12} item>
            <TourCard tour={tour} />
          </Grid>
        ))}
      </Grid>
      <Can perform="tour-bookings:view" yes={() => <TourBookingButton />} />
    </>
  ) : (
    <Alert severity="info">{translate('tours.available')}</Alert>
  );
};

TourList.propTypes = {
  tours: PropTypes.array.isRequired,
};

export default TourList;
