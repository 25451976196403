import React, { useState } from 'react';
import {
  useTranslation,
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SlideDown } from 'react-slidedown';

import Card from '@App/components/Card';
import CardBadge from '@App/components/Card/CardBadge';
import CardTopButton from '@App/components/Card/CardTopButton';
import DateTime from '@App/components/Date/DateTime';
import Translation, { translate } from '@App/components/Translation';
import useMaterialConfirm from '@App/hooks/useMaterialConfirm';
import useMaterialSnackbar from '@App/hooks/useMaterialSnackbar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MuiCardActions from '@material-ui/core/CardActions';
import MuiCardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';

import RestaurantBookingCardDetail from './RestaurantBookingCardDetail';
import RestaurantBookingCardForm from './RestaurantBookingCardForm';
import useBookings from './useBookings';
import { makeStyles } from '@material-ui/core';
import RestaurantTitle from '../RestaurantTitle';
import ButtonProgress from '@App/components/ButtonProgress';
import RestaurantBooking from '@App/types/RestaurantBooking';
import useAuth from '@App/hooks/useAuth';

const useStyles = makeStyles(() => ({
  buttonAction: {
    fontWeight: 700,
  },
  buttonActionIcon: {
    marginLeft: '7px',
  },
  loadingCard: {
    opacity: 0.5,
  },
}));

interface RestaurantBookingCardProps {
  booking: RestaurantBooking;
}

const RestaurantBookingCard: React.FC<
  RestaurantBookingCardProps & WithTranslation
> = ({ t, booking }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const { i18n } = useTranslation();

  const [showEditForm, setShowEditForm] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    bookings,
    updateAll: updateBookings,
    delete: deleteBooking,
  } = useBookings();

  const confirm = useMaterialConfirm();
  const snackbar: any = useMaterialSnackbar();
  const history = useHistory();

  const handleUpdateFormShowClick = () => {
    setShowEditForm(!showEditForm);
    setShowDetails(false);
  };

  const handleDetailShowClick = () => {
    setShowDetails(!showDetails);
    setShowEditForm(false);
  };

  const handleSubmit = async (data: any) => {
    booking = { ...booking, ...data };

    try {
      setLoading(true);
      await updateBookings(bookings.data, booking, user);
      snackbar({ message: translate({ t, path: 'operation.successful' }) });
      setLoading(false);
    } catch ({ response }) {
      snackbar({ variant: 'error', message: response.data.message });
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await confirm();
      setLoading(true);
      await deleteBooking(bookings.data, booking);
      snackbar({
        message: translate({ t, path: 'delete.reservation.confirmation' }),
      });
      setLoading(false);
    } catch ({ response }) {
      snackbar({ variant: 'error', message: response.data.message });
      setLoading(false);
    }
  };

  return (
    <Card
      className={loading && classes.loadingCard}
      elevation={2}
      badge={
        <CardBadge>
          {
            booking.additionalData.turnType?.translations?.[`${i18n.language}`]
              ?.name
          }
        </CardBadge>
      }
      topButton={
        <CardTopButton disabled={loading} onClick={handleDelete}>
          <DeleteIcon />
        </CardTopButton>
      }
    >
      {loading && <ButtonProgress />}
      <MuiCardContent>
        <DateTime
          date={booking.additionalData.bookingDate}
          time={booking.additionalData.bookingTime}
        />
        <Divider />
        <Box mt={2}>
          <RestaurantTitle
            type={
              <>
                <Translation path="restaurant" />
                &nbsp;-&nbsp;
                <Translation path={booking.additionalData.restaurantMenuType} />
              </>
            }
            title={`${
              booking.additionalData?.category?.translations?.[
                `${i18n.language}`
              ]?.name
            } | ${booking.additionalData.restaurantName}`}
          />
        </Box>
        <Divider />
      </MuiCardContent>
      <MuiCardActions>
        <Box display="flex" justifyContent="space-between" width="100%">
          {booking.additionalData.restaurantMenuType === 'restaurant.card' && (
            <Button
              className={`${classes.buttonAction}`}
              size="small"
              color="primary"
              onClick={() => {
                history.push(`/restaurants/bookings/${booking.id}/dish-orders`);
              }}
            >
              <Translation path={'my.menu'} />
              <i className={`fas fa-coffee ${classes.buttonActionIcon}`}></i>
            </Button>
          )}
          <Button
            className={`${classes.buttonAction}`}
            size="small"
            color="primary"
            onClick={() => handleUpdateFormShowClick()}
          >
            <Translation path={'modify'} />
            <i className={`fas fa-pencil-alt ${classes.buttonActionIcon}`}></i>
          </Button>
          <Button
            className={`${classes.buttonAction}`}
            size="small"
            color="primary"
            onClick={() => handleDetailShowClick()}
          >
            <Translation path={'more.details'} />
            <i className={`fas fa-caret-down ${classes.buttonActionIcon}`}></i>
          </Button>
        </Box>
      </MuiCardActions>
      <SlideDown>
        {showEditForm ? (
          <Box p={2}>
            <RestaurantBookingCardForm
              booking={booking}
              handleSubmit={handleSubmit}
              handleCancel={handleUpdateFormShowClick}
            />
          </Box>
        ) : null}
      </SlideDown>
      <SlideDown>
        {showDetails ? (
          <Box p={2}>
            <RestaurantBookingCardDetail booking={booking} />
          </Box>
        ) : null}
      </SlideDown>
    </Card>
  );
};

export default withTranslation()(RestaurantBookingCard);
