import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  verticalCentered: {
    display: 'flex',
    flex: '1 0 auto',
    alignItems: 'center',
    flexFlow: 'column',
  },
  horizontalCentered: {
    display: 'flex',
    flex: '1 0 auto',
    justifyContent: 'center',
    flexFlow: 'column',
  },
  allCentered: {
    display: 'flex',
    flex: '1 0 auto',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column',
  },
}));

export default useStyles;
