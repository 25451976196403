import { isEmpty, isUndefined } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Card from '@App/components/Card';
import DetailWithIcons from '@App/components/DetailWithIcons/DetailWithIcons';
import Translation, { translate } from '@App/components/Translation';
import useMaterialConfirm from '@App/hooks/useMaterialConfirm';
import UserService, { wrapAssociatedUsers } from '@App/services/user';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

import useAuth from '@App/hooks/useAuth';
import RestaurantTitle from '../RestaurantTitle';
import RestaurantBookingConfirmForm from './RestaurantBookingConfirmForm';
import RestaurantBookingConfirmSkeleton from './RestaurantBookingConfirmSkeleton';
import RestaurantBookingHeader from './RestaurantBookingHeader';
import useBookings from './useBookings';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.secondary.main,
  },
  titleIco: {
    marginRight: theme.spacing(1),
  },
  titleText: {
    fontWeight: 'bold',
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
}));

const RestaurantBookingConfirm: React.FC<any> = ({ t }) => {
  const classes = useStyles();
  const confirm: any = useMaterialConfirm();
  const history = useHistory();
  const { i18n } = useTranslation();
  const { user } = useAuth();
  const { booking, create: createBooking } = useBookings();

  const [associatedGuests, setAssociatedGuests] = useState({
    data: [],
    loading: true,
    error: null,
  });

  const handleSubmit = async (data: any) => {
    booking.data.additionalData = {
      ...booking.data.additionalData,
      associatedGuestsRegistryNumbers: data.associatedGuestsRegistryNumbers,
      bookedHostQuotaWithAssociatedGuestsQuota:
        data.bookedHostQuotaWithAssociatedGuestsQuota,
      bookingTime: data.bookingTime,
      bookingTimeWithFormat: data.bookingTimeWithFormat,
      status: 'A',
    };

    createBooking({
      ...booking.data,
      turnDistributionId: data.turnDistributionId,
      bookedQuota: data.bookedHostQuota,
      notes: data.notes,
      email: data.email,
      observations: data.observations,
    });
  };

  const findAssociatedUsers = async () => {
    const data: any = {
      ...user,
      bookingDate: booking.data.additionalData.bookingDate,
      turnTypeId: booking.data.additionalData.turnTypeId,
    };

    try {
      setAssociatedGuests({ data: [], loading: true, error: null });
      const request: any = wrapAssociatedUsers(
        await UserService.findAssociatedUsers(data)
      );

      setAssociatedGuests({ data: request, loading: false, error: null });
    } catch (error) {
      setAssociatedGuests({ data: [], loading: false, error });
    }
  };

  const fetchData = async () => {
    await findAssociatedUsers();
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!isEmpty(booking.error)) {
        await confirm({
          title: translate({ t, path: 'error' }),
          description: booking.error,
        });
        history.push('/restaurants/bookings');
      }
    })();
  }, [booking.error]);

  useEffect(() => {
    (async () => {
      if (!isUndefined(booking.data?.id)) {
        if (
          booking.data.additionalData.restaurantMenuType === 'restaurant.card'
        ) {
          try {
            await confirm({
              title: translate({ t, path: 'continue' }),
              description: translate({ t, path: 'continue.menu' }),
            });
            history.push(
              `/restaurants/bookings/${booking.data.id}/dish-orders`
            );
          } catch (e) {
            history.push('/restaurants/bookings/success');
          }
        } else {
          history.push('/restaurants/bookings/success');
        }
      }
    })();
  }, [booking.data?.id]);

  return (
    <Container maxWidth="md">
      <RestaurantBookingHeader
        title={<Translation path="list.restaurants.title" />}
        subtitle={<Translation path="list.restaurants.description" />}
      />
      {associatedGuests.loading ? (
        <RestaurantBookingConfirmSkeleton />
      ) : (
        <Card>
          <CardContent>
            <RestaurantTitle
              type={
                <>
                  <Translation path="restaurant" />
                  &nbsp; -&nbsp;
                  <Translation
                    path={booking.data.additionalData.restaurantMenuType}
                  />
                </>
              }
              title={booking.data.additionalData.restaurantName}
            />
            <Divider className={classes.divider} />
            <DetailWithIcons
              data={[
                {
                  label: <Translation path="date" />,
                  icon: 'fas fa-calendar',
                  content: booking.data.additionalData.bookingDate,
                },
                {
                  label: <Translation path="available.occuppancies" />,
                  icon: 'fas fa-users',
                  content: booking.data.additionalData.restaurantAvailableQuote,
                },
                {
                  label: <Translation path="turn.type" />,
                  icon: 'fas fa-clock',
                  content:
                    booking.data?.additionalData.turnType?.[`${i18n.language}`],
                },
                {
                  label: <Translation path="hotel" />,
                  icon: 'fas fa-hotel',
                  content: booking.data.additionalData.hotelName,
                },
                {
                  label: <Translation path="location" />,
                  icon: 'fas fa-map-marker',
                  content: booking.data.additionalData.restaurantLocation,
                  optional: true,
                },
                {
                  label: <Translation path="conditions" />,
                  icon: 'fas fa-exclamation-circle',
                  content: booking.data.additionalData.restaurantConditions,
                },
              ]}
            />
            <Divider className={classes.divider} />
            <RestaurantBookingConfirmForm
              associatedGuests={associatedGuests.data}
              handleSubmit={handleSubmit}
            />
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

export default withTranslation()(RestaurantBookingConfirm);
