import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components...
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

// Styles...
import { makeStyles } from '@material-ui/core/styles';

// Others...
import clip from 'text-clipper';

const useStyles = makeStyles((theme) => ({
  detail: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
  },
  detailText: {
    fontWeight: 'bold',
  },
}));

const TextClipper = ({ content, maxLength, ...others }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const clippedString = clip(content, maxLength, { ...others });

  const handleOpenClick = () => {
    setOpen(!open);
  };

  return content?.length > maxLength ? (
    <>
      <Typography {...others}>
        {content?.length > maxLength && !open ? clippedString : content}
      </Typography>
      <Box
        className={classes.detail}
        component="span"
        onClick={handleOpenClick}
      >
        <Typography
          className={classes.detailText}
          component="span"
          variant="body2"
        >
          Mas detalles
        </Typography>
        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Box>
    </>
  ) : (
    <Typography {...others}>{content}</Typography>
  );
};

TextClipper.propTypes = {
  content: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
};

TextClipper.defaultProps = {
  maxLength: 3,
};

export default TextClipper;
