import { COOKIE_AUTH } from '@App/utils/constants';
import { AuthContext } from '@App/providers/Authentication/context';
import { useContext } from 'react';
import {
  removeCookie,
  setCookieJson,
  getCookieToJson,
} from '@App/utils/cookie';
import { omit } from 'lodash';

const useAuth = () => {
  const { refresh } = useContext(AuthContext);

  const setAuthenticatedUser = (data: any) => {
    const { accessToken, hotelId } = data;
    removeCookie(COOKIE_AUTH);
    setCookieJson(COOKIE_AUTH, {
      isAuthenticated: true,
      tokenType: 'Bearer',
      expiresIn: 0,
      accessToken,
      refreshToken: '',
      hotelId,
      user: omit(data, ['accessToken']),
    });
    refresh();
  };

  const user = getCookieToJson(COOKIE_AUTH)?.user || null;
  const isAuthenticated =
    getCookieToJson(COOKIE_AUTH)?.isAuthenticated || false;

  const logout = () => {
    removeCookie(COOKIE_AUTH);
    refresh();
  };

  return {
    setAuthenticatedUser,
    user,
    isAuthenticated,
    logout,
  };
};
export default useAuth;
