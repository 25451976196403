import moment from 'moment';
import React from 'react';

import Translation from '@App/components/Translation';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dayNumber: {
    fontWeight: 500,
    fontSize: '2.8125rem',
    color: theme.palette.success.main,
  },
  dayName: {
    fontWeight: 500,
    fontSize: '1.5625rem',
    lineHeight: '1.5625rem',
  },
  monthName: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '1rem',
  },
}));

interface DayProps {
  date: string;
}

const Date: React.FC<DayProps> = ({ date }) => {
  const classes = useStyles();
  const formattedDate = {
    day: moment.utc(date).format('DD'),
    dayNumber: moment.utc(date).day(),
    monthNumber: moment.utc(date).month(),
  };

  return (
    <Box display="flex" alignItems="center">
      <Typography className={classes.dayNumber} variant="h3">
        {formattedDate.day}
      </Typography>
      <Box ml={1} display="flex" flexDirection="column">
        <Typography variant="body1" className={classes.dayName}>
          <Translation path={`day.${formattedDate.dayNumber}`} />
        </Typography>
        <Typography variant="body2" className={classes.monthName}>
          <Translation path={`month.${formattedDate.monthNumber}`} />
        </Typography>
      </Box>
    </Box>
  );
};

export default Date;
