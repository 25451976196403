import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import ButtonProgress from '@App/components/ButtonProgress';
import Translation from '@App/components/Translation';
import yup from '@App/plugins/yup';
import RestaurantTurnDistributionService from '@App/services/RestaurantTurnDistribution';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import restaurantBookingObservationService from '@App/services/RestaurantBookingObservation';
import { COOKIE_AUTH } from '@App/utils/constants';
import { getI18n, useTranslation } from 'react-i18next';
import { getCookieToJson } from '@App/utils/cookie';
import RestaurantBookingAssociatedGuestsTable from './RestaurantBookingAssociatedGuestsTable';
import useBookings from './useBookings';

const useStyles = makeStyles((theme) => ({
  titleCoupon: {
    marginTop: theme.spacing(2),
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  textSlug: {
    marginTop: theme.spacing(1),
  },
  error: {
    color: theme.palette.error.main,
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  submit: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

interface RestaurantBookingConfirmFormProps {
  associatedGuests: any;
  handleSubmit: Function;
}

const RestaurantBookingConfirmForm: React.FC<RestaurantBookingConfirmFormProps> = ({
  associatedGuests,
  handleSubmit,
}) => {
  const classes = useStyles();
  const { language } = getI18n();
  const { booking } = useBookings();
  const [turnDistributions, setTurnDistributions] = useState({
    data: [],
    loading: false,
    error: null,
  });

  const [observations, setObservations] = useState({
    data: [],
    loading: false,
    error: null,
  });

  const [selectedGuests, setSelectedGuests] = useState([]);
  const [turnDistributionId, setTurnDistributionId] = useState(null);
  const [hostQuota, setHostQuota] = useState(0);
  const [totalQuota, setTotalQuota] = useState(0);
  const [associatedGuestsQuota, setAssociatedGuestsQuota] = useState(0);
  const [scheduleError, setScheduleError] = useState<string>('');
  const { t: translate }: any = useTranslation();
  const user = getCookieToJson(COOKIE_AUTH)?.user || null;

  const schema = yup.object().shape({
    numberPersons: yup.string().required().label('number.of.persons'),
    bookingTime: yup.string().required().label('hours'),
    notes: yup.string(),
    email: yup.string().email(),
  });

  const defaultValues: any = {
    numberPersons: '',
    bookingTime: '',
    notes: '',
    email: user?.email || '',
    observations: [],
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const {
    control,
    handleSubmit: handleSubmitHook,
    errors,
    formState,
  } = methods;

  const onSubmit = (data: any) => {
    parseDataOnSubmit(data);
    return handleSubmit(data);
  };

  const parseDataOnSubmit = (data: any): void => {
    data.turnDistributionId = turnDistributionId;
    data.associatedGuestsRegistryNumbers = [];
    selectedGuests.forEach((selectedGuest: any) => {
      data.associatedGuestsRegistryNumbers.push(selectedGuest.registryNumber);
    });
    data.bookedHostQuota = hostQuota;
    data.bookedHostQuotaWithAssociatedGuestsQuota =
      hostQuota + associatedGuestsQuota;
    data.bookingTimeWithFormat = moment
      .utc(data.bookingTime, 'hh:mm')
      .format('h:mm A');
  };

  const handleFindSchedules = async (event: any) => {
    const data = {
      selectedQuote: event.target.value,
      restaurantId: booking.data.restaurantId,
      turnTypeId: booking.data.additionalData.turnTypeId,
      bookingDate: booking.data.additionalData.bookingDate,
    };

    setTimeout(async () => {
      try {
        setTurnDistributions({
          ...turnDistributions,
          data: [],
          loading: true,
        });
        setScheduleError('');

        const resource = data.selectedQuote
          ? RestaurantTurnDistributionService.wrapTurnDistribution(
              await RestaurantTurnDistributionService.find(data)
            )
          : [];

        setTurnDistributions({
          ...turnDistributions,
          data: resource,
          loading: false,
        });
      } catch (error) {
        setTurnDistributions({
          data: [],
          loading: false,
          error,
        });

        setScheduleError(translate('no.schedules.available'));
      }
    });
  };

  const handleAssociatedGuestsQuotaChange = () => {
    const associatedGuestsQuota = (selectedGuests as any).reduce(
      (oldValue: any, newValue: any) =>
        newValue.selected
          ? oldValue + parseInt(newValue.restaurantQuota)
          : oldValue,
      0
    );

    setAssociatedGuestsQuota(associatedGuestsQuota);
  };

  const availableQuota = () => {
    const availableHostQuota = parseInt(
      booking.data.additionalData.availableHostQuota
    );
    let total = availableHostQuota;

    if (!isEmpty(associatedGuests)) {
      const { maxRestaurantQuota } = associatedGuests[0];
      const maxGuestsQuota = associatedGuests.reduce(
        (oldValue: any, newValue: any) =>
          oldValue + parseInt(newValue.restaurantQuota),
        0
      );
      const bookedRestaurantQuota = associatedGuests.reduce(
        (oldValue: any, newValue: any) =>
          oldValue + parseInt(newValue.bookedRestaurantQuota),
        0
      );
      total = availableHostQuota + maxGuestsQuota - bookedRestaurantQuota;
      total = total > maxRestaurantQuota ? maxRestaurantQuota : total;
    }
    return total;
  };

  const fetchObservations = async () => {
    try {
      setObservations({ data: [], loading: true, error: null });
      const resource = await restaurantBookingObservationService.getAll();
      setObservations({
        data: resource.data,
        loading: false,
        error: null,
      });
    } catch (error) {
      setObservations({ data: [], loading: true, error });
    }
  };

  useEffect(() => {
    handleAssociatedGuestsQuotaChange();
  }, [selectedGuests]);

  useEffect(() => {
    setTotalQuota(hostQuota + associatedGuestsQuota);
  }, [hostQuota, associatedGuestsQuota]);

  useEffect(() => {
    (async () => {
      await fetchObservations();
    })();
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmitHook(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FormControl
              error={Boolean(errors.numberPersons)}
              variant="outlined"
            >
              <InputLabel id="number-of-persons">
                <Translation path="number.of.persons" />
              </InputLabel>
              <Controller
                name="numberPersons"
                error={Boolean(errors.numberPersons)}
                control={control}
                labelId="number-of-persons"
                render={({ onChange, ...others }) => (
                  <Select
                    onChange={(event: any) => {
                      const hostQuota: number = event.target.value
                        ? parseInt(event.target.value)
                        : 0;
                      handleFindSchedules(event);
                      setHostQuota(hostQuota);
                      onChange(event.target.value);
                    }}
                    label={<Translation path="number.of.persons" />}
                    {...others}
                  >
                    <MenuItem value="">
                      <em>
                        <Translation path="select" />
                      </em>
                    </MenuItem>
                    {[
                      ...Array(
                        parseInt(booking.data.additionalData.availableHostQuota)
                      ),
                    ].map((item, index) => (
                      <MenuItem key={index} value={index + 1}>
                        <em>{index + 1}</em>
                      </MenuItem>
                    ))}
                  </Select>
                )}
                fullWidth
              />
              <FormHelperText>{errors.numberPersons?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              error={Boolean(errors.bookingTime) || Boolean(scheduleError)}
              variant="outlined"
            >
              <InputLabel id="bookingTime">
                <Translation path="hours" />
              </InputLabel>
              <Controller
                name="bookingTime"
                error={Boolean(errors.bookingTime)}
                control={control}
                labelId="bookingTime"
                render={({ onChange, ...others }) => (
                  <>
                    {turnDistributions.loading && <ButtonProgress />}
                    <Select
                      onChange={(event: any) => {
                        onChange(event.target.value);
                      }}
                      disabled={
                        turnDistributions.loading ||
                        isEmpty(turnDistributions.data) ||
                        hostQuota <= 0
                      }
                      label={<Translation path="hours" />}
                      {...others}
                    >
                      <MenuItem
                        onClick={() => {
                          setTurnDistributionId(null);
                        }}
                        value=""
                      >
                        <em>
                          <Translation path="select" />
                        </em>
                      </MenuItem>
                      {turnDistributions.data.map(
                        (turnDistribution: any, index) => (
                          <MenuItem
                            onClick={() => {
                              setTurnDistributionId(turnDistribution.id);
                            }}
                            key={index}
                            value={turnDistribution.time}
                          >
                            <em>
                              {moment
                                .utc(turnDistribution.time, 'hh:mm')
                                .format('h:mm A')} - &nbsp;
                              <Translation path="places.available" count={turnDistribution.enabled} />
                            </em>
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </>
                )}
                fullWidth
              />
              <FormHelperText>
                {errors.bookingTime?.message || scheduleError}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Typography className={classes.textSlug} variant="subtitle2">
          <Translation path="finish.reservation.room.message" />
        </Typography>
        <Box my={2}>
          <RestaurantBookingAssociatedGuestsTable
            associatedGuests={associatedGuests}
            handleGuests={(guests: any) => setSelectedGuests(guests)}
          />
        </Box>
        <Typography className={classes.titleCoupon} variant="h6">
          <Translation path="selected.occupancies" />: {totalQuota}{' '}
          <Translation path="of" /> {availableQuota()}
        </Typography>
        {totalQuota > availableQuota() && (
          <Typography className={classes.error} variant="subtitle2">
            <Translation path="occupancies.error.p1" /> {totalQuota}{' '}
            <Translation path="occupancies.error.p2" /> {availableQuota()}
          </Typography>
        )}
        <Divider className={classes.divider} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FormControl
              error={Boolean(errors.observations)}
              variant="outlined"
            >
              <InputLabel id="observations">
                <Translation path="observations" />
              </InputLabel>
              <Controller
                name="observations"
                error={Boolean(errors.observations)}
                control={control}
                labelId="observations"
                render={({ onChange, ...others }) => (
                  <>
                    {observations.loading && <ButtonProgress />}
                    <Select
                      multiple
                      onChange={(event: any) => {
                        onChange(event.target.value);
                      }}
                      renderValue={(selected) =>
                        (selected as string[]).map((s: any) => s?.translations?.[language]?.name || s.name).join(', ')
                      }
                      disabled={observations.loading}
                      label={<Translation path="observations" />}
                      {...others}
                    >
                      <MenuItem value="">
                        <em>
                          <Translation path="select" />
                        </em>
                      </MenuItem>
                      {observations.data.map(
                        (observation: any, index: number) => (
                          <MenuItem key={index} value={observation}>
                            <em>
                              <Translation
                                path="name"
                                messages={observation.translations}
                                default={observation.name}
                              />
                            </em>
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </>
                )}
                fullWidth
              />
              <FormHelperText>
                {errors.bookingTime?.message || scheduleError}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Controller
              variant="outlined"
              name="email"
              control={control}
              as={TextField}
              label={<Translation path="email" />}
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
            />
            <Typography className={classes.textSlug} variant="subtitle2">
              <Translation path="finish.reservation.email.message" />
            </Typography>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Typography className={classes.textSlug} variant="subtitle1">
          <Translation path="finish.reservation.click.confirm" />
        </Typography>
        <Box className={classes.actions}>
          <Button
            className={classes.submit}
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              booking.loading ||
              !formState.isValid ||
              totalQuota > availableQuota()
            }
          >
            {booking.loading && <ButtonProgress />}
            <Translation path="continue" />
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};

export default RestaurantBookingConfirmForm;
