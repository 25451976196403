import React from 'react';

// Components...
import Box from '@material-ui/core/Box';
import Card from '@App/components/Card';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';

// Styles...
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: '2px',
  },
  text: {
    marginRight: theme.spacing(1),
    height: `${theme.spacing(2)}px`,
  },
  content: {
    paddingBottom: `${theme.spacing(2)}px !important`,
  },
}));

const RestaurantDishOrdersAccordionSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Box display="flex">
          <Skeleton className={classes.text} width="30%" animation="wave">
            <Typography>.</Typography>
          </Skeleton>
          <Skeleton className={classes.text} width="60%" animation="wave">
            <Typography>.</Typography>
          </Skeleton>
          <Skeleton className={classes.text} width="10%" animation="wave">
            <Typography>.</Typography>
          </Skeleton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default RestaurantDishOrdersAccordionSkeleton;
