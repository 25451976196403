/**
 * @fileoverview  Constants
 * @version       0.1
 * @author        Miguel Ortiz <miguel.ortiz@decameron.com>
 */

// Cookies...
export const COOKIE_AUTH = 'ah_at';
export const COOKIE_LANG = 'ah_lang';
export const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
export const HAS_ENCRYPTED_DATA = process.env.REACT_APP_ENCRYPTED_DATA === 'true';
export const URL_API_HOTEL = `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_PATH_URL}/`;
