import RestaurantTurnDistribution from '@App/types/RestaurantTurnDistribution';

export default class RestaurantTurnDistributionWrap {
  static wrapTurnDistribution({ data }: any): any {
    const wrappedData: RestaurantTurnDistribution[] = [];

    data.Datos.forEach((item: any) => {
      const wrappedItem: RestaurantTurnDistribution = {
        id: item.ID_DISTUR,
        turnId: item.ID_TURN,
        restaurantId: item.ID_REST,
        restaurantName: item.NOMBRE_REST,
        date: item.FECHA_FIN_TURN,
        time: item.HORA_INI_TURN,
        enabled: item.CUPOS_DISPONIBLES,
      };

      wrappedData.push(wrappedItem);
    });

    return wrappedData;
  }
}
