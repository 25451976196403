import React, { useContext, useEffect, useState } from 'react';
import Footer from '@App/components/Footer';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import Translation from '@App/components/Translation';
import HelpIcon from '@material-ui/icons/Help';
import classNames from 'classnames';
import Axios from '@App/plugins/axios';
import { AuthContext } from '@App/providers/Authentication/context';
import useBookings from './Bookings/useBookings';
import RestaurantFooterHelp from './RestaurantFooterHelp';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  assistedBookingButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  assistedBookingButtonActive: {
    color: theme.palette.warning.light,
  },
  helpIcon: { fontSize: 14, marginRight: 3 },
}));


// @fixme Wrap Temporal
const wrapGlosa = (data: any): any => ({
  control: data.Datos.Control,
  extension: data.Datos.Extension,
});

const RestaurantFooter: React.FC<any> = () => {
  const classes = useStyles();
  const { booking, update: updateBooking } = useBookings();
  const { isAuthenticated } = useContext(AuthContext);

  const [glosa, setGlosa]: any = useState({
    data: {},
    loading: true,
    error: null,
  });

  const isAssisted = booking.data.channel === 'Web asistida';

  const handleAssistReservation = () => {
    if (!isAssisted) {
      updateBooking({ ...booking.data, channel: 'Web asistida' });
    } else {
      updateBooking({ ...booking.data, channel: 'Web' });
    }
  };

  const fetchGlosa = async () => {
    try {
      setGlosa({ data: {}, loading: true, error: null });
      const resource = await Axios.post('mostrarglosa');
      setGlosa({ data: wrapGlosa(resource.data), loading: false, error: null });
    } catch (error) {
      setGlosa({ data: {}, loading: true, error });
    }
  };

  const fetchData = async () => {
    // eslint-disable-next-line no-unused-expressions
    isAuthenticated && (await fetchGlosa());
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  return (
    <Footer>
      <Box>
        <RestaurantFooterHelp />
        <Typography variant="caption" component="div" gutterBottom>
          <Translation path="footer.text.p1" />
          {` ${glosa.data?.extension || ''} `}
          <Translation path="footer.text.p2" />
        </Typography>
        <Typography variant="caption" component="div" gutterBottom>
          Decameron All Inclusive Hotels & Resorts - RNT 3961 - Copyright
        </Typography>
        <Typography
          className={classNames({
            [`${classes.assistedBookingButton}`]: true,
            [`${classes.assistedBookingButtonActive}`]: isAssisted,
          })}
          variant="caption"
          component="div"
          gutterBottom
          onClick={handleAssistReservation}
        >
          <HelpIcon className={classes.helpIcon} />
          <Translation path="assist.reservation" />
        </Typography>
      </Box>
    </Footer>
  );
};

export default RestaurantFooter;
