import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';

import Card from '@App/components/Card';
import DateTime from '@App/components/Date/DateTime';
import DetailWithIcons from '@App/components/DetailWithIcons/DetailWithIcons';
import Translation from '@App/components/Translation';
import useBookings from '@App/containers/Restaurants/Bookings/useBookings';
import RestaurantTitle from '@App/containers/Restaurants/RestaurantTitle';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MuiCardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.success.main,
    padding: theme.spacing(3),
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const RestaurantBookingSuccess: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { booking } = useBookings();
  const { i18n } = useTranslation();

  const handleFinishClick = () => {
    history.push('/restaurants/bookings');
  };

  return isEmpty(booking.data) ? (
    <Redirect to="/restaurants/bookings" />
  ) : (
    <Container maxWidth="md">
      <Card>
        <Box className={classes.header}>
          <Typography variant="h5">
            <Translation path="create.reservation.confirmation.title" /> #
            {booking.data.id}
          </Typography>
        </Box>
        <MuiCardContent>
          <DateTime
            date={booking.data.additionalData.bookingDate}
            time={booking.data.additionalData.bookingTime}
          />
          <Divider className={classes.divider} />
          <RestaurantTitle
            type={
              <>
                <Translation path="restaurant" /> -{' '}
                {booking.data.additionalData.restaurantName}
              </>
            }
            title={booking.data.additionalData.hotelName}
          />
          <Divider className={classes.divider} />
          <DetailWithIcons
            data={[
              {
                label: <Translation path="hotel" />,
                icon: 'fas fa-hotel',
                content: booking.data.additionalData.hotelName,
              },
              {
                label: <Translation path="type" />,
                icon: 'fas fa-star',
                content:
                  booking.data.additionalData.restaurantFoodType?.[
                    `${i18n.language}`
                  ],
              },
              {
                label: <Translation path="location" />,
                icon: 'fas fa-map-marker',
                content: booking.data.additionalData.restaurantLocation,
                optional: true,
              },
              {
                label: <Translation path="date" />,
                icon: 'fas fa-calendar-o',
                content: booking.data.additionalData.bookingDate,
              },
              {
                label: <Translation path="conditions" />,
                icon: 'fas fa-exclamation-triangle',
                content: booking.data.additionalData.restaurantConditions,
              },
              {
                label: <Translation path="occupancies" />,
                icon: 'fas fas fa-users',
                content:
                  booking.data.additionalData
                    .bookedHostQuotaWithAssociatedGuestsQuota,
              },
              {
                label: <Translation path="observations" />,
                icon: 'fas fa-file-text',
                content: booking.data.notes,
                optional: true,
              },
              {
                label: <Translation path="email" />,
                icon: 'fas fa-envelope',
                content: booking.data.email,
                optional: true,
              },
            ]}
          />
          <Box className={classes.actions}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleFinishClick}
            >
              <Translation path="finish" />
            </Button>
          </Box>
        </MuiCardContent>
      </Card>
    </Container>
  );
};

export default RestaurantBookingSuccess;
