import { isEmpty } from 'lodash';
import React from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'absolute',
    padding: `0 ${theme.spacing(1)}px`,
    top: '-25px',
  },
  circle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '38px',
    width: '38px',
    borderRadius: '50%',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.secondary.main,
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    top: '8px',
    left: '-13px',
    height: '25px',
    margin: `0 ${theme.spacing(0.5)}px`,
    padding: `0 ${theme.spacing(1)}px`,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '4px',
    minWidth: theme.spacing(12),
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

interface CardBadgeCircleProps {
  slug?: any;
  text?: any;
}

const CardBadgeCircle: React.FC<CardBadgeCircleProps> = ({ slug, text }) => {
  const classes = useStyles();
  return !isEmpty(slug) && !isEmpty(text) ? (
    <Box className={classes.root}>
      <Typography
        className={classes.circle}
        variant="subtitle1"
        component="span"
      >
        {slug}
      </Typography>
      <Typography className={classes.text} variant="subtitle1" component="span">
        {text}
      </Typography>
    </Box>
  ) : (
    <></>
  );
};

export default CardBadgeCircle;
