import React, { useState, useEffect } from 'react';

import MuiTextField from '@material-ui/core/TextField';

const TextField: React.FC<any> = ({ delayTimeout, onDelayChange, onChange, ...props }) => {
  const [value, setValue] = useState('');

  const handleOnChange = (event: any) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(
      () => onDelayChange(value),
      delayTimeout
    );
    return () => clearTimeout(timeoutId);
  }, [value]);

  return <MuiTextField onChange={handleOnChange} {...props} />;
};

TextField.defaultProps = {
  delayTimeout: 300,
  onDelayChange: () => ({}),
};

export default TextField;
