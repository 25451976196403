import Can from '@App/components/Can';
import { AuthContext } from '@App/providers/Authentication/context';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TodayIcon from '@material-ui/icons/Today';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useTourBookings from './Bookings/useTourBookings';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
}));

const TourCardActions: React.FC<any> = ({ tour }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t: translate } = useTranslation();
  const { showUserForm, updateSelectedTour } = useTourBookings();
  const { isAuthenticated } = useContext(AuthContext);

  const handleBookingClick = async () => {
    if (isAuthenticated) {
      history.push(`/tours/${tour.id}/bookings`);
    } else {
      updateSelectedTour(tour);
      showUserForm(true);
    }
  };

  return (
    <>
      {tour.isBookable && (
        <Can
          perform="tour-bookings:view"
          yes={() => (
            <Box className={classes.root}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleBookingClick}
                endIcon={<TodayIcon />}
              >
                {translate('booking')}
              </Button>
            </Box>
          )}
        />
      )}
    </>
  );
};

export default TourCardActions;
