import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import App from '@App/components/App';
import PrivateRoute from '@App/components/PrivateRoute';
import Footer from '@App/components/Footer';
import Header from '@App/components/Header';
import Main from '@App/components/Main';
import Tours from '@App/containers/Tours';
import TourBooking from '@App/containers/Tours/Bookings/TourBooking';

const TourLayout: React.FC = () => (
  <App>
    <Header />
    <Main>
      <Switch>
        <PrivateRoute
          exact
          path="/tours"
          component={Tours}
          perform="tours:view"
        />
        <PrivateRoute
          exact
          path="/tours/bookings"
          component={TourBooking}
          perform="tours:view"
        />
        <PrivateRoute
          exact
          path="/tours/:tourId/bookings"
          component={TourBooking}
          perform="tours:view"
        />
        <Redirect to="/not-found" />
      </Switch>
    </Main>
    <Footer />
  </App>
);

export default TourLayout;
