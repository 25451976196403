import BlockUi from '@App/components/BlockUi';
import TextField from '@App/components/TextField';
import useMaterialSnackbar from '@App/hooks/useMaterialSnackbar';
import Axios from '@App/plugins/axios';
import yup from '@App/plugins/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useTourBookings from './useTourBookings';

const useStyles = makeStyles(() => ({
  dialogActions: {
    justifyContent: 'space-between',
  },
}));

const defaultValues: any = {
  roomCode: '',
  braceletNumber: '',
  identityDocumentCountry: 'CO',
  identityDocumentType: 'CC',
  identityDocumentCode: '',
  name: '',
  lastName: '',
  phone: '',
  email: '',
};

const identityDocumentTypes: any = [
  { id: 'CC', description: 'Identificación nacional' },
  { id: 'PA', description: 'Pasaporte' },
];

const TourBookingDialogUserForm: React.FC<any> = ({ t }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t: translate }: any = useTranslation();
  const { tourBookings, showUserForm } = useTourBookings();
  const snackbar = useMaterialSnackbar();
  const [user, setUser] = useState({
    data: null,
    loading: false,
    error: null,
  });

  const schema = yup.object().shape({
    name: yup.string().max(25).required(),
    identityDocumentType: yup.string().required(),
    identityDocumentCode: yup.string().min(4).max(25).required(),
    lastName: yup.string().max(25),
    email: yup.string().email(),
    phone: yup.string().min(4).max(25),
    roomCode: yup.string(),
    braceletNumber: yup.string().max(4),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const {
    control,
    handleSubmit: handleSubmitHook,
    errors,
    formState,
    watch,
    setValue,
  } = methods;

  const watchFields = watch(['roomCode', 'braceletNumber']);

  const setFormValues = (formValues: any) => {
    Object.entries(formValues).forEach((entry: any) => {
      const [key, value] = entry;
      setValue(key, value);
    });
  };

  const findUserByRoomCodeAndBraceletNumber = async ({
    roomCode,
    braceletNumber,
  }: any) => {
    try {
      setUser({ data: null, loading: true, error: null });
      const resource = await Axios.get(
        `/serviline/people?roomCode=${roomCode}&braceletNumber=${braceletNumber}`
      );
      const userFound = !isEmpty(resource.data) ? resource.data[0] : null;

      if (userFound) {
        const [name, lastName] = userFound.fullName.split(':');
        setFormValues({ ...userFound, name, lastName });
      } else {
        setFormValues({ ...defaultValues, roomCode, braceletNumber });
        // @ts-ignore
        snackbar({
          variant: 'info',
          message: translate('user.not.found'),
        });
      }

      setUser({ data: userFound, loading: false, error: null });
    } catch (error) {
      // @ts-ignore
      snackbar({ variant: 'error', message: error.message });
      setUser({ data: null, loading: false, error: error.message });
    }
  };

  const toInputUppercase = (event: any) => {
    event.target.value = event.target.value.toUpperCase();
  };

  const onDelayChange = async () => {
    if (watchFields.roomCode && watchFields.braceletNumber) {
      await findUserByRoomCodeAndBraceletNumber(watchFields);
    }
  };

  const onSubmit = async (data: any) => {
    // await createOrUpdateUser(newData);
    await showUserForm(false);
    history.push(`/tours/${tourBookings.selectedTour.id}/bookings`);
  };

  const handleClose = () => {
    showUserForm(false);
  };

  return (
    <Dialog
      fullWidth
      disableBackdropClick
      maxWidth="sm"
      open={tourBookings.showUserForm}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <BlockUi blocking={user.loading}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmitHook(onSubmit)}>
            <DialogTitle id="max-width-dialog-title">
              {translate('turn.booking.user.title')}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Controller
                    variant="outlined"
                    name="roomCode"
                    control={control}
                    as={TextField}
                    delayTimeout={500}
                    label={translate('login.room.number')}
                    error={Boolean(errors.roomCode)}
                    helperText={errors.roomCode?.message}
                    onDelayChange={onDelayChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    variant="outlined"
                    name="braceletNumber"
                    control={control}
                    as={TextField}
                    delayTimeout={500}
                    label={translate('login.digits.bracelet')}
                    error={Boolean(errors.braceletNumber)}
                    helperText={errors.braceletNumber?.message}
                    onDelayChange={onDelayChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined">
                    <InputLabel id="identity-document-type">
                      {translate('identityDocumentType')}*
                    </InputLabel>
                    <Controller
                      name="identityDocumentType"
                      error={Boolean(errors.identityDocumentType)}
                      control={control}
                      labelId="booked-quote-select"
                      as={
                        <Select>
                          {identityDocumentTypes.map(
                            (identityDocumentType: any, index: number) => (
                              <MenuItem
                                key={index}
                                value={identityDocumentType.id}
                              >
                                <em>{identityDocumentType.description}</em>
                              </MenuItem>
                            )
                          )}
                        </Select>
                      }
                      label={translate('identityDocumentType')}
                      fullWidth
                    />
                    <FormHelperText>
                      {errors.identityDocumentType?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    variant="outlined"
                    name="identityDocumentCode"
                    control={control}
                    as={MuiTextField}
                    label={`${translate('identityDocumentCode')}*`}
                    error={Boolean(errors.identityDocumentCode)}
                    helperText={errors.identityDocumentCode?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    variant="outlined"
                    name="name"
                    control={control}
                    as={MuiTextField}
                    label={`${translate('name')}*`}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                    onInput={toInputUppercase}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    variant="outlined"
                    name="lastName"
                    control={control}
                    as={MuiTextField}
                    label={`${translate('lastName')}*`}
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName?.message}
                    onInput={toInputUppercase}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    variant="outlined"
                    name="phone"
                    control={control}
                    as={MuiTextField}
                    label={`${translate('phone')}*`}
                    error={Boolean(errors.phone)}
                    helperText={errors.phone?.message}
                    onInput={toInputUppercase}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    variant="outlined"
                    name="email"
                    control={control}
                    as={MuiTextField}
                    label={`${translate('email')}*`}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                    onInput={toInputUppercase}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button onClick={handleClose} color="secondary">
                {translate('cancel')}
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={!formState.isValid}
              >
                {translate('continue')}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </BlockUi>
    </Dialog>
  );
};

export default TourBookingDialogUserForm;
