import React, { useEffect, useState, useMemo } from 'react';
import Container from '@App/components/Container';
import { useParams, useHistory } from 'react-router-dom';
import zones from '@App/mocks/zones.json';
import hotelsData from '@App/mocks/hotels.json';
import { groupObjectKey } from '@App/utils/helpers';
import { Typography, Box, Divider, Button, Grid } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import FlagIcon from '@material-ui/icons/Flag';
import HotelService from '@App/services/Hotel';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocalHotelIcon from '@material-ui/icons/LocalHotel';
import HotelList from './HotelList';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    marginTop: theme.spacing(2),
  },
  countrySection: {},
  sectionTitle: {
    marginTop: theme.spacing(2),
  },
  list: {
    marginTop: theme.spacing(2),
  },
  alert: {
    marginTop: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  button: {
    marginBottom: theme.spacing(2),
  },
}));

const Accordion = withStyles((theme) => ({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#edf1f5',
    borderBottom: '#edf1f5',
    color: '#fff',
    marginBottom: -1,
    minHeight: 30,
    '&$expanded': {
      minHeight: 30,
    },
  },
  gutters: {
    color: '#ffffff',
  },
  content: {
    '&$expanded': {
      margin: '5px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const FactSheet = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t: translate } = useTranslation();
  const { country } = useParams();
  const [zone, setZone] = useState(null);
  const [hotels, setHotels] = useState([]);
  const [expanded, setExpanded] = React.useState(null);

  useEffect(() => {
    // Consultamos los hoteles

    HotelService.getAll().then((response) => {
      const countryeHotels = response.data.filter(
        (hotel) => hotel.countryId == country,
      );
      // Este fix se programa para colocar el codigo RNT de los hoteles
      countryeHotels.map((hotel) => {
        hotel.rnt = null;
        const hotelData = hotelsData.find(
          (data) => data.id == hotel.id
        );
        if (hotelData) {
          hotel.rnt = hotelData.RNT;
        }
        return hotel;
      });
      setHotels(countryeHotels);
    });
  }, []);

  const filterHotels = useMemo(() => {
    const zonesCountry = zones[country];
    let updateHotels = hotels.map((hotel) => {
      // Buscamos la zona a la que pertenece el hotel
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < zonesCountry.length; i++) {
        const zone = zonesCountry[i];
        if (zone.hotels.includes(hotel.id)) {
          // eslint-disable-next-line no-param-reassign
          hotel.zoneName = zone.zoneName;
          break;
        }
      }
      return hotel;
    });
    if (zone) {
      updateHotels = updateHotels.filter((hotel) => hotel.zoneName == zone);
    }
    // setiamos el default accordion
    const groupHotels = groupObjectKey(updateHotels, 'zoneName');
    if (groupHotels.length && !expanded) {
      setExpanded(groupHotels[0].zoneName);
    }
    return groupHotels;
  }, [hotels, zone]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container maxWidth="md" className={classes.root}>
      <Grid
        mb="20"
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={9}>
          <Typography>
            {translate('hotels.search.title')}
          </Typography>
        </Grid>
        <Grid item xs={3} justifyContent="space-between">
          <Button
            className={classes.button}
            variant="contained"
            size="medium"
            onClick={() => history.push('/fact-sheets')}
            color="secondary"
            startIcon={<FlagIcon />}
          >
            {translate('change.country')}
          </Button>
        </Grid>
      </Grid>

      {filterHotels.length > 0 ? (
        filterHotels.map((h, index) => (
          <Accordion
            expanded={expanded == h.zoneName}
            onChange={handleChange(h.zoneName)}
            className={classes.countrySection}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel1a-content-${h.zoneName}`}
              id={`panel1a-content-${h.zoneName}`}
            >
              <Typography
                color="secondary"
                className={classes.sectionTitle}
                gutterBottom
                variant="h5"
                component="div"
              >
                <LocalHotelIcon className={classes.icon} />
                {h.zoneName}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <HotelList classes={classes.list} hotels={h.item} />
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Alert className={classes.alert} severity="info">
          {translate('hotels.available')}
        </Alert>
      )}
    </Container>
  );
};

export default FactSheet;
