import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Translation from '@App/components/Translation';
import RestaurantTurnService from '@App/services/RestaurantTurn';
import RestaurantBookingDate from '@App/types/RestaurantBookingDate';
import RestaurantTurn from '@App/types/RestaurantTurn';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';

import useAuth from '@App/hooks/useAuth';
import Hidden from '@App/components/Hidden';
import RestaurantBookingTurnModalSkeleton from './RestaurantBookingTurnModalSkeleton';
import RestaurantBookingTurnModalItem from './RestaurantBookingTurnModalItem';
import useBookings from './useBookings';

const useStyles = makeStyles((theme: any) => ({
  dialogContent: {
    overflow: 'initial',
  },
  cancelButton: {
    color: theme.palette.dark.contrastText,
    backgroundColor: theme.palette.dark.main,
    '&:hover': {
      backgroundColor: theme.palette.dark.dark,
    },
  },
}));

interface RestaurantBookingTurnModalProps {
  bookingDate: RestaurantBookingDate;
  show: boolean;
  handleTurnsModal: Function;
}

const AlertMessageRender = () => (
  <Grid item xs={12}>
    <Alert severity="info">
      <Translation path="reservations.list.menu.notFound" />
    </Alert>
  </Grid>
);

const RestaurantBookingTurnModal: React.FC<RestaurantBookingTurnModalProps> = ({
  bookingDate,
  show,
  handleTurnsModal,
}: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { user } = useAuth();
  const [turns, setTurns] = useState({
    data: [],
    loading: true,
    error: null,
  });
  const {
    booking,
    update: updateBooking,
    updateStep: updateBookingStep,
  } = useBookings();
  const history = useHistory();

  const fetchTurns = async () => {
    try {
      setTurns({ data: [], loading: true, error: null });
      const data = { ...user, ...bookingDate };
      const resource = RestaurantTurnService.wrapTurns(
        await RestaurantTurnService.find(data)
      );
      setTurns({ ...turns, data: resource, loading: false });
    } catch (error) {
      setTurns({ ...turns, loading: false, error });
    }
  };

  const handleUpdateBooking = (turn: RestaurantTurn) => {
    const data = {
      ...user,
      ...booking.data,
      additionalData: {
        bookingDate: bookingDate.date,
        turnTypeId: turn.typeId,
        availableHostQuota: turn.availableHostQuota,
      },
    };

    updateBooking(data);
    updateBookingStep(2);
    history.push('/restaurants/bookings/steps/2');
  };

  useEffect(() => {
    if (show) {
      (async () => {
        await fetchTurns();
      })();
    }

    setOpen(show);
  }, [show, bookingDate]);

  return (
    <Dialog
      open={open}
      disableBackdropClick
      scroll="body"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Translation path="select.service.type" />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {turns.loading ? (
          <RestaurantBookingTurnModalSkeleton />
        ) : (
          <Grid container spacing={3}>
            <Hidden
              value={turns.data.length > 0}
              yes={() => (
                turns.data.map((turn: RestaurantTurn, index) => (
                  <Grid item key={index} xs={12}>
                    <RestaurantBookingTurnModalItem
                      turn={turn}
                      onClick={handleUpdateBooking}
                    />
                  </Grid>
                ))
              )}
              no={() => <AlertMessageRender />}
              expectation
            />
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleTurnsModal(bookingDate, false);
          }}
          variant="contained"
          className={classes.cancelButton}
        >
          <Translation path="cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RestaurantBookingTurnModal;
