import { AxiosResponse } from 'axios';

import axios from '@App/plugins/axios';
import RestaurantBookingDate from '@App/types/RestaurantBookingDate';
import User from '@App/types/User';

import RestaurantTurnWrap from './wraps/RestaurantTurnWrap';

export default class RestaurantTurnService extends RestaurantTurnWrap {
  static find(data: RestaurantBookingDate & User): Promise<AxiosResponse> {
    return axios.post('/tiposerviciosfecha?idioma=ES', {
      numero_regi: data.registryNumber,
      fecha: data.date,
    });
  }
}
