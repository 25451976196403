import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

// Components...
import Box from '@material-ui/core/Box';
import Card from '@App/components/Card';
import CardContent from '@material-ui/core/CardContent';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import Translation from '@App/components/Translation';

// Styles...
import { makeStyles } from '@material-ui/core/styles';

// Others...
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
  },
  rootContent: {
    paddingBottom: '14px !important',
  },
  name: {
    marginRight: theme.spacing(1),
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  nameIco: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(5),
    fontSize: '1.8rem',
    maxWidth: theme.spacing(3),
    minWidth: theme.spacing(3),
  },
  navigateIco: {
    color: theme.palette.secondary.main,
  },
}));

const ActivityCard = ({ activity }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleCardClick = () => {
    if (activity?.hasActivities) {
      history.push(`/activities/${activity.id}/children`);
    } else {
      activity?.hasContent && history.push(`/activities/${activity.id}`);
    }
  };

  return (
    <Card className={classes.root} onClick={handleCardClick}>
      <CardContent className={classes.rootContent}>
        <Box className={classes.content}>
          <Box display="flex">
            <Box display="flex" alignItems="center">
              <i className={classNames(classes.nameIco, activity.icon)} />
            </Box>
            <Box>
              <Box>
                <Typography
                  className={classes.name}
                  variant="h6"
                  component="span"
                >
                  <Translation
                    path="name"
                    messages={activity.translations}
                    default={activity.name}
                  />
                </Typography>
                <Typography variant="subtitle1" component="span">
                  {activity.fromTime &&
                    moment
                      .utc(activity.fromTime, 'HH:mm:ss A')
                      .format('h:mm A')}
                  {activity.toTime &&
                    ` - ${moment
                      .utc(activity.toTime, 'HH:mm:ss A')
                      .format('h:mm A')}`}
                </Typography>
              </Box>
              <Typography variant="subtitle1" component="span">
                <Translation
                  path="description"
                  messages={activity.translations}
                  default={activity.description}
                />
              </Typography>
            </Box>
          </Box>
          {(activity?.hasActivities || activity?.hasContent) && (
            <Box display="flex" alignItems="center">
              <NavigateNextIcon
                className={classes.navigateIco}
                fontSize="large"
              />
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

ActivityCard.propTypes = {
  activity: PropTypes.object.isRequired,
};

export default ActivityCard;
