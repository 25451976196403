import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

// Plugins...
import theme from '@App/plugins/material-ui';
import '@App/plugins/i18n';
import '@App/plugins/react-add-to-calendar';

// Sass...
import '@App/assets/scss/main.scss';
import 'react-slidedown/lib/slidedown.css';

// Others...
import MaterialConfirm from '@App/providers/MaterialConfirm';
import MaterialSnackbar from '@App/providers/MaterialSnackbar';
import GoogleReCaptcha from '@App/providers/GoogleReCaptcha';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Providers...

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <MaterialSnackbar>
      <MaterialConfirm>
        <GoogleReCaptcha>
          <App />
        </GoogleReCaptcha>
      </MaterialConfirm>
    </MaterialSnackbar>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
