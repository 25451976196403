import React, { useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Button from '@App/components/Button';
import Input from '@Forms/Input';
import LoginService from '@App/services/Login';
import yup from '@App/plugins/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import useAuth from '@App/hooks/useAuth';
import { decryptToLaravel, PARAM_JSON } from '@App/utils/crypto';
import { HAS_ENCRYPTED_DATA } from '@App/utils/constants';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(() => ({
  submit: {
    textAlign: 'center',
    position: 'relative',
  },
}));

const LoginForm: React.FC = () => {
  const { setAuthenticatedUser } = useAuth();
  const { t: translate }: any = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string>('');
  const [
    hasIdentityDocumentCode,
    setHasIdentityDocumentCode,
  ] = useState<boolean>(false);
  const classes = useStyles();
  const formSchema = yup.object().shape({
    roomCode: yup.string().required().label('login.room.number'),
    braceletNumber: yup
      .string()
      .required()
      .matches(/^.{4}$/, translate('login.digits.bracelet.required'))
      .label('login.bracelet.number'),
    identityDocumentCode: yup.lazy((value) => {
      if (hasIdentityDocumentCode) {
        return yup.string().required();
      }
      return yup.string();
    }),
    authorization: yup
      .boolean()
      .oneOf([true], translate('field.must.be.checked'))
      .required(),
  });

  const [form, setForm] = useState({
    roomCode: '',
    braceletNumber: '',
  });

  const methods = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  });
  const login = (data: any) => {
    setLoginError('');
    setLoading(true);
    LoginService.login(data)
      .then((response) => {
        const res = HAS_ENCRYPTED_DATA
          ? JSON.parse(decryptToLaravel(response.data, PARAM_JSON))
          : response.data;
        setAuthenticatedUser(res);
        setLoading(false);
      })
      .catch((error) => {
        const { data } = error.response;
        data?.code === 1001
          ? setHasIdentityDocumentCode(true)
          : setLoginError(translate('invalid.login.message.n1') + ', ' + translate('invalid.login.message.n2'));
        setLoading(false);
      });
  };

  const { control, errors, handleSubmit, reset }: any = methods;

  const formatBraceletNumber = async () => {
    let braceletNumber = String(form.braceletNumber);

    if (braceletNumber.length && braceletNumber.length <= 3) {
      for (let i = braceletNumber.length; i < 4; i++) {
        braceletNumber = `0${braceletNumber}`;
      }
    }

    reset({ ...form, braceletNumber }, { keepIsValid: true });
    setForm({ ...form, braceletNumber });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(login)}>
        <Box marginBottom={1}>
          <Input
            InputLabelProps={{
              required: true,
            }}
            label={translate('login.room.number')}
            state={[form, setForm]}
            path="roomCode"
            control={control}
            errors={errors}
          />
          <Input
            InputLabelProps={{
              required: true,
            }}
            label={translate('login.digits.bracelet')}
            state={[form, setForm]}
            path="braceletNumber"
            control={control}
            errors={errors}
            onBlur={() => {
              formatBraceletNumber();
            }}
            parseValue={false}
            type="password"
          />
          {hasIdentityDocumentCode && (
            <Input
              InputLabelProps={{
                required: hasIdentityDocumentCode,
              }}
              label={translate('identityDocumentCode')}
              state={[form, setForm]}
              path="identityDocumentCode"
              control={control}
              errors={errors}
            />
          )}
          <FormControl error={Boolean(errors.authorization)}>
            <FormControlLabel
              control={
                <Controller
                  name="authorization"
                  control={control}
                  render={({ onChange }) => (
                    <Checkbox
                      onChange={(event) =>
                        onChange(event.target.checked)
                      }
                    />
                  )}
                  value="checked"
                />
              }
              label={
                <Typography variant="subtitle2">
                  {translate('finish.reservation.auth')}
                  &nbsp;
                  {translate('finish.reservation.accept.personal.data')}.
                </Typography>
              }
            />
            <FormHelperText>
              {errors.authorization?.message}
            </FormHelperText>
          </FormControl>
        </Box>
        {loginError && (
          <Box marginTop={1} marginBottom={1}>
            <Alert severity="error">{loginError}</Alert>
          </Box>
        )}
        <Box className={classes.submit}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            path="ok"
            loading={loading}
            disabled={loading}
          />
        </Box>
      </form>
    </FormProvider>
  );
};

export default LoginForm;
