import React from 'react';

import App from '@App/components/App';
import Main from '@App/components/Main';
import Container from '@App/components/Container';
import { Typography } from '@material-ui/core';

const NotFoundLayout: React.FC = () => (
  <App>
    <Main>
      <Container allCentered>
        <Typography variant="h2">404</Typography>
        <Typography variant="h6">Page Not Found</Typography>
      </Container>
    </Main>
  </App>
);

export default NotFoundLayout;
