import * as types from './types';

const branch: string = 'tour-bookings';

export const showUserFormAction = (payload: boolean) => ({
  branch,
  type: `${branch}/${types.SHOW_USER_FORM}`,
  payload,
});

export const updateSelectedTourAction = (payload: boolean) => ({
  branch,
  type: `${branch}/${types.UPDATE_SELECTED_TOUR}`,
  payload,
});
