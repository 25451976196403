import React, { useCallback, useState } from 'react';

// Components....
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { MaterialSnackbarProvider } from './context';

const DEFAULT_OPTIONS = {
  variant: 'success',
  message: '',
  show: false,
  autoHideDuration: 3000,
  onClose: () => ({}),
};

const buildOptions = (options) => ({
  ...DEFAULT_OPTIONS,
  ...options,
});

const MaterialConfirm = ({ children, defaultOptions = {} }) => {
  const [options, setOptions] = useState({
    ...DEFAULT_OPTIONS,
    ...defaultOptions,
  });

  const snackbar = useCallback(
    (options = {}) => setOptions(
        buildOptions({
          show: true,
          ...options,
        }),
      ),
    [options],
  );

  const handleNotificationClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    options.onClose();
    setOptions({ ...options, show: false });
  };

  return (
    <MaterialSnackbarProvider value={snackbar}>
      {children}
      <Snackbar
        open={options.show}
        autoHideDuration={options.autoHideDuration}
        onClose={handleNotificationClose}
      >
        <MuiAlert onClose={handleNotificationClose} severity={options.variant}>
          {options.message}
        </MuiAlert>
      </Snackbar>
    </MaterialSnackbarProvider>
  );
};
export default MaterialConfirm;
