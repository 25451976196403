import * as types from './types';

export const initialState = {
  showUserForm: false,
  selectedTour: null,
};

export default function reducer(state = initialState, action: any = {}) {
  const { branch } = action;

  switch (action.type) {
    case `${branch}/${types.SHOW_USER_FORM}`:
      return {
        ...state,
        showUserForm: action.payload,
      };
    case `${branch}/${types.UPDATE_SELECTED_TOUR}`:
      return {
        ...state,
        selectedTour: action.payload,
      };
    default:
      return state;
  }
}
