import React from 'react';
import MuiStepper from '@material-ui/core/Stepper';
import { withStyles } from '@material-ui/core/styles';
import StepConnector from '@material-ui/core/StepConnector';

const ColorConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 30,
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 1,
  },
}))(StepConnector);

const Stepper = ({ children, ...others }) => (
  <MuiStepper connector={<ColorConnector />} {...others}>
    {children}
  </MuiStepper>
);

export default Stepper;
