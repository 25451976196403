import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Axios from '@App/plugins/axios';
import ActivityHeader from './ActivityHeader';
import ActivityListCard from './ActivityListCard';
import ActivityListCardSkeleton from './ActivityListCardSkeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacing(5)}px 0`,
  },
}));

const ACTIVITY = { activities: [] };

const Activity = () => {
  const classes = useStyles();
  const { activityId } = useParams();
  const [activity, setActivity] = useState({
    item: ACTIVITY,
    loading: false,
    error: null,
  });

  const fetchActivities = async () => {
    let resource = null;
    try {
      setActivity({ item: ACTIVITY, loading: true, error: null });
      if (activityId) {
        resource = await Axios.get(
          `local/activities/${activityId}?_embed=activities`
        );
        setActivity({ item: resource.data, loading: false, error: null });
      } else {
        resource = await Axios.get('local/activities?activityId=');
        setActivity({
          item: { ...activity.item, activities: resource.data },
          loading: false,
          error: null,
        });
      }
    } catch (error) {
      setActivity({ item: ACTIVITY, loading: true, error });
    }
  };

  const fetchData = async () => {
    await fetchActivities();
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [activityId]);

  return (
    <>
      <ActivityHeader activity={activityId ? activity.item : null} />
      <Container maxWidth="md">
        <TransitionGroup>
          <CSSTransition
            key={activityId}
            timeout={{ enter: 300, exit: 300 }}
            classNames="fade-activity"
            mode="out-in"
          >
            <Box className={classes.root}>
              {activity.loading ? (
                <ActivityListCardSkeleton />
              ) : (
                <ActivityListCard activities={activity.item.activities} />
              )}
            </Box>
          </CSSTransition>
        </TransitionGroup>
      </Container>
    </>
  );
};

export default Activity;
