import RestaurantBooking from '@App/types/RestaurantBooking';
import * as types from './types';

export const initialState: any = {
  data: [],
  loading: false,
  error: null,
  bookings: {
    data: [],
    error: null,
    loading: false,
  },
  booking: {
    data: {
      channel: 'Web',
      additionalData: {},
    },
    error: null,
    loading: false,
  },
  step: 1,
};

export default function reducer(state = initialState, action: any = {}) {
  const { branch } = action;

  switch (action.type) {
    case `${branch}/${types.BOOKINGS_FIND_INIT}`:
      return {
        ...state,
        bookings: { data: null, loading: true, error: null },
      };

    case `${branch}/${types.BOOKINGS_FIND_SUCCESS}`:
      return {
        ...state,
        bookings: { data: action.payload, loading: false, error: null },
      };

    case `${branch}/${types.BOOKINGS_FIND_ERROR}`:
      return {
        ...state,
        bookings: { data: [], loading: false, error: action.payload },
      };
    case `${branch}/${types.BOOKINGS_UPDATE_INIT}`:
      return {
        ...state,
        bookings: {
          data: state.bookings.data,
          loading: state.bookings.loading,
          error: null,
        },
      };

    case `${branch}/${types.BOOKINGS_UPDATE_SUCCESS}`:
      return {
        ...state,
        bookings: {
          data: action.payload,
          loading: state.bookings.loading,
          error: null,
        },
      };

    case `${branch}/${types.BOOKINGS_UPDATE_ERROR}`:
      return {
        ...state,
        bookings: {
          data: state.bookings.data,
          loading: state.bookings.loading,
          error: action.payload,
        },
      };
    case `${branch}/${types.BOOKINGS_DELETE_INIT}`:
      return {
        ...state,
        bookings: {
          data: state.bookings.data,
          loading: state.bookings.loading,
          error: null,
        },
      };

    case `${branch}/${types.BOOKINGS_DELETE_SUCCESS}`:
      return {
        ...state,
        bookings: {
          data: action.payload,
          loading: state.bookings.loading,
          error: null,
        },
      };

    case `${branch}/${types.BOOKINGS_DELETE_ERROR}`:
      return {
        ...state,
        bookings: {
          data: state.bookings.data,
          loading: state.bookings.loading,
          error: action.payload,
        },
      };
    case `${branch}/${types.BOOKING_STEP_UPDATE}`:
      return {
        ...state,
        step: action.payload,
      };
    case `${branch}/${types.BOOKING_UPDATE}`:
      return {
        ...state,
        booking: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case `${branch}/${types.BOOKING_RESET}`:
      return {
        ...state,
        booking: {
          data: { channel: state.booking.data.channel, additionalData: {} },
          loading: false,
          error: null,
        },
      };
    case `${branch}/${types.BOOKING_CREATE_INIT}`:
      return {
        ...state,
        booking: {
          data: state.booking.data,
          loading: true,
          error: null,
        },
      };
    case `${branch}/${types.BOOKING_CREATE_SUCCESS}`:
      return {
        ...state,
        booking: {
          data: { ...action.payload, channel: state.booking.data.channel },
          loading: false,
          error: null,
        },
      };
    case `${branch}/${types.BOOKING_CREATE_ERROR}`:
      return {
        ...state,
        booking: {
          data: state.booking.data,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
