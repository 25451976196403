import Restaurant from '@App/types/Restaurant';

export default class RestaurantWrap {
  static wrapRestaurants({ data }: any): any {
    const wrappedData: Restaurant[] = [];

    data.Datos.forEach((item: any) => {
      const wrappedItem: Restaurant = {
        id: item.ID_REST,
        hotelId: item.ID_HOTEL,
        name: item.NOMBRE_REST,
        hotelName: item.HOTEL,
        conditions: item.CONDICIONES,
        turnType: {
          es: item?.TRADUCCION_TIPSER?.ES?.name,
          en: item?.TRADUCCION_TIPSER?.EN?.name,
          pt: item?.TRADUCCION_TIPSER?.PT?.name,
          fr: item?.TRADUCCION_TIPSER?.FR?.name,
          ht: item?.TRADUCCION_TIPSER?.HT?.name,
        },
        foodType: {
          es: item?.TRADUCCION_CATE?.ES?.name,
          en: item?.TRADUCCION_CATE?.EN?.name,
          pt: item?.TRADUCCION_CATE?.PT?.name,
          fr: item?.TRADUCCION_CATE?.FR?.name,
          ht: item?.TRADUCCION_CATE?.HT?.name,
        },
        menuType:
          item.BUFFET_REST === 'N' ? 'restaurant.card' : 'restaurant.buffet',
        availableQuote: item.DISPONIBLES,
        location: item.DESCRIPCION_UBICACION_REST,
        turnDate: item.FECHA_DISTUR,
        active: parseInt(item.ID_ESTADO_REST) === 0,
        isPlusService: item.PLUS_DISTUR === 'S',
      };

      wrappedData.push(wrappedItem);
    });

    return wrappedData;
  }
}
