import React from 'react';

// Components...
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';

const TransferCardSkeleton = () => (
  <Box>
    <Skeleton variant="rect" width="100%" height={118} />
    <Skeleton width="100%" />
    <Skeleton width="100%" />
    <Skeleton width="100%" />
  </Box>
);

export default TransferCardSkeleton;
