const isTransfers = process.env.REACT_APP_TRANSFERS || 'true';
const isTours = process.env.REACT_APP_TOURS || 'true';
const isTourBookings = process.env.REACT_APP_TOUR_BOOKINGS || 'true';
const isRestaurants = process.env.REACT_APP_RESTAURANTS || 'true';
const isActivities = process.env.REACT_APP_ACTIVITIES || 'true';
const isActivityBookings = process.env.REACT_APP_ACTIVITY_BOOKINGS || 'true';

const view = {
  'restaurants:view': isRestaurants.toLowerCase() === 'true',
  'activities:view': isActivities.toLowerCase() === 'true',
  'activity-bookings:view': isActivityBookings.toLowerCase() === 'true',
  'tours:view': isTours.toLowerCase() === 'true',
  'tour-bookings:view': isTourBookings.toLowerCase() === 'true',
  'transfers:view': isTransfers.toLowerCase() === 'true',
  app: true,
};

export const check = (user, perform, data) => {
  if (!Array.isArray(perform)) {
    return view[perform];
  } else {
    for (let i = 0; i < perform.length; i++) {
      if (view[perform[i]] === 'true' || view[perform[i]]) return true;
    }

    return false;
  }
};
