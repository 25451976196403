import React from 'react';
import classNames from 'classnames';

import MuiContainer from '@material-ui/core/Container';

import useStyles from './styles';

interface ContainerProps {
  className?: any;
  children: NonNullable<React.ReactNode>;
  disableGutters?: boolean;
  fixed?: boolean;
  verticalCentered?: boolean;
  horizontalCentered?: boolean;
  allCentered?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

const Container: React.FC<ContainerProps> = ({
  children,
  verticalCentered,
  horizontalCentered,
  allCentered,
  ...props
}) => {
  const classes = useStyles();

  return (
    <MuiContainer
      className={classNames({
        [`${classes.verticalCentered}`]: verticalCentered,
        [`${classes.horizontalCentered}`]: horizontalCentered,
        [`${classes.allCentered}`]: allCentered,
      })}
      {...props}
    >
      {children}
    </MuiContainer>
  );
};

export default Container;
