import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import Stepper from '@App/components/Stepper';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Step from '@material-ui/core/Step';
import Translation from '@App/components/Translation';
import StepLabel from '@App/components/Step/StepLabel';

import DateRangeIcon from '@material-ui/icons/DateRange';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import CardTravelIcon from '@material-ui/icons/CardTravel';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Restaurant from '../Restaurant';
import RestaurantBookingConfirm from './RestaurantBookingConfirm';
import RestaurantBookingDate from './RestaurantBookingDate';
import useBookings from './useBookings';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme & any) => ({
  stepper: {
    backgroundColor: 'transparent',
  },
  pointer: {
    '& .MuiStepLabel-iconContainer > div': {
      cursor: 'pointer',
      transition: 'all 0.4s ease',
    },
    '&:hover .MuiStepLabel-iconContainer > div': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
  },
  cancelButton: {
    fontWeight: 700,
    textTransform: 'none',
  },
}));

const icons: any = {
  1: <DateRangeIcon />,
  2: <RestaurantIcon />,
  3: <CardTravelIcon />,
};

const getStepContent = (step: number) => {
  switch (step) {
    case 1:
      return <RestaurantBookingDate />;
    case 2:
      return <Restaurant />;
    case 3:
      return <RestaurantBookingConfirm />;
    default:
      return 'Not found!';
  }
};

const getSteps = () => [
  'step.select.date',
  'step.select.restaurant',
  'step.confirm.reservation',
];

const RestaurantBookingSteps: React.FC<any> = () => {
  const classes = useStyles();
  const history = useHistory();
  const steps = getSteps();
  const { stepId }: any = useParams();
  const { step } = useBookings();

  const activeStep = parseInt(stepId, 10) - 1;

  const validateCurrentStep = () => {
    if (parseInt(stepId, 10) > step) {
      history.push(`/restaurants/bookings/steps/${step}`);
    }
  };

  const getStepClass = (stepNumber: number): string => {
    let stepClass = '';

    switch (stepNumber) {
      case 1:
        if (step >= 1) stepClass = classes.pointer;
        break;
      case 2:
        if (step >= 2) stepClass = classes.pointer;
        break;
      case 3:
        if (step >= 3) stepClass = classes.pointer;
        break;
      default:
        if (step >= 1) stepClass = classes.pointer;
        break;
    }

    return stepClass;
  };

  const stepRedirect = (stepNumber: number) => {
    switch (stepNumber) {
      case 1:
        if (step >= 1)
          history.push(`/restaurants/bookings/steps/${stepNumber}`);
        break;
      case 2:
        if (step >= 2)
          history.push(`/restaurants/bookings/steps/${stepNumber}`);
        break;
      case 3:
        if (step >= 3)
          history.push(`/restaurants/bookings/steps/${stepNumber}`);
        break;
      default:
        if (step >= 1)
          history.push(`/restaurants/bookings/steps/${stepNumber}`);
        break;
    }
  };

  const cancelOperation = () => {
    history.push('/restaurants/bookings');
  };

  useEffect(() => {
    validateCurrentStep();
  }, []);

  return (
    <Container maxWidth="md">
      <Stepper
        className={classes.stepper}
        alternativeLabel
        activeStep={activeStep}
      >
        {steps.map((path, index) => (
          <Step
            key={path}
            onClick={() => {
              stepRedirect(index + 1);
            }}
          >
            <StepLabel className={getStepClass(index + 1)} icons={icons}>
              <Translation path={path} />
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box>{getStepContent(parseInt(stepId, 10))}</Box>
      <Box mt={6} display="flex" justifyContent="center">
        <Button
          className={classes.cancelButton}
          color="primary"
          onClick={() => {
            cancelOperation();
          }}
        >
          <Translation path="cancel.operation" />
        </Button>
      </Box>
    </Container>
  );
};

export default RestaurantBookingSteps;
