import React from 'react';

// Components...
import Grid from '@material-ui/core/Grid';
import RestaurantBookingDateCardSkeleton from './RestaurantBookingDateCardSkeleton';

const RestaurantListSkeleton: React.FC = () => (
  <Grid container spacing={3}>
    {[...(Array(3) as any).keys()].map((key) => (
      <Grid key={key} xs={12} sm={4} item>
        <RestaurantBookingDateCardSkeleton />
      </Grid>
    ))}
  </Grid>
);

export default RestaurantListSkeleton;
