import axios from '@App/plugins/axios';

import RestaurantTurnDistributionWrap from './wraps/RestaurantTurnDistribution';

export default class RestaurantTurnDistributionService extends RestaurantTurnDistributionWrap {
  static find(data: any) {
    return axios.post('/horarios?idioma=ES', {
      cupo: data.selectedQuote,
      fecha: data.bookingDate,
      id_tipser: data.turnTypeId,
      idrestaurante: data.restaurantId,
    });
  }
}
