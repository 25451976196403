import React from 'react';

// Components...
import Box from '@material-ui/core/Box';
import Card from '@App/components/Card';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';

// Styles...
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: theme.spacing(5),
    minWidth: '40px',
    minHeight: '40px',
  },
  content: {
    paddingBottom: `${theme.spacing(2)}px !important`,
  },
}));

const ActivityCardSkeleton = () => {
  const classes = useStyles();
  return (
    <Card>
      <CardContent className={classes.content}>
        <Box display="flex">
          <Skeleton
            className={classes.avatar}
            variant="circle"
            animation="wave"
          >
            <Avatar />
          </Skeleton>
          <Skeleton width="100%" animation="wave">
            <Typography>.</Typography>
          </Skeleton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ActivityCardSkeleton;
