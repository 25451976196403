import React from 'react';
import { Translation } from 'react-i18next';
import * as yup from 'yup';

yup.setLocale({
  string: {
    min: ({ path, min }) => (
      <Translation>
        {(t) => t('validations:min.string', { attribute: t(path), min })}
      </Translation>
    ),
    max: ({ path, max }) => (
      <Translation>
        {(t) => t('validations:max.string', { attribute: t(path), max })}
      </Translation>
    ),
    email: ({ path }) => (
      <Translation>
        {(t) => t('validations:email', { attribute: t(path) })}
      </Translation>
    ),
    url: ({ path }) => (
      <Translation>
        {(t) => t('validations:url', { attribute: t(path) })}
      </Translation>
    ),
  },
  number: {
    min: ({ path, min }) => (
      <Translation>
        {(t) => t('validations:min.numeric', { attribute: t(path), min })}
      </Translation>
    ),
    max: ({ path, max }) => (
      <Translation>
        {(t) => t('validations:max.numeric', { attribute: t(path), max })}
      </Translation>
    ),
    integer: ({ path }) => (
      <Translation>
        {(t) => t('validations:numeric', { attribute: t(path) })}
      </Translation>
    ),
  },
  mixed: {
    required: ({ path }) => (
      <Translation>
        {(t) => t('validations:required', { attribute: t(path) })}
      </Translation>
    ),
    notType: ({ path }) => (
      <Translation>
        {(t) => t('validations:not.regex', { attribute: t(path) })}
      </Translation>
    ),
  },
});

export default yup;
