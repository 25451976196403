import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

// Components...
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Translation from '@App/components/Translation';
import { NavLink } from 'react-router-dom';
import { Box } from '@material-ui/core';

// Styles....
import { makeStyles } from '@material-ui/core/styles';

// Others...
import moment from '@App/plugins/moment';
import { AuthContext } from '@App/providers/Authentication/context';

// Hooks
import useMenu from '@App/hooks/useMenu';
import useAuth from '@App/hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    justifyContent: 'center',
  },
  title: {
    color: theme.palette.primary.contrastText,
  },
  date: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(0.5),
  },
  userInfo: {
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  fullName: {
    color: theme.palette.secondary.main,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  logout: {
    marginTop: theme.spacing(-0.5),
    cursor: 'pointer',
  },
  staff: {
    flex: '0 0 100%',
    textAlign: 'center',
  },
  other: {
    marginTop: theme.spacing(-0.5),
  },
  greeting: {
    marginRight: theme.spacing(1),
    fontWeight: 'bold',
  },
  container: {
    width: 250,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  icon: {
    color: theme.palette.secondary.main,
  },
  fullList: {
    width: 'auto',
  },
}));

const Sidebar = ({ anchor, onOpen, ...props }) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const { logout } = useContext(AuthContext);
  const { user } = useAuth();

  const menu = useMenu();

  const handleOnlineRegisterClick = () => {
    window.open(
      `${process.env.REACT_APP_REGISTER_URL}?sigPais=CO&idioma=${i18n.language}`
    );
  };

  const list = () => (
    <div
      className={classNames(classes.container, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={onOpen(false)}
      onKeyDown={onOpen(false)}
    >
      <List className={classes.list}>
        <ListItem className={classes.header}>
          <Typography
            className={classNames(classes.title, 'text-font-viva-beautiful')}
            variant="h4"
            component="span"
          >
            Decameron
          </Typography>
        </ListItem>
        <ListItem className={classes.userInfo}>
          <Box className={classes.staff}>
            <Typography
              className={classes.greeting}
              variant="h5"
              component="span"
            >
              <Translation path="hi" />
            </Typography>
            <Typography
              className={classes.fullName}
              variant="h5"
              component="span"
            >
              {user?.fullName?.split(' ')[0]}
            </Typography>
            <ExitToAppIcon
              className={classes.logout}
              onClick={logout}
              fontSize="small"
            />
          </Box>
          <Box className={classes.other}>
            <Typography
              className={classes.date}
              variant="subtitle2"
              component="span"
            >
              {moment().format('dd DD')}
            </Typography>
            <Typography variant="subtitle2" component="span">
              <Translation path="room" /> {user?.roomCode}
            </Typography>
          </Box>
        </ListItem>
        <Divider />
        {menu.map((suggestion, index) => (
          <Box component="span" key={index}>
            <ListItem
              button
              key={suggestion.key}
              component={NavLink}
              target={suggestion.target}
              to={suggestion.link}
            >
              <ListItemIcon className={classes.icon}>
                {suggestion.icon}
              </ListItemIcon>
              <ListItemText primary={suggestion.name} />
            </ListItem>
            <Divider />
          </Box>
        ))}
        <ListItem button onClick={handleOnlineRegisterClick}>
          <ListItemIcon className={classes.icon}>
            <PersonAddIcon />
          </ListItemIcon>
          <ListItemText primary={<Translation path="main.online.register" />} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <Drawer anchor={anchor} onClose={onOpen(false)} {...props}>
      {list()}
    </Drawer>
  );
};

Sidebar.propTypes = {
  anchor: PropTypes.string.isRequired,
  onOpen: PropTypes.func.isRequired,
};

Sidebar.defaultProps = {};

export default Sidebar;
