import React, { useEffect, useState } from 'react';
// Components...
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

// Styles...
import {
  addDays,
  addMonths,
  differenceInMonths,
  format,
  isSameDay,
  lastDayOfMonth,
  startOfMonth,
} from 'date-fns';
import useStyles from './style';

// Others..

const HorizontalDatepicker = ({
  endDate,
  selectDate,
  getSelectedDay,
  color,
  labelFormat,
}) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(new Date());

  const startDate = new Date();
  const lastDate = addDays(startDate, endDate || 90);
  const primaryColor = color || 'rgb(54, 105, 238)';
  const selectedStyle = {
    fontWeight: 'bold',
    width: '49px',
    height: '49px',
    color: primaryColor,
  };
  const buttonColor = { background: primaryColor };
  const labelColor = { color: primaryColor };

  const getStyles = (day) => {
    if (isSameDay(day, selectedDate)) {
      return selectedStyle;
    }
    return null;
  };

  const getId = (day) => {
    if (isSameDay(day, selectedDate)) {
      return 'selected';
    }
    return '';
  };

  const renderDays = () => {
    const dayFormat = 'E';
    const dateFormat = 'd';
    const months = [];
    let days = [];
    for (let i = 0; i <= differenceInMonths(lastDate, startDate); i++) {
      const month = startOfMonth(addMonths(startDate, i));
      const start = i === 0 ? Number(format(startDate, dateFormat)) - 1 : 0;
      const end =        i === differenceInMonths(lastDate, startDate)
          ? Number(format(lastDate, 'd'))
          : Number(format(lastDayOfMonth(month), 'd'));

      for (let j = start; j < end; j++) {
        days.push(
          <div
            id={`${getId(addDays(startDate, j))}`}
            className={classes.dateDayItem}
            style={getStyles(addDays(month, j))}
            key={addDays(month, j)}
            onClick={() => onDateClick(addDays(month, j))}
          >
            <div className={classes.dayLabel}>
              {format(addDays(month, j), dayFormat)}
            </div>
            <div className={classes.dateLabel}>
              {format(addDays(month, j), dateFormat)}
            </div>
          </div>
        );
      }

      months.push(
        <div className={classes.monthContainer} key={month}>
          <span className={classes.monthYearLabel} style={labelColor}>
            {format(month, labelFormat || 'MMMM yyyy')}
          </span>
          <div className={classes.daysContainer}>{days}</div>
        </div>
      );
      days = [];
    }
    return (
      <div id="container" className={classes.dateListScrollable}>
        {months}
      </div>
    );
  };

  const onDateClick = (day) => {
    setSelectedDate(day);
    if (getSelectedDay) {
      getSelectedDay(day);
    }
  };

  useEffect(() => {
    if (getSelectedDay) {
      if (selectDate) {
        getSelectedDay(selectDate);
      } else {
        getSelectedDay(startDate);
      }
    }
  }, []);

  useEffect(() => {
    if (selectDate) {
      if (!isSameDay(selectedDate, selectDate)) {
        setSelectedDate(selectDate);
        setTimeout(() => {
          const view = document.getElementById('selected');
          if (view) {
            view.scrollIntoView({
              behavior: 'smooth',
              inline: 'center',
              block: 'nearest',
            });
          }
        }, 20);
      }
    }
  }, [selectDate]);

  const nextWeek = () => {
    const e = document.getElementById('container');
    const width = e ? e.getBoundingClientRect().width : null;
    e.scrollLeft += width - 60;
  };

  const prevWeek = () => {
    const e = document.getElementById('container');
    const width = e ? e.getBoundingClientRect().width : null;
    e.scrollLeft -= width - 60;
  };

  return (
    <div className={classes.container}>
      <div className={classes.buttonWrapper}>
        <button
          className={classes.button}
          style={buttonColor}
          onClick={prevWeek}
        >
          <KeyboardArrowLeftIcon />
        </button>
      </div>
      {renderDays()}
      <div className={classes.buttonWrapper}>
        <button
          className={classes.button}
          style={buttonColor}
          onClick={nextWeek}
        >
          <KeyboardArrowRightIcon />
        </button>
      </div>
    </div>
  );
};
export default HorizontalDatepicker;
