import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import Translation from '@App/components/Translation';
import RestaurantDishOrdersService from '@App/services/RestaurantDishOrders';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';

import RestaurantBookingHeader from '../Bookings/RestaurantBookingHeader';
import RestaurantDishOrdersList from './RestaurantDishOrdersList';
import RestaurantDishOrdersListSkeleton from './RestaurantDishOrdersListSkeleton';
import useAuth from '@App/hooks/useAuth';

const RestaurantDishOrders: React.FC = () => {
  const { bookingId }: any = useParams();
  const { user } = useAuth();

  const [loading, setLoading] = useState(true);
  const [accordionDishOrders, setAccordionDishOrders] = useState([]);
  const [bookingNotFound, setBookingNotFound] = useState({ message: null });

  const fetchRestaurantDishOrdersByDishType = async () => {
    try {
      setAccordionDishOrders(
        await RestaurantDishOrdersService.findOrdersByDishType(user, bookingId)
      );
    } catch (error) {
      setBookingNotFound({ message: error });
    }
  };

  const fetchData = async () => {
    await fetchRestaurantDishOrdersByDishType();
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  return (
    <Container maxWidth="md">
      <RestaurantBookingHeader />
      {loading ? (
        <RestaurantDishOrdersListSkeleton />
      ) : bookingNotFound.message ? (
        <Alert severity="error">
          <Translation path={bookingNotFound.message} />
        </Alert>
      ) : (
        <RestaurantDishOrdersList dishOrders={accordionDishOrders} />
      )}
    </Container>
  );
};

export default RestaurantDishOrders;
