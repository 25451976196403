import React, { useState } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Translation from '@App/components/Translation';

const useStyles = makeStyles((theme) => ({
  modalStyle: (isMobile) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '250px' : '960px',
    height: isMobile ? '520px' : '760px',
    backgroundImage: isMobile
      ? 'url(/assets/images/help-movil.png)'
      : 'url(/assets/images/help-desktop.png)',
    backgroundSize: 'cover',
    outline: 'none',
  }),
  text: {
    cursor: 'pointer',
    textTransform: 'uppercase',
    textDecoration: 'underline',
  },
}));
const RestaurantFooterHelp: React.FC<any> = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles(isMobile);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography
        className={classes.text}
        variant="caption"
        component="div"
        onClick={handleOpen}
        gutterBottom
      >
        <Translation path="need.help" />
      </Typography>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-titulo"
        aria-describedby="modal-descripcion"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.modalStyle} />
        </Fade>
      </Modal>
    </div>
  );
};

export default RestaurantFooterHelp;
