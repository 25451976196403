import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

// Components...
import Can from '@App/components/Can';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TodayIcon from '@material-ui/icons/Today';
import Translation from '@App/components/Translation';

// Others...
import { AuthContext } from '@App/providers/Authentication/context';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
}));

const ActivityDetailActions = ({ activity }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const classes = useStyles();

  return (
    <Can
      perform="activity-bookings:view"
      yes={() => (
        <Box className={classes.root} display="flex" justifyContent="flex-end">
          {isAuthenticated ? (
            <Link to={`/activities/${activity?.id}/bookings`}>
              <Button
                variant="contained"
                color="secondary"
                endIcon={<TodayIcon />}
              >
                <Translation path="reserve" />
              </Button>
            </Link>
          ) : (
            <Link to={`/auth/login?redirect=activities/${activity?.id}`}>
              <Button
                variant="contained"
                color="secondary"
                endIcon={<TodayIcon />}
              >
                <Translation path="sign.in.for.booking" />
              </Button>
            </Link>
          )}
        </Box>
      )}
    />
  );
};

export default ActivityDetailActions;
