import React from 'react';

// Components...
import Grid from '@material-ui/core/Grid';
import RestaurantCardSkeleton from './RestaurantCardSkeleton';

const RestaurantListSkeleton = () => (
  <Grid container spacing={3}>
    {[...Array(2).keys()].map((key) => (
      <Grid key={key} md={6} xs={12} item>
        <RestaurantCardSkeleton />
      </Grid>
    ))}
  </Grid>
);

export default RestaurantListSkeleton;
