import React from 'react';

// Components...
import Card from '@App/components/Card';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import CardBadgeSkeleton from '@App/components/Card/CardBadgeSkeleton';

const useStyles = makeStyles((theme) => ({
  list: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  image: {
    marginBottom: theme.spacing(1),
  },
  text: {
    height: theme.spacing(3),
  },
  button: {
    height: theme.spacing(4),
    borderRadius: '.25rem',
  },
  content: {
    paddingTop: '24px !important',
  },
}));

const RestaurantBookingDatesSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <Card badge={<CardBadgeSkeleton />}>
      <CardContent className={classes.content}>
        <Skeleton
          className={classes.image}
          variant="rect"
          width="100%"
          height={150}
          animation="wave"
        />
        <Box className={classes.list}>
          {[...(Array(6) as any).keys()].map((key) => (
            <Skeleton
              className={classes.text}
              key={key}
              width="100%"
              animation="wave"
            >
              <Typography>.</Typography>
            </Skeleton>
          ))}
        </Box>
        <Box display="flex" justifyContent="flex-start">
          {[...(Array(1) as any).keys()].map((key) => (
            <Skeleton
              className={classes.button}
              key={key}
              width="20%"
              animation="wave"
              variant="rect"
            />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default RestaurantBookingDatesSkeleton;
