import React from 'react';

// Components...
import Grid from '@material-ui/core/Grid';
import RestaurantBookingCardSckeleton from './RestaurantBookingCardSckeleton';

const RestaurantBookingDateListSkeleton: React.FC = () => (
  <Grid container justify="center" spacing={6}>
    {[...(Array(2) as any).keys()].map((key) => (
      <Grid key={key} md={6} xs={12} item>
        <RestaurantBookingCardSckeleton />
      </Grid>
    ))}
  </Grid>
);

export default RestaurantBookingDateListSkeleton;
