import React, { useEffect } from 'react';

import Container from '@material-ui/core/Container';

import RestaurantBookingHeader from './RestaurantBookingHeader';
import RestaurantBookingList from './RestaurantBookingList';
import RestaurantBookingListSkeleton from './RestaurantBookingListSkeleton';
import RestaurantBookingTabs from './RestaurantBookingTabs';
import RestaurantBookingHeaderNew from './RestaurantBookingHeaderNew';
import useBookings from './useBookings';
import useAuth from '@App/hooks/useAuth';

const RestaurantBooking: React.FC = () => {
  const { user } = useAuth();
  const { bookings, find: findBookings, reset: resetBooking } = useBookings();

  useEffect(() => {
    findBookings(user);
    resetBooking();
  }, []);

  return (
    <Container maxWidth="md">
      <RestaurantBookingHeader />
      <RestaurantBookingTabs />
      <RestaurantBookingHeaderNew />
      {bookings.loading ? (
        <RestaurantBookingListSkeleton />
      ) : (
        <RestaurantBookingList />
      )}
    </Container>
  );
};

export default RestaurantBooking;
