import React from 'react';
import PropTypes from 'prop-types';

// Styles...
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    flexShrink: 0,
  },
}));

const Footer = ({ children = null }) => {
  const classes = useStyles();
  return <footer className={classes.footer}>{children}</footer>;
};

Footer.propTypes = {
  children: PropTypes.node,
};

Footer.defaultProps = {};

export default Footer;
