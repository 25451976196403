import countries from '@App/mocks/countries.json'

class CountryServices {
  static getAll() {
    return new Promise((resolve) => {
      resolve(countries);
    });
  }
}

export default CountryServices;
