import { AxiosResponse } from 'axios';
import axios from '@App/plugins/axios';
import RestaurantBooking from '@App/types/RestaurantBooking';
import User from '@App/types/User';

import RestaurantBookingWrap from './wraps/RestaurantBookingWrap';

export default class RestaurantBookingService extends RestaurantBookingWrap {
  static find(data: User): Promise<AxiosResponse<RestaurantBooking[]>> {
    return axios.get(
      `/hoderest/restaurant-bookings?_embed=additionalData&registryNumber=${data.registryNumber}`
    );
  }

  static update(
    data: RestaurantBooking & User
  ): Promise<AxiosResponse<RestaurantBooking>> {
    return axios.put(`/local/restaurant-bookings/${data.id}`, data);
  }

  static delete(id: number): Promise<AxiosResponse<RestaurantBooking>> {
    return axios.delete(`/local/restaurant-bookings/${id}`);
  }

  static findBookingDates(data: User): Promise<AxiosResponse> {
    return axios.post('/fechas?idioma=ES', {
      nroregistro: data.registryNumber,
    });
  }

  static store(data: any): Promise<AxiosResponse> {
    return axios.post('/local/restaurant-bookings', data);
  }
}
