import React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import * as ReactRouterDom from 'react-router-dom';
import useAuth from '@App/hooks/useAuth';
import { check } from '@App/utils/auth';

interface PublicRouteProps {
  component: any;
  perform?: string | string[];
  redirect?: string;
}

const Route: React.FC<PublicRouteProps & RouteProps> = ({
  component: Component,
  perform,
  ...otherProps
}) => {
  const { user } = useAuth();
  const { redirect } = otherProps;
  const isAuthorized = perform ? check(user, perform) : true;

  return (
    <ReactRouterDom.Route
      {...otherProps}
      render={(props: any) => {
        let component: any = null;
        if (isAuthorized) {
          component = <Component {...props} />;
        } else if (!isAuthorized) {
          component = (
            <Redirect
              to={{ pathname: redirect, state: { from: props.location } }}
            />
          );
        }
        return component;
      }}
    />
  );
};

Route.defaultProps = {
  redirect: '/not-authorized',
};

export default Route;
